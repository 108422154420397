import { ReactComponent as ReactLogo } from 'src/assets/images/react.svg';
import { ReactComponent as Kotlin } from 'src/assets/images/kotlin.svg';
import { ReactComponent as Swift } from 'src/assets/images/swift.svg';
import { TechnologieItem } from '../technologie-item';

const TechnologiesMobile = () => {
  const items = [
    { title: 'React', SvgLogo: <ReactLogo /> },
    { title: 'Kotlin', SvgLogo: <Kotlin /> },
    { title: 'Swift', SvgLogo: <Swift /> },
  ];

  const renderItems = () => {
    return items.map((item) => {
      return (
        <TechnologieItem
          key={item.title}
          title={item.title}
          svgLogo={item.SvgLogo}
        />
      );
    });
  };
  return renderItems();
};

export default TechnologiesMobile;
