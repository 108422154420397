import React from 'react';

function Front({ className = '' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='274'
      height='88'
      fill='none'
      viewBox='0 0 274 88'
      className={className}
    >
      <g clipPath='url(#clip0)'>
        <path
          fill='#00D8FF'
          d='M136.099 34.073c3.187 0 5.772-2.593 5.772-5.793 0-3.199-2.585-5.792-5.772-5.792-3.188 0-5.772 2.593-5.772 5.792 0 3.2 2.584 5.793 5.772 5.793z'
        />
        <path
          stroke='#00D8FF'
          strokeWidth='1.663'
          d='M136.099 16.503c7.749 0 14.948 1.116 20.377 2.991 6.54 2.26 10.561 5.685 10.561 8.786 0 3.232-4.262 6.871-11.285 9.206-5.31 1.766-12.297 2.688-19.653 2.688-7.543 0-14.685-.866-20.054-2.707-6.794-2.33-10.886-6.016-10.886-9.187 0-3.076 3.84-6.475 10.288-8.73 5.448-1.907 12.825-3.047 20.651-3.047h.001z'
          clipRule='evenodd'
        />
        <path
          stroke='#00D8FF'
          strokeWidth='1.663'
          d='M125.883 22.425c3.871-6.737 8.431-12.438 12.761-16.22 5.218-4.557 10.184-6.342 12.86-4.793 2.79 1.615 3.802 7.138 2.309 14.41-1.127 5.498-3.823 12.033-7.499 18.429-3.768 6.557-8.082 12.334-12.354 16.08-5.405 4.743-10.631 6.459-13.367 4.875-2.656-1.536-3.671-6.572-2.397-13.306 1.076-5.689 3.777-12.671 7.685-19.475h.002z'
          clipRule='evenodd'
        />
        <path
          stroke='#00D8FF'
          strokeWidth='1.663'
          d='M125.892 34.268c-3.882-6.73-6.526-13.543-7.629-19.197-1.328-6.813-.389-12.021 2.285-13.575 2.787-1.62 8.061.259 13.593 5.189 4.183 3.727 8.478 9.334 12.164 15.723 3.779 6.551 6.611 13.188 7.713 18.773 1.394 7.068.265 12.469-2.469 14.057-2.653 1.541-7.507-.09-12.683-4.56-4.374-3.778-9.053-9.614-12.974-16.41v0z'
          clipRule='evenodd'
        />
        <path
          fill='#00D8FF'
          fillRule='evenodd'
          d='M109.231 84.653l-7.074-10.726c3.575-.317 6.797-2.917 6.797-7.667 0-4.833-3.418-7.927-8.212-7.927h-10.53v26.3h3.263v-10.45H98.7l6.679 10.45 3.851.02zm-8.879-13.328h-6.876V61.27h6.876c3.105 0 5.226 2.05 5.226 5.008 0 2.959-2.123 5.05-5.226 5.05v-.003zm21.021 13.8c3.105 0 5.658-1.025 7.544-2.917l-1.414-1.933c-1.495 1.538-3.694 2.405-5.854 2.405-4.086 0-6.602-2.997-6.838-6.583h15.362v-.75c0-5.717-3.38-10.214-9.117-10.214-5.423 0-9.35 4.457-9.35 9.977 0 5.955 4.047 10 9.665 10l.002.015zm5.895-11.2h-12.455c.158-2.917 2.159-6.35 6.208-6.35 4.323 0 6.208 3.51 6.247 6.35zm22.477 10.727V71.56c0-4.613-3.339-6.428-7.347-6.428-3.106 0-5.541 1.025-7.583 3.155l1.375 2.05c1.689-1.814 3.537-2.642 5.816-2.642 2.75 0 4.794 1.458 4.794 4.022v3.43c-1.533-1.775-3.693-2.604-6.287-2.604-3.222 0-6.643 2.012-6.643 6.27 0 4.14 3.418 6.31 6.643 6.31 2.554 0 4.716-.906 6.287-2.641v2.166l2.945.004zm-8.171-1.657c-2.75 0-4.677-1.733-4.677-4.14 0-2.445 1.927-4.18 4.677-4.18 2.082 0 4.085.788 5.226 2.367v3.588c-1.139 1.577-3.144 2.367-5.226 2.367v-.002zm22.419 2.13c3.458 0 5.501-1.42 6.956-3.312L168.984 80c-1.257 1.695-2.868 2.483-4.816 2.483-4.047 0-6.56-3.155-6.56-7.373s2.514-7.333 6.56-7.333c1.964 0 3.575.75 4.816 2.483l1.965-1.813c-1.454-1.894-3.498-3.312-6.956-3.312-5.658 0-9.469 4.338-9.469 9.977 0 5.678 3.811 10 9.469 10v.013zm15.877 0c1.727 0 2.79-.513 3.537-1.222l-.863-2.208c-.394.433-1.163.788-2.005.788-1.295 0-1.926-1.025-1.926-2.445v-11.83h3.853v-2.601h-3.853v-5.205h-2.948v5.205h-3.144v2.603h3.144v12.462c0 2.84 1.415 4.456 4.205 4.456v-.003z'
          clipRule='evenodd'
        />
      </g>
      <path
        fill='#E44D26'
        d='M60.213 19.705L54.727 81.16 30.069 88l-24.59-6.84L0 19.705h60.213z'
      />
      <path
        fill='#F16529'
        d='M30.105 82.773l19.924-5.523 4.68-52.52H30.105v58.043z'
      />
      <path
        fill='#EBEBEB'
        d='M19.442 39.808h10.663V32.27H11.206l.18 2.024 1.854 20.773h16.868v-7.54h-9.975l-.69-7.718zm1.714 19.027h-7.56l1.055 11.837 15.421 4.299h.036v-7.866h-.032l-8.395-2.269-.525-6.001z'
      />
      <path
        fill='#000'
        d='M5.605 3h3.83v3.784h3.502V3h3.83v11.463h-3.83v-3.838H9.435v3.838h-3.83V3zm16.199 3.802H18.43V3h10.576v3.802h-3.373v7.66h-3.827v-7.66h-.003zM30.688 3h3.996l2.458 4.029L39.597 3h3.996v11.463h-3.816V8.782l-2.635 4.075h-.065L34.44 8.782v5.68h-3.751V3zm14.81 0h3.83v7.672h5.399v3.787h-9.219V3h-.01z'
      />
      <path
        fill='#fff'
        d='M30.08 55.066h9.28l-.875 9.778-8.406 2.268v7.86l15.454-4.285.112-1.275 1.77-19.847.184-2.024H30.08v7.54-.015zm0-15.276v.018h18.207l.151-1.696.36-3.823.18-2.023H30.079v7.524z'
      />
      <path
        fill='#264DE4'
        d='M243.282 88l-24.59-6.82-5.479-61.468h60.213l-5.485 61.46L243.282 88z'
      />
      <path
        fill='#2965F1'
        d='M263.244 77.26l4.688-52.52h-24.608v58.032l19.92-5.512z'
      />
      <path
        fill='#EBEBEB'
        d='M225.79 47.528l.676 7.538h16.87v-7.538H225.79zm-1.358-15.259l.685 7.538h18.216v-7.538h-18.916.015zm18.901 34.838h-.033l-8.396-2.268-.537-6.014h-7.581l1.055 11.837 15.441 4.297h.036l.015-7.852z'
      />
      <path
        fill='#000'
        d='M227.385 3h9.129v3.82h-5.313v3.818h5.313v3.82h-9.129V3zm10.97 0h9.128v3.321h-5.324v.664h5.313v7.638h-9.132v-3.486h5.313v-.665h-5.313V3h.015zm10.97 0h9.128v3.321h-5.339v.664h5.313v7.638h-9.132v-3.486h5.313v-.665h-5.313V3h.03z'
      />
      <path
        fill='#fff'
        d='M260.816 47.528l1.361-15.259h-18.895v7.538h10.623l-.685 7.71h-9.938v7.537h9.283l-.889 9.785-8.394 2.274v7.843l15.453-4.285.113-1.275 1.779-19.849.183-2.025.006.006z'
      />
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0h93v85H0z' transform='translate(90.213)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Front;
