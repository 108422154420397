import React from 'react';

function MSquaresThree2() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='272'
      height='355'
      fill='none'
      viewBox='0 0 272 355'
    >
      <path
        fill='#DDE9FF'
        d='M7.144 145.06c-11.125-13.733-9.012-33.883 4.72-45.008L126.546 7.144c13.732-11.125 33.883-9.012 45.008 4.72l92.908 114.682c11.125 13.732 9.011 33.883-4.721 45.008L145.06 264.462c-13.733 11.125-33.883 9.011-45.008-4.721L7.144 145.06zm108.408 178.7c-5.215-6.437-4.224-15.883 2.212-21.098L143 282.218c6.437-5.215 15.883-4.224 21.098 2.213l20.444 25.235c5.215 6.437 4.225 15.883-2.212 21.098l-25.236 20.444c-6.437 5.215-15.883 4.225-21.098-2.212l-20.444-25.236zm-47.224-29.493a7 7 0 011.033-9.845l14.041-11.376a7 7 0 019.846 1.033l11.375 14.041a7 7 0 01-1.032 9.846l-14.042 11.375a7 7 0 01-9.845-1.032l-11.376-14.042z'
      />
    </svg>
  );
}

export default MSquaresThree2;
