import { media } from 'src/assets/style/media';
import { ParallaxControl } from 'src/components';
import {
  MFixPdq,
  MLine,
  MOutline,
  MSettings,
  MSquareSmall,
  MTitle,
} from 'src/components/svg/cases/pdq/models';
import styled from 'styled-components/macro';
import { DecorLayers } from './components';

const SaasSection = () => {
  return (
    <SectionWrapper>
      <SaasLayerLine>
        <MLine className='layer--line' />
      </SaasLayerLine>
      <SaasMain>
        <SaasHead>
          <ParallaxControl speed='0.1' limitY={1}>
            <h2>SAAS</h2>
          </ParallaxControl>
          <ParallaxControl speed='0.1' limitY={1}>
            <SaasCircles>
              <span />
              <span />
              <span />
            </SaasCircles>
          </ParallaxControl>
        </SaasHead>
        <SaasMainTitle>
          <ParallaxControl speed='0.3' limitY={1}>
            <MFixPdq />
            <SaasLayerSquare>
              <ParallaxControl speed='.3' limitX={1}>
                <MSquareSmall className='layer--square-m' />
              </ParallaxControl>
            </SaasLayerSquare>
            <SaasLayerTetragon>
              <ParallaxControl speed='-.3' limitX={1}>
                <MSquareSmall className='layer--square-s' />
              </ParallaxControl>
            </SaasLayerTetragon>
          </ParallaxControl>
          <DecorLayers />
        </SaasMainTitle>
        <SaasLayerTitle>
          <ParallaxControl speed='0.6' limitY={1}>
            <MTitle className='layer--title' />
          </ParallaxControl>
          <SaasLayerSettings>
            <MSettings />
            <MOutline />
          </SaasLayerSettings>
        </SaasLayerTitle>
      </SaasMain>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  z-index: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: -421px;
`;

const SaasMain = styled.div`
  padding: 15px calc(50% - 686px) 500px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  position: relative;

  h2 {
    margin-right: min(348px, 19vw);
  }
`;

const SaasMainTitle = styled.div`
  position: relative;

  > svg {
    width: 100%;
  }
  @media ${media.laptopL()} {
    svg {
      height: auto;
      max-width: 95vw;
    }
  }
`;

const SaasHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

const SaasLayerTitle = styled.div`
  display: flex;
  justify-content: center;
  margin: max(calc(-15vw - -4px), -275px) auto 0;
  max-width: 100%;
  position: relative;

  @media ${media.tabletL()} {
    margin: calc(-21vw - -4px) auto 0;
  }

  .layer--title {
    margin-left: 25px;
    height: calc(31vw + 16px);
    max-height: 611px;
    width: 815px;
    max-width: 100%;

    @media ${media.tabletL()} {
      height: calc(41vw + 16px);
    }
  }
`;

const SaasLayerSettings = styled.div`
  position: absolute;
  z-index: -1;
  left: min(80vw, calc(100% + 30px));
  bottom: min(5vw, 100px);

  @media ${media.laptopS()} {
    display: none;
  }

  svg {
    &:first-child {
      animation: circle 16s linear infinite;
      width: 10vw;
      max-width: 185px;
    }

    &:last-child {
      position: absolute;
      top: calc(50% + 12px);
      left: calc(50% + 23px);
      transform: translate(-50%, -50%);
      width: calc(20vw + 3px);
      max-width: 333px;
      height: 308px;
    }
  }
`;

const SaasCircles = styled.div`
  display: flex;
  width: min(15vw, 286px);
  align-items: flex-start;
  justify-content: space-between;

  @media ${media.laptopS()} {
    width: min(25vw, 286px);
  }

  span {
    width: 42px;
    height: 42px;
    border-radius: 50%;

    @media ${media.tabletL()} {
      width: 22px;
      height: 22px;
    }

    &:nth-child(1) {
      background-color: #c72c7a;
    }

    &:nth-child(2) {
      background-color: #ffef00;
    }

    &:nth-child(3) {
      background-color: #81af28;
    }
  }
`;

const SaasLayerLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  .layer--line {
    width: min(30vw, 500px);
    height: min(20vw, 345px);
  }
`;

const SaasLayerSquare = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: min(186px, calc(12vw - 5px));
  width: min(7vw, 110px);
  height: min(7vw, 110px);

  svg {
    animation: circle 5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  .layer--square-m {
    width: #{'min(7vw, 110px)'};
    height: #{'min(7vw, 110px)'};
  }

  @media ${media.laptopL()} {
    left: calc(14% - 1px);
    width: min(7vw, 90px);
    height: min(7vw, 90px);
  }

  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    left: calc(17% - 3vw - -4px);
  }

  @media ${media.laptopM()} {
    left: calc(22% - 6.5vw);
  }

  @media ${media.laptopS()} {
    left: max(calc(23% - 9vw), calc(23% - 8vw));
  }
`;

const SaasLayerTetragon = styled.div`
  position: absolute;
  top: 10px;
  right: min(186px, calc(12vw - 5px));
  width: min(10vw, 185px);
  height: min(10vw, 185px);

  .layer--square-s {
    width: #{'min(13vw, 185px)'};
    height: #{'min(13vw, 185px)'};
  }

  @media ${media.laptopL} {
    right: calc(14% - 1px);
  }
`;

export default SaasSection;
