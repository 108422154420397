import React from 'react';

function MSquaresThree6() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='345'
      height='446'
      fill='none'
      viewBox='0 0 345 446'
    >
      <path
        fill='#fff'
        d='M336.983 177.517c11.125-13.732 9.012-33.883-4.72-45.008L177.518 7.144c-13.733-11.125-33.883-9.012-45.008 4.72L7.145 166.61c-11.125 13.732-9.012 33.883 4.72 45.008L166.61 336.982c13.732 11.125 33.883 9.012 45.008-4.72l125.365-154.745zM199.551 402.981c5.215-6.437 4.224-15.882-2.213-21.097l-37.066-30.028c-6.437-5.215-15.882-4.225-21.097 2.212l-30.028 37.066c-5.215 6.437-4.225 15.883 2.212 21.097l37.066 30.029c6.437 5.215 15.883 4.224 21.097-2.213l30.029-37.066zm57.502-35.165a6.999 6.999 0 00-1.032-9.845l-20.114-16.295a6.999 6.999 0 00-9.845 1.032l-16.296 20.114a7 7 0 001.033 9.845l20.114 16.296a7 7 0 009.845-1.033l16.295-20.114z'
      />
    </svg>
  );
}

export default MSquaresThree6;
