function MCircle5() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='164'
      height='164'
      fill='none'
      viewBox='0 0 164 164'
    >
      <circle cx='82' cy='82' r='82' fill='#74D8F0' />
    </svg>
  );
}

export default MCircle5;
