import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { media } from 'src/assets/style/media';
import { routes } from 'src/routes/routes';
import styled from 'styled-components/macro';

const BaseHeader = () => {
  return (
    <HeaderContainer>
      <Link to={routes.home.short}>
        <Logo />
      </Link>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  position: absolute;
  z-index: 3;
  left: 4rem;
  top: 1rem;

  svg {
    height: 10vh;
    width: 10vw;
  }

  @media ${media.laptopM()} {
    left: 1rem;
    top: 1rem;
  }
  @media ${media.laptopS()} {
    svg {
      width: 160px;
      height: 42px;
    }
  }

  @media ${media.tabletS()} {
    svg {
      width: 88px;
      height: 23px;
    }
  }
`;

export default BaseHeader;
