import React from 'react';

function SomeTime({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='449'
      height='368'
      fill='none'
      viewBox='0 0 449 368'
    >
      <path
        fill='#F2F2F2'
        d='M101.582 321.732c56.102 0 101.582-45.48 101.582-101.582s-45.48-101.582-101.582-101.582S0 164.048 0 220.15s45.48 101.582 101.582 101.582z'
      />
      <path
        fill='#14BC6F'
        d='M369.189 276.833l-.024.012-105.786 58.905-115.838-57.919-1.996-.998 6.012-120.239 104.608-74.548 1.154.722 108.263 67.814 3.607 126.251z'
      />
      <path
        fill='#000'
        d='M369.165 276.845L263.38 335.75l-115.839-57.919 102.612-71.938 119.012 70.952z'
        opacity='0.3'
      />
      <path
        fill='#000'
        d='M369.189 276.833l-119.036-70.94 7.166-123.125 108.263 67.814 3.607 126.251z'
        opacity='0.1'
      />
      <path
        fill='#F2F2F2'
        d='M150.017 193.697l-.588-.832 105.775-74.661 111.738 70.056-.542.863-111.163-69.696-105.22 74.27z'
      />
      <path
        fill='#F2F2F2'
        d='M147.967 234.545l-.572-.842 105.239-71.449.275.156 114.127 64.682-.503.886-113.851-64.526-104.715 71.093z'
      />
      <path
        fill='#F2F2F2'
        d='M188.121 298.419l-.537-.866 106.063-65.721v-125.99h1.018V232.4l-106.544 66.019z'
      />
      <path
        fill='#F2F2F2'
        d='M229.75 319.231l-.541-.862 102.126-64.056V129.449h1.019v125.428L229.75 319.231z'
      />
      <path
        fill='#F2F2F2'
        d='M330.95 298.432L212.177 232.44l.017-.318 6.252-123.584 1.017.052-6.236 123.267 118.218 65.684-.495.891z'
      />
      <path
        fill='#F2F2F2'
        d='M293.582 319.251l-117.439-61.543.018-.327 6.923-123.645 1.017.057-6.904 123.318 116.859 61.238-.474.902z'
      />
      <path
        fill='#3F3D56'
        d='M389.26 81.92c22.622 0 40.96-18.338 40.96-40.96C430.22 18.34 411.882 0 389.26 0s-40.961 18.339-40.961 40.96c0 22.622 18.339 40.96 40.961 40.96z'
      />
      <path
        fill='#2F2E41'
        fillRule='evenodd'
        d='M193.439 344a185.493 185.493 0 01-60.535-241.68A185.5 185.5 0 01448.196 83H448v2.301c-33.247-48.069-88.758-79.62-151.499-79.62-101.504 0-184.083 82.58-184.083 184.083 0 64.496 33.34 121.351 83.69 154.236h-2.669zM351 367.176a184.294 184.294 0 0076.668-46.244A185.432 185.432 0 00448 296.806v-2.579c-23.197 33.539-57.233 59.037-97 71.387v1.562z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default SomeTime;
