import React from 'react';

function SaiktFront() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='79'
      height='73'
      fill='none'
      viewBox='0 0 79 73'
    >
      <path
        fill='#00D8FF'
        d='M38.49 29.13c2.673 0 4.84-2.197 4.84-4.907s-2.167-4.907-4.84-4.907c-2.674 0-4.842 2.197-4.842 4.907s2.168 4.906 4.841 4.906z'
      />
      <path
        stroke='#00D8FF'
        strokeWidth='1.663'
        d='M38.488 14.246c6.5 0 12.538.945 17.09 2.534 5.486 1.914 8.858 4.815 8.858 7.442 0 2.738-3.574 5.82-9.464 7.798-4.454 1.496-10.314 2.276-16.484 2.276-6.326 0-12.316-.732-16.82-2.292-5.697-1.974-9.129-5.096-9.129-7.782 0-2.605 3.22-5.484 8.628-7.395 4.57-1.615 10.757-2.58 17.32-2.58h.001z'
        clipRule='evenodd'
      />
      <path
        stroke='#00D8FF'
        strokeWidth='1.663'
        d='M29.917 19.263c3.247-5.707 7.072-10.536 10.704-13.74 4.376-3.86 8.54-5.372 10.785-4.06 2.34 1.368 3.189 6.046 1.937 12.206-.946 4.658-3.207 10.193-6.29 15.61-3.16 5.555-6.778 10.448-10.361 13.622-4.533 4.017-8.916 5.47-11.212 4.129-2.227-1.3-3.078-5.567-2.01-11.27.903-4.82 3.168-10.734 6.446-16.497h.001z'
        clipRule='evenodd'
      />
      <path
        stroke='#00D8FF'
        strokeWidth='1.663'
        d='M29.927 29.294c-3.256-5.7-5.473-11.471-6.398-16.26-1.114-5.772-.327-10.184 1.916-11.5 2.338-1.371 6.76.22 11.4 4.396 3.509 3.157 7.111 7.906 10.203 13.318 3.169 5.55 5.544 11.171 6.468 15.902 1.17 5.987.223 10.562-2.07 11.907-2.225 1.306-6.296-.077-10.638-3.863-3.668-3.2-7.592-8.143-10.88-13.9v0z'
        clipRule='evenodd'
      />
      <path
        fill='#00D8FF'
        fillRule='evenodd'
        d='M15.955 71.974l-5.934-9.086c3-.269 5.701-2.47 5.701-6.494 0-4.095-2.866-6.715-6.887-6.715H.004v22.278h2.735v-8.852h4.384l5.602 8.852 3.23.017zm-7.448-11.29H2.741v-8.517h5.766c2.605 0 4.384 1.736 4.384 4.242 0 2.506-1.78 4.278-4.384 4.278v-.003zm17.631 11.69c2.605 0 4.746-.869 6.328-2.471l-1.187-1.638c-1.253 1.303-3.098 2.037-4.91 2.037-3.426 0-5.536-2.538-5.734-5.576h12.884v-.635c0-4.843-2.835-8.652-7.647-8.652-4.548 0-7.842 3.775-7.842 8.451 0 5.044 3.395 8.47 8.107 8.47l.001.013zm4.945-9.488H20.636c.133-2.47 1.811-5.379 5.207-5.379 3.625 0 5.206 2.974 5.24 5.38zm18.85 9.086V60.883c0-3.908-2.799-5.445-6.161-5.445-2.605 0-4.647.868-6.36 2.672l1.153 1.737c1.417-1.536 2.967-2.238 4.878-2.238 2.306 0 4.021 1.235 4.021 3.407v2.905c-1.285-1.503-3.098-2.205-5.273-2.205-2.702 0-5.572 1.704-5.572 5.311 0 3.507 2.867 5.345 5.572 5.345 2.142 0 3.956-.768 5.273-2.238v1.836l2.47.002zm-6.852-1.403c-2.307 0-3.923-1.468-3.923-3.507 0-2.07 1.616-3.54 3.923-3.54 1.746 0 3.426.667 4.383 2.004v3.04c-.955 1.335-2.637 2.004-4.383 2.004zm18.803 1.804c2.9 0 4.614-1.203 5.834-2.805l-1.648-1.536c-1.054 1.436-2.406 2.104-4.04 2.104-3.394 0-5.501-2.673-5.501-6.246 0-3.573 2.109-6.212 5.502-6.212 1.647 0 2.998.636 4.039 2.104l1.648-1.536c-1.22-1.604-2.934-2.805-5.834-2.805-4.745 0-7.942 3.674-7.942 8.45 0 4.81 3.197 8.471 7.942 8.471v.011zm13.316 0c1.449 0 2.34-.434 2.967-1.035l-.724-1.87c-.33.367-.975.668-1.682.668-1.086 0-1.615-.869-1.615-2.071V58.044h3.231V55.84h-3.231v-4.409h-2.473v4.41h-2.636v2.204h2.636v10.556c0 2.406 1.187 3.775 3.527 3.775v-.003z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default SaiktFront;
