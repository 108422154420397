import React from 'react';
import { media } from 'src/assets/style/media';
import styled, { css } from 'styled-components/macro';

const textAlignmentHelper = {
  left: 'right',
  right: 'left',
};

const marginHelper = {
  left: (number) => css`
    margin-left: -${number}px;
    margin-right: auto;
  `,
  right: (number) => css`
    margin-right: -${number}px;
    margin-left: auto;
  `,
};

const SectionTitle = ({ children, className, orientation = 'left' }) => {
  return (
    <Title className={className} $orientation={orientation}>
      {children}
    </Title>
  );
};

const Title = styled.h2`
  font-size: 2.5vh;
  text-align: ${({ $orientation }) => textAlignmentHelper[$orientation]};
  border-bottom: 1px solid var(--green-main);
  margin-bottom: 50px;
  width: 50%;
  color: inherit;
  text-transform: uppercase;
  ${({ $orientation }) => marginHelper[$orientation](140)}
  @media ${media.laptopM()} {
    font-size: 2vh;
    ${({ $orientation }) => marginHelper[$orientation](70)}
  }
  @media ${media.laptopS()} {
    margin-bottom: 30px;
    ${({ $orientation }) => marginHelper[$orientation](20)}
  }
  @media ${media.tabletS()} {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export default SectionTitle;
