import { media } from 'src/assets/style/media';
import { SharedContainer, SliderProject } from 'src/components';
import styled from 'styled-components/macro';

const ArkSlider = () => {
  return (
    <SectionWrapper>
      <SharedContainer>
        <SliderMain>
          <StyledSliderProject
            prevName='saikt'
            nextName='pdq'
            fillColor='var(--ark-main-color)'
          />
        </SliderMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  z-index: 1;

  @media ${media.laptopS('min')} {
    margin: -341px 0 -34px;
  }
`;

const SliderMain = styled.div`
  max-width: 408px;
  margin-left: auto;

  @media ${media.laptopS()} {
    margin: 60px auto 0;
  }

  .slider-project {
    margin: 0;
    max-width: 100%;
    color: #000;
  }
`;

const StyledSliderProject = styled(SliderProject)`
  margin: 0;
`;

export default ArkSlider;
