import React from 'react';

function MSquares5() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='545'
      height='545'
      fill='none'
      viewBox='0 0 545 545'
    >
      <path
        fill='#fff'
        d='M7.137 267.375c-11.125-13.732-9.012-33.883 4.72-45.008L277.528 7.137c13.732-11.125 33.883-9.012 45.008 4.72l215.23 265.671c11.125 13.732 9.012 33.883-4.721 45.008l-265.67 215.23c-13.732 11.125-33.883 9.012-45.008-4.721L7.137 267.375z'
      />
    </svg>
  );
}

export default MSquares5;
