import React from 'react';

function Ensure({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='430'
      height='364'
      fill='none'
      viewBox='0 0 430 364'
    >
      <path
        fill='#F1F1F1'
        d='M345.926 29.671a6.819 6.819 0 006.822-6.816 6.819 6.819 0 00-6.822-6.816 6.819 6.819 0 00-6.822 6.816 6.819 6.819 0 006.822 6.816zM305.7 293.432a6.82 6.82 0 006.823-6.816 6.82 6.82 0 00-6.823-6.816 6.819 6.819 0 00-6.822 6.816 6.818 6.818 0 006.822 6.816zM136.012 95.265a6.819 6.819 0 006.822-6.816 6.819 6.819 0 00-6.822-6.816 6.82 6.82 0 00-6.823 6.816 6.82 6.82 0 006.823 6.816zM72.591 364c40.092 0 72.592-4.757 72.592-10.624 0-5.868-32.5-10.625-72.592-10.625-40.09 0-72.591 4.757-72.591 10.625C0 359.243 32.5 364 72.591 364z'
      />
      <path
        fill='#14BC6F'
        d='M110.938 276.684l18.224-5.703a66.677 66.677 0 00-6.807-21.709l-18.224 5.704a40.898 40.898 0 016.807 21.708z'
      />
      <path
        fill='#2F2E41'
        d='M67.136 140.035c9.397 0 17.015-7.611 17.015-16.999 0-9.388-7.618-16.999-17.015-16.999s-17.015 7.611-17.015 16.999c0 9.388 7.617 16.999 17.015 16.999z'
      />
      <path
        fill='#9F616A'
        d='M48.78 336.053l-3.237 13.858h7.86l3.237-15.705-7.86 1.847zm37.914 0v12.934l7.86.924v-13.858h-7.86zM46.468 189.16s.924 19.863 1.85 21.249c.924 1.385 9.247 21.248 9.247 21.248l4.16-12.934s-6.472-10.624-5.547-16.629c.924-6.005.342-13.395.342-13.395l-10.052.461zm26.355-48.041c7.15 0 12.946-5.791 12.946-12.934 0-7.143-5.796-12.934-12.946-12.934-7.15 0-12.946 5.791-12.946 12.934 0 7.143 5.796 12.934 12.946 12.934z'
      />
      <path
        fill='#9F616A'
        d='M62.188 129.571s.462 18.477-2.774 21.249c-3.237 2.771 18.495.462 18.495.462s-4.624-12.934-.925-15.706c3.699-2.771-14.796-6.005-14.796-6.005z'
      />
      <path
        fill='#2F2E41'
        d='M79.296 150.358s-2.775-5.081-6.474-4.619c-3.698.461-15.72 1.385-16.182 2.309-.463.924-1.85 4.158-2.775 5.081-.924.924-7.86 2.31-8.322 7.853-.462 5.543 16.645 39.264 16.645 39.264s-2.312 7.853-1.85 9.701c.463 1.847-2.311 7.391-1.849 9.7.463 2.31-6.935 16.168-3.236 34.183v29.101s-12.484 50.351-8.785 54.046c3.699 3.696 16.182 1.386 18.494 0 2.312-1.386 12.022-71.137 12.022-71.137l4.623-23.558.463 51.736s-.925 43.421 2.312 44.807c3.236 1.386 15.72 1.848 16.645-.924.721-2.162 4.255-59.676 5.775-84.887a94.068 94.068 0 00-2.036-25.949c-1.924-8.72-4.718-18.965-7.9-23.123-6.011-7.853-9.71-32.797-9.71-32.797l7.86-14.32s-4.624-4.619-7.398-4.619-8.322-1.848-8.322-1.848z'
      />
      <path
        fill='#2F2E41'
        d='M48.317 157.749l-2.438 1.658s-1.723 29.753-.336 31.601c1.387 1.847 11.03.946 11.757-.682.727-1.628-8.983-32.577-8.983-32.577z'
      />
      <path
        fill='#9F616A'
        d='M86.231 185.464s.463 19.401 3.237 24.945c2.774 5.543 12.946 28.639 12.946 28.639s-.59 25.421 4.56 19.178c5.15-6.244 1.913-21.026 1.913-21.026l-8.785-34.182s-4.624-15.244-4.161-19.863c.462-4.62-9.71 2.309-9.71 2.309z'
      />
      <path
        fill='#2F2E41'
        d='M94.554 341.504s.462-.369 1.85 1.016c1.386 1.386 5.085 3.234 4.623 8.315-.463 5.081-3.237 6.005-3.237 6.005v-1.386h-1.387v2.31s-2.312 7.391-12.946 2.772c0 0-.462-3.696 0-6.006.462-2.309.462-13.395 1.85-12.934 1.386.462 5.085.924 6.01 2.772.925 1.848 3.7 1.2 3.237-2.864zm-47.624.462s-.462-.37-1.85 1.016c-1.386 1.386-5.085 3.234-4.623 8.315.462 5.081 3.236 6.005 3.236 6.005v-1.386h1.388v2.31s2.311 7.391 12.946 2.771c0 0 .462-3.695 0-6.005-.462-2.309-.462-13.396-1.85-12.934-1.387.462-5.086.924-6.01 2.772-.925 1.848-3.7 1.2-3.237-2.864zm41.613-186.527l6.473 1.386s0 10.162 1.85 14.32c1.85 4.157 5.086 13.858 3.699 14.319-1.388.462-14.334 6.929-15.258 3.234-.925-3.695-3.237-33.259 3.236-33.259zm-39.84-37.786c9.397 0 17.015-7.611 17.015-16.999 0-9.388-7.618-16.999-17.015-16.999s-17.015 7.61-17.015 16.999c0 9.388 7.618 16.999 17.015 16.999z'
      />
      <path
        fill='#2F2E41'
        d='M34.026 114.73a17.015 17.015 0 0015.446 2.317 16.998 16.998 0 0010.83-11.243 16.986 16.986 0 01-7.007 11.723 17.027 17.027 0 01-24.496-5.145 16.993 16.993 0 01-1.689-13.549 16.978 16.978 0 006.916 15.897z'
      />
      <path
        fill='#2F2E41'
        d='M62.598 138.335c6.892 0 12.478-5.581 12.478-12.466 0-6.885-5.586-12.466-12.478-12.466-6.891 0-12.477 5.581-12.477 12.466 0 6.885 5.586 12.466 12.477 12.466z'
      />
      <path
        fill='#2F2E41'
        d='M73.09 129.836c6.109 0 11.06-3.869 11.06-8.642 0-4.772-4.951-8.641-11.06-8.641-6.107 0-11.059 3.869-11.059 8.641 0 4.773 4.952 8.642 11.06 8.642z'
      />
      <path
        fill='#A0616A'
        d='M76.04 133.759c1.16 0 2.1-1.661 2.1-3.71s-.94-3.71-2.1-3.71c-1.159 0-2.098 1.661-2.098 3.71s.94 3.71 2.099 3.71z'
      />
      <path
        fill='#F1F1F1'
        d='M424.286 80.95L187.815 10.007l-57.047 189.795 236.471 70.942 57.047-189.795z'
      />
      <path
        stroke='#3F3D56'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M282.582 65.544l56.011 22.619'
      />
      <path
        fill='#3F3D56'
        d='M284.005 70.195l.839-.391-1.934-4.126 4.261-1.625-.331-.863-5.189 1.98 2.354 5.025zm50.33 20.324l-.331-.864 4.259-1.624-1.933-4.126.839-.392 2.353 5.026-5.187 1.98zm-23.748-8.584a5.084 5.084 0 005.086-5.081 5.084 5.084 0 00-5.086-5.082 5.084 5.084 0 00-5.086 5.082 5.084 5.084 0 005.086 5.08z'
      />
      <path
        stroke='#3F3D56'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M278.313 222.35l51.602-31.389'
      />
      <path
        fill='#3F3D56'
        d='M282.898 223.985l.186-.906-4.467-.913 1.238-4.385-.891-.25-1.507 5.341 5.441 1.113zm46.366-28.204l-.891-.251 1.239-4.383-4.467-.914.187-.906 5.44 1.114-1.508 5.34zm-25.15 15.956a5.084 5.084 0 005.086-5.081 5.084 5.084 0 00-5.086-5.082 5.084 5.084 0 00-5.086 5.082 5.084 5.084 0 005.086 5.081z'
      />
      <path
        stroke='#3F3D56'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M213.481 122.894l.019 60.356'
      />
      <path
        fill='#3F3D56'
        d='M209.7 125.956l.678.63 3.103-3.338 3.105 3.336.677-.63-3.783-4.063-3.78 4.065zm.017 54.234l.677-.63 3.105 3.336 3.104-3.338.677.63-3.781 4.064-3.782-4.062zm3.774-22.037a5.084 5.084 0 005.086-5.081 5.084 5.084 0 00-5.086-5.081 5.084 5.084 0 00-5.086 5.081 5.084 5.084 0 005.086 5.081zM254.763 0h-84.15v104.396h84.15V0z'
      />
      <path
        fill='#FF6584'
        d='M207.427 38.864h-19.098a66.681 66.681 0 010-22.748h19.098a40.886 40.886 0 000 22.748z'
      />
      <path
        fill='#14BC6F'
        d='M217.949 50.402h19.098a66.68 66.68 0 000-22.748h-19.098a40.885 40.885 0 010 22.748zM199.917 88.28h19.097a66.63 66.63 0 000-22.748h-19.097a40.904 40.904 0 010 22.748z'
      />
      <path fill='#3F3D56' d='M430 99.315h-84.151V203.71H430V99.315z' />
      <path
        fill='#14BC6F'
        d='M417.979 121.487h-60.108v2.772h60.108v-2.772zm0 9.701h-60.108v2.771h60.108v-2.771zm0 9.7h-60.108v2.772h60.108v-2.772zm0 9.701h-60.108v2.771h60.108v-2.771zm0 9.7h-60.108v2.772h60.108v-2.772zm-14.334 10.163h-31.441v11.086h31.441v-11.086z'
      />
      <path fill='#3F3D56' d='M262.161 206.482h-84.15v104.396h84.15V206.482z' />
      <path
        fill='#14BC6F'
        d='M250.277 264.596h-60.108v2.771h60.108v-2.771zm0 9.7h-60.108v2.772h60.108v-2.772zm0 9.701h-60.108v2.771h60.108v-2.771zm0 9.7h-60.108v2.772h60.108v-2.772z'
      />
      <path
        fill='#14BC6F'
        d='M208.583 243.64h-19.098a66.678 66.678 0 010-22.748h19.098a40.884 40.884 0 000 22.748zm43.081-20.618h-35.14v2.31h35.14v-2.31zm0 8.315h-35.14v2.309h35.14v-2.309zm0 8.315h-35.14v2.309h35.14v-2.309zm0 8.314h-35.14v2.31h35.14v-2.31z'
        opacity='0.3'
      />
    </svg>
  );
}

export default Ensure;
