import React from 'react';

function MDecoration() {
  return (
    <svg
      width='1920'
      height='268'
      version='1.1'
      viewBox='0 0 1920 268'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='960'
          x2='960'
          y2='77.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' offset='0' />
          <stop stopColor='#fff' stopOpacity='0' offset='1' />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='960'
          x2='960'
          y2='211'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' offset='0' />
          <stop stopColor='#fff' stopOpacity='0' offset='1' />
        </linearGradient>
      </defs>
      <g transform='translate(274.34 -100.44)'>
        <g transform='translate(-274.34 101.44)'>
          <path
            d='m0 217.18 548-217.18h822l550 217.18v50.825l-550-210h-822l-548 210z'
            fill='url(#paint0_linear)'
            fillOpacity='.15'
          />
          <path
            d='m0 217.18 548-217.18h822l550 217.18v50.825l-550-210h-822l-548 210z'
            fill='url(#paint1_linear)'
            fillOpacity='.15'
          />
          <path d='m0 268 548-210h822l550 210z' fill='#fff' />
        </g>
      </g>
    </svg>
  );
}

export default MDecoration;
