import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as LogoFooter } from 'src/assets/images/logo-footer.svg';
import { ReactComponent as LogoFooterReversed } from 'src/assets/images/logo-footer-reversed.svg';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { ReactComponent as LogoReversed } from 'src/assets/images/logo-reversed.svg';
import { Socials } from '../socials';
import { media } from 'src/assets/style/media';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes/routes';

const Footer = ({ className, isLogoReversed }) => {
  return (
    <FooterWrapper className={className}>
      <FooterContainer>
        <ColumnContainer>
          <Link to={routes.home.short}>
            {isLogoReversed ? (
              <LogoFooterReversed />
            ) : (
              <LogoFooter style={{ marginBottom: 40 }} />
            )}
          </Link>
          <Copyright>© BITCOM solution, {new Date().getFullYear()}</Copyright>
        </ColumnContainer>
        <ColumnContainer>
          <Address>ARMENIA, YEREVAN</Address>
        </ColumnContainer>
        <ColumnContainer>
          <EmailSocialsContainer>
            <Socials />
          </EmailSocialsContainer>
          <LinksContainer>
            <PrivacyPolicyLink to={routes.terms}>
              Terms of service
            </PrivacyPolicyLink>
            <PrivacyPolicyLink to={routes.privacy}>
              Privacy policy
            </PrivacyPolicyLink>
          </LinksContainer>
        </ColumnContainer>
      </FooterContainer>
      <MobileFooterContainer>
        <MobileColumn>
          {isLogoReversed ? <LogoReversed /> : <Logo />}
          <Copyright>© BITCOM solution, {new Date().getFullYear()}</Copyright>
          <Address>ARMENIA, YEREVAN</Address>
        </MobileColumn>
        <MobileColumn>
          <PrivacyPolicyLink to={routes.terms}>
            Terms of service
          </PrivacyPolicyLink>
          <PrivacyPolicyLink to={routes.privacy}>
            Privacy Policy
          </PrivacyPolicyLink>
        </MobileColumn>
      </MobileFooterContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  border-top: 1px solid var(--green-main);
  padding: 60px 30px 20px 30px;
  font-size: 18px;
  font-weight: 300;
  color: var(--white);
  line-height: 22px;
  position: relative;
  z-index: 3;
  @media ${media.laptopS()} {
    padding: 25px 15px 70px;
  }
  @media ${media.tabletL()} {
    padding: 10px 0 20px;
  }
  @media ${media.mobile()} {
    padding-bottom: 0;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${media.tabletL()} {
    display: none;
  }
`;

const MobileFooterContainer = styled.div`
  display: none;
  @media ${media.tabletL()} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 10px;
  }
  @media ${media.mobile()} {
    font-size: 12px;
  }
`;

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > svg {
    width: 88px;
    height: 23px;
    margin-bottom: 10px;
  }

  :nth-child(2) {
    align-items: center;
  }

  @media ${media.tabletL()} {
    :nth-child(2) {
      grid-column-start: 2;
      grid-row-start: 1;
      align-items: flex-end;

      a {
        margin: 0;
      }
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > svg {
    width: 250px;
    height: 65px;
  }
`;

const Copyright = styled.p`
  @media ${media.tabletL()} {
    margin-bottom: 16px;
  }
  @media ${media.mobile()} {
    margin-bottom: 0;
  }
`;

const Address = styled.p`
  max-width: 160px;
`;

const EmailSocialsContainer = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media ${media.laptopS()} {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

// TODO check later
// const ButtonContainer = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 15px;
//   font-size: 18px;
//   @media ${media.tabletL()} {
//     grid-template-columns: 30px 30px;
//     margin-left: auto;
//     gap: 10px;
//     font-size: 24px;
//   }
//   @media ${media.tabletS()} {
//     font-size: 16px;
//   }
// `;

// const LanguageButton = styled.button`
//   border: 0;
//   background-color: transparent;
//   font-size: inherit;
//   font-weight: 500;
//   color: inherit;

//   :hover {
//     color: var(--green-main);
//   }
// `;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PrivacyPolicyLink = styled(Link)`
  text-decoration: underline;
  font-size: 18px;
  color: #808080;
  cursor: pointer;

  &:hover {
    color: var(--green-main);
  }

  @media ${media.laptopL()} {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

export default Footer;
