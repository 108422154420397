import styled from 'styled-components/macro';
import { ReactComponent as BackArrows } from 'src/assets/images/back-arrows.svg';

const BackButton = ({ className }) => {
  return (
    <ButtonWrapper className={className}>
      <BackArrows />
      BACK
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  position: relative;
  width: 160px;
  height: 160px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--white);
  font-size: 48px;
  font-family: 'gyre heros';
  font-weight: 700;
  text-transform: uppercase;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg path {
    transition: fill 0.3s ease-in-out;
    fill: var(--green-main);
  }

  :hover {
    svg path {
      fill: var(--green-hover);
    }
  }

  :active {
    color: var(--green-active);
    svg path {
      fill: var(--green-active);
    }
  }
`;

export default BackButton;
