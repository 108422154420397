import { media } from 'src/assets/style/media';
import styled from 'styled-components/macro';
import { LogoComponent } from './components';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes/routes';

const CasesHeader = ({ variant = 'light', className }) => {
  return (
    <HeaderWrapper className={className}>
      <StyledLink to={routes.home.full}>
        <LogoComponent variant={variant} />
      </StyledLink>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  height: 120px;
  padding: 0 140px;
  display: flex;
  align-items: flex-end;
  position: relative;
  @media ${media.laptopS()} {
    padding: 0 15px;
    height: 80px;
  }

  @media ${media.tabletS()} {
    height: 40px;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  z-index: 3;
  left: 4rem;
  top: 1rem;

  svg {
    height: 10vh;
    width: 10vw;
  }

  @media ${media.laptopM()} {
    left: 1rem;
    top: 1rem;
  }

  @media ${media.laptopS()} {
    > svg {
      width: 160px;
      height: 42px;
    }
  }

  @media ${media.tabletS()} {
    > svg {
      width: 88px;
      height: 23px;
    }
  }
`;

export default CasesHeader;
