function MCircle1() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      fill='none'
      viewBox='0 0 45 45'
    >
      <circle cx='22.5' cy='22.5' r='22.5' fill='#97E2F4' />
    </svg>
  );
}

export default MCircle1;
