import React from 'react';

function IconArrow({ fillColor = 'var(--white)' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='61'
      height='16'
      fill='none'
      viewBox='0 0 61 16'
    >
      <path
        fill={fillColor}
        d='M60.707 8.707a1 1 0 000-1.414L54.343.929a1 1 0 00-1.414 1.414L58.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h60V7H0v2z'
      />
    </svg>
  );
}

export default IconArrow;
