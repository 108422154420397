import { media } from 'src/assets/style/media';
import { SharedContainer, SliderProject } from 'src/components';
import styled from 'styled-components/macro';

const SaiktSliderSection = () => {
  return (
    <SectionWrapper>
      <SharedContainer>
        <SliderMain>
          <StyledSliderProject
            prevName='cyber-security'
            nextName='ark'
            fillColor='var(--saikt-main-color)'
          />
        </SliderMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  z-index: 1;

  @media ${media.laptopS('min')} {
    margin-bottom: -43px;
  }
`;

const SliderMain = styled.div`
  max-width: 408px;
  margin-left: auto;
`;

const StyledSliderProject = styled(SliderProject)`
  margin: 0;
  color: #000;
`;

export default SaiktSliderSection;
