import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { MPopover, MSlider } from 'src/components/svg/cases/cs';
import { Popover } from '..';
import { media } from 'src/assets/style/media';
import ChallengesBackground from 'src/assets/images/cases/cs/challenges.png';
import { SharedContainer } from 'src/components';
import { Slider } from 'src/components';

const params = {
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: '.challenges__button-next',
    prevEl: '.challenges__button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const data = ['screen-0', 'screen-1', 'screen-2', 'screen-3', 'screen-4'];

const renderSliderItem = () => {
  return data.map((item, i) => {
    const dynamicImport = require(`src/assets/images/cases/cs/carousel/${item}.jpg`);

    return (
      <SliderItem key={i}>
        <img src={dynamicImport.default} alt={item} />
      </SliderItem>
    );
  });
};

const ChallengesSection = () => {
  const { t } = useTranslation(['cs']);

  return (
    <SectionWrapper>
      <SharedContainer>
        <Title>{t('cs:challenges.title')}</Title>
        <ChallengesMain>
          <ChallengesDesc>
            <h2>{t('cs:challenges.desc.title')}</h2>
            <p>{t('cs:challenges.desc.text.1')}</p>
            <p>{t('cs:challenges.desc.text.2')}</p>
          </ChallengesDesc>
          <ChallengesThumbnail>
            <h2>{t('cs:challenges.thumb.title')}</h2>
            <h3>{t('cs:challenges.thumb.subtitle')}</h3>
            <ChallengesPopover>
              <MPopover />
              <Popover />
            </ChallengesPopover>
          </ChallengesThumbnail>
          <ChallengesSlider>
            <h2>{t('cs:challenges.slider.title')}</h2>
            <ChallengesSliderPolygon>
              <MSlider />
              <Slider rebuildOnUpdate={true} {...params}>
                {renderSliderItem()}
              </Slider>
            </ChallengesSliderPolygon>
          </ChallengesSlider>
        </ChallengesMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: url(${ChallengesBackground}) top / cover no-repeat #000d30;
  padding: 92px 0 170px;
  position: relative;
  z-index: 2;

  h2,
  h3 {
    color: var(--cs-challenges-title);
  }

  h3 {
    font-size: 40px;
    line-height: 21px;
    margin-bottom: 38px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 104px;

  @media ${media.tabletL()} {
    margin-bottom: 64px;
  }
`;

const ChallengesMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  h2 {
    margin-bottom: 27px;
  }
`;

const ChallengesDesc = styled.div`
  width: calc(40% - 60px);
  color: var(--cs-title-color);
  user-select: text;

  @media ${media.tabletL()} {
    width: 100%;
  }

  p {
    color: var(--white);
    font-size: 18px;
    line-height: 22.94px;

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
`;

const ChallengesThumbnail = styled.div`
  width: min(calc(60% + 40px), calc(50% + 60px));
  margin: 56px 0 0 auto;
  text-align: center;

  @media ${media.tabletL()} {
    width: 100%;
  }
`;

const ChallengesPopover = styled.div`
  position: relative;

  svg {
    width: 100%;
    height: auto;
  }
`;

const ChallengesSlider = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 54px;

  img {
    width: 90%;
    margin: auto;
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: top;
    padding-bottom: calc(6% - 0px);
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    bottom: 0 !important;
    right: 10%;
    top: calc(100% - 43px);
    z-index: 2;
    left: unset !important;
    width: auto !important;

    @media ${media.laptopM()} {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 30px;
    border-radius: 0;
    opacity: 1;
    background-color: var(--cs-title-color);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.swiper-pagination-bullet-active {
      background-color: var(--white);
      height: 40px;
    }
  }
`;

const ChallengesSliderPolygon = styled.div`
  position: relative;
  padding: calc(6% - 0px) calc(5% - 0px) 0;
  margin-top: 51px;

  > svg {
    position: absolute;
    left: 50%;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .challenges__button-prev,
  .challenges__button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    filter: drop-shadow(0 0 5px #000);
    z-index: 2;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;

    @media ${media.mobile()} {
      transform: translateY(-70%) scale(80%);
    }
  }

  .challenges__button-prev {
    left: 4px;
    border-right: 25px solid var(--white);
  }

  .challenges__button-next {
    right: 4px;
    border-left: 25px solid var(--white);
  }
`;

const SliderItem = styled.div``;

export default ChallengesSection;
