import styled from 'styled-components/macro';
import {
  MSquares5,
  MSquaresTwo1,
  MSquaresTwo2,
  MSquaresThree4,
  MSquaresThree5,
  MSquaresThree2,
  MSquares6,
} from 'src/components/svg/cases/saikt/models';
import { ParallaxControl, ParallaxControlScroll } from 'src/components';
import { media } from 'src/assets/style/media';

const SaiktLayers = () => {
  return (
    <SaiktLayersWrapper>
      <SaiktLayer1>
        <ParallaxControlScroll y={[50, -30]}>
          <ParallaxControl speed='.2'>
            <MSquares5 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </SaiktLayer1>
      <SaiktLayer2>
        <ParallaxControlScroll y={[0, 70]}>
          <ParallaxControl speed='-.3'>
            <MSquaresTwo1 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </SaiktLayer2>
      <SaiktLayer3>
        <ParallaxControlScroll y={[60, -30]}>
          <ParallaxControl speed='.2'>
            <MSquaresTwo2 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </SaiktLayer3>
      <SaiktLayer4>
        <ParallaxControl speed='.2'>
          <MSquaresThree4 />
        </ParallaxControl>
      </SaiktLayer4>
      <SaiktLayer5>
        <MSquaresThree5 />
      </SaiktLayer5>
      <SaiktLayer6>
        <ParallaxControl speed='.1'>
          <MSquares5 />
        </ParallaxControl>
      </SaiktLayer6>
      <SaiktLayer7>
        <MSquaresThree2 />
      </SaiktLayer7>
      <SaiktLayer8>
        <ParallaxControlScroll y={[-50, 0]}>
          <ParallaxControl speed='-.1'>
            <MSquares6 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </SaiktLayer8>
      <SaiktLayer9>
        <ParallaxControl speed='.2'>
          <MSquares6 />
        </ParallaxControl>
      </SaiktLayer9>
      <SaiktLayer10>
        <ParallaxControl speed='-.3'>
          <MSquares6 />
        </ParallaxControl>
      </SaiktLayer10>
      <SaiktLayer11>
        <ParallaxControlScroll y={[100, -50]}>
          <ParallaxControl speed='.1'>
            <MSquares6 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </SaiktLayer11>
      <SaiktLayer12>
        <ParallaxControl speed='.3'>
          <MSquares6 />
        </ParallaxControl>
      </SaiktLayer12>
      <SaiktLayer13>
        <ParallaxControl speed='-.2'>
          <MSquares5 />
        </ParallaxControl>
      </SaiktLayer13>
      <SaiktLayer14>
        <ParallaxControl speed='.1'>
          <MSquares5 />
        </ParallaxControl>
      </SaiktLayer14>
    </SaiktLayersWrapper>
  );
};

const SaiktLayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    > div {
      width: 100%;
      height: 100%;
    }
  }
`;

const SaiktLayer1 = styled.div`
  position: absolute;
  top: 15px;
  right: calc(100% - 5vw);
  width: min(40vw, 549px);
  height: min(40vw, 549px);

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer2 = styled.div`
  position: absolute;
  top: calc(-1% - -8px);
  left: calc(100% - 149px);
  width: min(40vw, 278px);
  height: min(40vw, 278px);

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer3 = styled.div`
  position: absolute;
  top: calc(15% + 29px);
  left: calc(100% - 60px);
  width: min(40vw, 305px);
  height: min(40vw, 434px);
  z-index: 0;
`;

const SaiktLayer4 = styled.div`
  position: absolute;
  bottom: calc(min(40%, 94vw) + 29px);
  right: 3vw;
  width: min(40vw, 525px);
  height: min(40vw, 585px);
  z-index: 0;

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer5 = styled.div`
  position: absolute;
  bottom: calc(min(20%, 100vw) + 29px);
  right: 58%;
  width: min(50vw, 700px);
  height: min(50vw, 974px);
  z-index: 0;

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer6 = styled.div`
  position: absolute;
  bottom: calc(min(30%, 54vw));
  left: calc(100% - 20px);
  width: min(50vw, 544px);
  height: min(50vw, 544px);
  z-index: 0;
`;

const SaiktLayer7 = styled.div`
  position: absolute;
  bottom: 2%;
  left: 7%;
  width: min(30vw, 445px);
  height: min(30vw, 445px);
  z-index: 0;

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer8 = styled.div`
  position: absolute;
  top: 16%;
  left: 17%;
  width: min(10vw, 173px);
  height: min(10vw, 173px);
  z-index: 0;

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer9 = styled.div`
  position: absolute;
  top: 20%;
  left: 10%;
  width: min(40vw, 272px);
  height: min(40vw, 272px);
  z-index: 0;

  svg {
    width: 272px;
    height: 272px;
  }

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer10 = styled.div`
  position: absolute;
  top: 30%;
  right: 10%;
  width: min(40vw, 465px);
  height: min(40vw, 465px);
  z-index: 0;

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SaiktLayer11 = styled.div`
  position: absolute;
  top: 35%;
  right: 100%;
  width: min(40vw, 447px);
  height: min(40vw, 447px);
  z-index: 0;
`;

const SaiktLayer12 = styled.div`
  position: absolute;
  top: 42%;
  left: 13%;
  width: min(40vw, 447px);
  height: min(40vw, 447px);
  z-index: 0;
`;

const SaiktLayer13 = styled.div`
  position: absolute;
  top: 53%;
  right: calc(100% - 50px);
  width: min(40vw, 116px);
  height: min(40vw, 116px);
  z-index: 0;

  @media ${media.tabletS()} {
    display: none;
  }
`;

const SaiktLayer14 = styled.div`
  position: absolute;
  top: 53%;
  right: calc(100% + 80px);
  width: min(40vw, 183px);
  height: min(40vw, 183px);
  z-index: 0;
`;

export default SaiktLayers;
