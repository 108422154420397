import React from 'react';

function MSquares4() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='244'
      height='244'
      fill='none'
      viewBox='0 0 244 244'
    >
      <path
        fill='#fff'
        d='M7.145 132.326c-11.125-13.733-9.012-33.883 4.72-45.008l98.963-80.174c13.733-11.125 33.883-9.012 45.008 4.72l80.175 98.964c11.125 13.732 9.011 33.882-4.721 45.007l-98.963 80.175c-13.733 11.125-33.883 9.011-45.008-4.721L7.145 132.326z'
      />
    </svg>
  );
}

export default MSquares4;
