import React from 'react';

function MSquaresTwo3() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='206'
      height='259'
      fill='none'
      viewBox='0 0 206 259'
    >
      <path
        fill='#DDE9FF'
        d='M6.047 112.107c-9.426-11.634-7.635-28.707 4-38.133L93.892 6.047c11.634-9.426 28.707-7.635 38.133 4l67.927 83.846c9.426 11.634 7.635 28.707-3.999 38.133l-83.847 67.927c-11.634 9.426-28.707 7.635-38.133-3.999L6.047 112.107zm112.714 119.546a7.897 7.897 0 011.165-11.107l24.421-19.785a7.897 7.897 0 0111.107 1.165l19.785 24.421a7.897 7.897 0 01-1.165 11.107l-24.421 19.785a7.897 7.897 0 01-11.107-1.165l-19.785-24.421z'
      />
    </svg>
  );
}

export default MSquaresTwo3;
