import React from 'react';

function MFixPdq() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1344'
      height='295'
      fill='none'
      viewBox='0 0 1344 295'
    >
      <path
        fill='#434242'
        d='M0 272.192V3.012h183.317v59.86H78.185v57.224h83.878v56.848H78.185v95.248H0zm202.333 0V3.012h78.564v269.18h-78.564zm95.098 0l83.878-138.543L303.883 3.012h88.433l42.508 80.19 41.75-80.19h79.703l-72.872 128.002 83.879 141.178h-88.813l-50.478-92.613-50.479 92.613h-80.083zm359.258 0V3.012h71.733c28.086 0 50.479 6.149 67.178 18.447 16.953 12.298 25.43 31.373 25.43 57.224v1.506c0 26.354-8.35 45.805-25.05 58.354-16.7 12.298-39.093 18.447-67.179 18.447h-50.858v115.202h-21.254zm21.254-132.143h52.377c46.303 0 69.455-19.953 69.455-59.86v-1.505c0-20.832-6.072-35.766-18.218-44.801-12.145-9.036-29.477-13.553-51.996-13.553h-51.618v119.719zm196.738 114.825h47.822c37.448 0 65.913-10.541 85.397-31.624 19.48-21.333 29.22-49.695 29.22-85.083v-3.012c0-34.887-9.61-62.746-28.84-83.578-19.231-20.831-47.95-31.247-86.157-31.247h-47.442v234.544zm-21.254 17.318V3.012h70.594c43.268 0 76.539 12.298 99.819 36.894 23.53 24.346 35.3 56.095 35.3 95.249v3.388c0 39.405-11.64 71.531-34.92 96.378-23.03 24.847-56.678 37.271-100.958 37.271h-69.835zm461.353 22.589l-35.3-37.648c-19.99 12.047-42.26 18.071-66.8 18.071-38.21 0-69.96-13.051-95.27-39.154-25.05-26.353-37.57-58.604-37.57-96.754v-3.012c0-38.4 12.52-70.652 37.57-96.754C1142.46 13.177 1173.97 0 1211.92 0s69.46 13.051 94.51 39.154c25.04 26.102 37.57 58.228 37.57 96.377v3.012c0 21.836-4.43 42.291-13.28 61.366-8.61 18.824-20.75 34.51-36.44 47.059l33.4 35.389-12.9 12.424zm-102.1-37.271c20.24 0 38.33-4.644 54.27-13.93l-38.33-41.412 12.9-12.424 40.24 43.671c26.82-22.588 40.23-54.338 40.23-95.248v-3.012c0-33.381-10.25-61.366-30.75-83.954-20.24-22.589-46.68-33.883-79.32-33.883-32.89 0-59.59 11.169-80.08 33.506-20.25 22.338-30.37 50.699-30.37 85.084v3.012c0 34.133 10.63 62.495 31.89 85.083 21.25 22.338 47.69 33.507 79.32 33.507z'
      />
    </svg>
  );
}

export default MFixPdq;
