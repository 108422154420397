import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import csEn from './messages/cases/cs/cs-en.json';
import csRu from './messages/cases/cs/cs-ru.json';
import commonEn from './messages/common/common-en.json';
import commonRu from './messages/common/common-ru.json';
import saiktEn from './messages/cases/saikt/saikt-en.json';
import saiktRu from './messages/cases/saikt/saikt-ru.json';
import arkEn from './messages/cases/ark/ark-en.json';
import arkRu from './messages/cases/ark/ark-ru.json';
import fixpdqEn from './messages/cases/fixpdq/fixpdq-en.json';
import fixpdqRu from './messages/cases/fixpdq/fixpdq-ru.json';

const resources = {
  en: {
    cs: csEn,
    saikt: saiktEn,
    ark: arkEn,
    fixpdq: fixpdqEn,
    common: commonEn,
  },
  ru: {
    cs: csRu,
    saikt: saiktRu,
    ark: arkRu,
    fixpdq: fixpdqRu,
    common: commonRu,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
