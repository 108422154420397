import { ReactComponent as Wordpress } from 'src/assets/images/wordpress.svg';
import { TechnologieItem } from '../technologie-item';

const TechnologiesCms = () => {
  const items = [{ title: 'Wordpress', SvgLogo: <Wordpress /> }];

  return items.map((item) => (
    <TechnologieItem
      key={item.title}
      title={item.title}
      svgLogo={item.SvgLogo}
    />
  ));
};

export default TechnologiesCms;
