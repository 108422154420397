import { useTranslation } from 'react-i18next';
import { ParallaxControlScroll, SharedContainer } from 'src/components';
import styled from 'styled-components/macro';
import {
  Results,
  Ensure,
  Requirements,
  Front,
  Back,
} from 'src/components/svg/cases/pdq/images';
import { MSkill } from 'src/components/svg/cases/pdq/models';
import { media } from 'src/assets/style/media';

const SkillsSection = () => {
  const { t } = useTranslation(['fixpdq']);
  return (
    <SectionWrapper>
      <SharedContainer>
        <SkillsMain>
          <SkillsItem>
            <SkillResult x={[-20, 0]}>
              <Results />
            </SkillResult>
            <SkillItemName x={[20, 0]}>
              <h2>{t('fixpdq:skills.result.title')}</h2>
              <p>{t('fixpdq:skills.result.text')}</p>
            </SkillItemName>
          </SkillsItem>
          <SkillsItem>
            <EnsureName x={[-30, 10]}>
              <h2>{t('fixpdq:skills.ensure.title')}</h2>
              <SkillsItemList>
                <li>{t('fixpdq:skills.ensure.list.1')}</li>
                <li>{t('fixpdq:skills.ensure.list.2')}</li>
                <li>{t('fixpdq:skills.ensure.list.3')}</li>
              </SkillsItemList>
            </EnsureName>
            <SkillEnsure x={[30, -10]}>
              <Ensure />
            </SkillEnsure>
          </SkillsItem>
          <SkillsItem>
            <SkillRequirements x={[-20, 0]}>
              <Requirements />
            </SkillRequirements>
            <RequirementsName x={[20, 0]}>
              <h2>{t('fixpdq:skills.requirements.title')}</h2>
              <SkillsRequirements>
                <RequirementsItem>
                  <strong>Frontend:</strong>
                  <Front className='skills--front' />
                </RequirementsItem>
                <RequirementsItem>
                  <strong>Backend:</strong>
                  <Back className='skills--back' />
                </RequirementsItem>
              </SkillsRequirements>
            </RequirementsName>
          </SkillsItem>
          <SkillsDecor>
            <MSkill />
          </SkillsDecor>
        </SkillsMain>
        <MobileVersion>
          <ResultMobile>
            <Results />
            <ItemName>
              <h2>{t('fixpdq:skills.result.title')}</h2>
              <p>{t('fixpdq:skills.result.text')}</p>
            </ItemName>
          </ResultMobile>
          <EnsureMobile>
            <ItemName>
              <h2>{t('fixpdq:skills.ensure.title')}</h2>
              <SkillsItemList>
                <li>{t('fixpdq:skills.ensure.list.1')}</li>
                <li>{t('fixpdq:skills.ensure.list.2')}</li>
                <li>{t('fixpdq:skills.ensure.list.3')}</li>
              </SkillsItemList>
            </ItemName>
            <Ensure />
          </EnsureMobile>
          <RequirementsMobile>
            <ItemName>
              <h2>{t('fixpdq:skills.requirements.title')}</h2>
              <SkillsRequirements>
                <RequirementsItem>
                  <strong>Frontend:</strong>
                  <Front className='skills--front' />
                </RequirementsItem>
                <RequirementsItem>
                  <strong>Backend:</strong>
                  <Back className='skills--back' />
                </RequirementsItem>
              </SkillsRequirements>
            </ItemName>
            <Requirements />
          </RequirementsMobile>
        </MobileVersion>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  z-index: 3;
  position: relative;
  overflow: hidden;
`;

const SkillsMain = styled.div`
  position: relative;
  padding-bottom: 44px;
  @media ${media.laptopS()} {
    display: none;
  }
`;

const SkillsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 54px;

  @media ${media.laptopM()} {
    flex-direction: column;
  }
`;

const SkillsDecor = styled.div`
  position: absolute;
  bottom: 0;
  right: calc(100% - 8px);
  transform: translateX(80%);
  width: min(40vw, 757px);
  z-index: -1;

  @media ${media.laptopL()} {
    width: min(30vw, 757px);
  }

  @media ${media.laptopM()} {
    display: none;
  }
`;

const SkillsItemSkill = styled(ParallaxControlScroll)`
  @media ${media.laptopM()} {
    order: 0;
  }

  svg {
    width: 100%;
  }

  @media ${media.laptopM()} {
    margin-bottom: 20px;
  }
`;

const SkillResult = styled(SkillsItemSkill)`
  margin-right: -151px;
  width: min(36vw, 682px);

  @media ${media.laptopL()} {
    margin-right: -101px;
  }

  @media ${media.laptopM()} {
    width: min(76vw, 682px);
  }
`;

const SkillEnsure = styled(SkillsItemSkill)`
  width: min(56vw, 430px);

  @media ${media.laptopL('min')} {
    margin: 0 69px 0 127px;
  }
`;

const SkillRequirements = styled(SkillsItemSkill)`
  h2 {
    max-width: 100%;

    @media ${media.laptopM('min')} {
      white-space: nowrap;
    }
  }
`;

const SkillItemName = styled(ParallaxControlScroll)`
  @media ${media.laptopM()} {
    order: 1;
  }

  h2 {
    line-height: 78.02px;
    max-width: 765px;
    margin-bottom: 30px;

    @media ${media.laptopM()} {
      line-height: 1.35;
    }

    @media ${media.tabletL()} {
      line-height: 1;
    }
  }

  p {
    font-size: 18px;
    max-width: 610px;
    line-height: 21.94px;
  }
`;

const EnsureName = styled(SkillItemName)`
  margin-left: auto;
`;

const RequirementsName = styled(SkillItemName)`
  h2 {
    max-width: 100%;

    @media ${media.laptopM('min')} {
      white-space: nowrap;
    }
  }
`;

const SkillsItemList = styled.ul`
  li {
    font-size: 18px;
    line-height: 21.94px;
    padding-left: 25px;
    margin-bottom: 23px;
    position: relative;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #14bc6f;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
`;

const SkillsRequirements = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 550px;

  svg {
    width: 100%;
  }
`;

const RequirementsItem = styled.div`
  @media ${media.laptopM()} {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  strong {
    display: block;
    font-size: 20px;
    margin-bottom: 32px;
  }
  @media ${media.tabletS()} {
    strong {
      font-size: 16px;
    }
  }
  @media ${media.mobile()} {
    strong {
      font-size: 14px;
    }
  }

  .skills--front {
    max-width: 274px;
  }

  .skills--back {
    max-width: 105px;
  }
`;

const MobileVersion = styled.div`
  display: none;
  @media ${media.laptopS()} {
    display: flex;
    flex-direction: column;
  }
`;

const SkillMobileItem = styled.div`
  display: flex;
  margin-bottom: 40px;
  h2 {
    font-size: 40px;
  }
  @media ${media.tabletL()} {
    h2 {
      font-size: 32px;
    }
  }
  @media ${media.tabletS()} {
    h2 {
      font-size: 24px;
    }
  }

  p {
    font-size: 14px;
  }
  li {
    font-size: 16px;
  }
  @media ${media.mobile()} {
    h2 {
      font-size: 16px;
    }
    p,
    li {
      font-size: 12px;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const RequirementsMobile = styled(SkillMobileItem)`
  flex-direction: column;
  align-items: center;
  > svg {
    width: 60%;
    align-self: center;
    margin-top: 15px;
  }
  h2 {
    margin-bottom: 15px;
  }
`;

const ResultMobile = styled(SkillMobileItem)`
  > svg {
    min-width: 50%;
    margin-right: -12%;
  }
`;
const EnsureMobile = styled(SkillMobileItem)`
  > svg {
    margin-right: -10%;
    margin-left: -5%;
    margin-top: -8%;
  }
`;

const ItemName = styled.div``;

export default SkillsSection;
