import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconArrow from 'src/components/svg/icon-arrow';
import styled from 'styled-components/macro';

const SliderProject = ({ prevName, nextName, fillColor, className }) => {
  const { t } = useTranslation(['common']);
  return (
    <SliderContainer className={className}>
      <StyledLink to={`/cases/${prevName}`}>
        <label>{t('common:slider-project.prev')}</label>
        <span>«{prevName}»</span>
        <IconArrow fillColor={fillColor} />
      </StyledLink>
      <StyledLink to={`/cases/${nextName}`}>
        <label>{t('common:slider-project.next')}</label>
        <span>«{nextName}»</span>
        <IconArrow fillColor={fillColor} />
      </StyledLink>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  display: flex;
  max-width: 344px;
  width: 100%;
  justify-content: space-between;
  margin: 130px 0 -130px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;

  &:hover {
    label,
    span {
      color: var(--cs-title-color);
    }
  }

  svg {
    fillrule: initial;
    cliprule: initial;
    width: 63px;
  }

  &:first-child {
    svg {
      transform: rotate(180deg);
    }
  }

  label,
  span {
    transition: all 0.3s ease;
  }

  label {
    color: inherit;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
  }

  span {
    color: inherit;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    margin: 6px 0 12px;
  }
`;

export default SliderProject;
