import { media } from 'src/assets/style/media';
import { ParallaxControl } from 'src/components';
import {
  MSquaresThree1,
  MSquaresThree2,
  MSquaresThree3,
} from 'src/components/svg/cases/saikt/models';
import styled from 'styled-components/macro';

const PreviewLayers = () => {
  return (
    <PreviewLayersWrapper>
      <Layer1>
        <MSquaresThree1 />
      </Layer1>
      <Layer2>
        <MSquaresThree2 />
      </Layer2>
      <Layer3>
        <ParallaxControl speed='.2'>
          <MSquaresThree3 />
        </ParallaxControl>
      </Layer3>
    </PreviewLayersWrapper>
  );
};

const PreviewLayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Layer1 = styled.div`
  position: absolute;
  top: calc(100% - -15px);
  left: calc(10% + 3px);
  width: min(42vw, 271px);
  height: min(42vw, 354px);

  @media ${media.tabletL()} {
    top: calc(100% + 100px);
  }

  @media ${media.laptopS()} {
    display: none;
  }
`;

const Layer2 = styled.div`
  position: absolute;
  top: calc(100% + 115px);
  right: calc(100% - 80px);
  width: min(42vw, 271px);
  height: min(42vw, 354px);

  @media ${media.laptopL()} {
    top: calc(100% + 10px);
  }

  @media ${media.laptopS()} {
    display: none;
  }
`;

const Layer3 = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  right: calc(100% - 32px);
  width: min(42vw, 310px);
  height: min(42vw, 243px);

  @media ${media.laptopL()} {
    top: calc(100% + 10px);
  }
`;

export default PreviewLayers;
