import styled, { css } from 'styled-components/macro';
import { PortfolioItem } from './portfolio-item';
import arkImg from 'src/assets/images/ark.jpg';
import cybernetImg from 'src/assets/images/cybernet.jpg';
import fixpdqImg from 'src/assets/images/fixpdq.jpg';
import saiktImg from 'src/assets/images/saikt.jpg';
import { media } from 'src/assets/style/media';
import { useEffect, useRef } from 'react';
import { useSpring, useSpringRef, animated } from 'react-spring';
import { routes } from 'src/routes/routes';

const animationConfig = {
  from: { opacity: 0, y: 150, skewY: 5 },
  to: { opacity: 1, y: 0, skewY: 0 },
  config: {
    tension: 100,
    friction: 30,
    mass: 3,
  },
};

const textAnimationConfig = {
  from: { x: 100, opacity: 0 },
  to: { opacity: 1, x: 0 },
  config: {
    tension: 100,
    friction: 30,
    mass: 3,
  },
};

const subTitleAnimationConfig = {
  from: { y: 100, opacity: 0 },
  to: { opacity: 1, y: 0 },
  config: {
    tension: 100,
    friction: 30,
    mass: 3,
  },
};

const ioOptions = {
  threshold: 0.01,
};

const mainTextOptions = {
  threshold: 0.1,
};

const AboutInformation = () => {
  const portfolioRef = useRef(null);
  const mainTextContainer = useRef(null);

  const cybernetRef = useSpringRef();
  const arkRef = useSpringRef();
  const subTitleRef = useSpringRef();
  const textRef = useSpringRef();

  const cybernetSpring = useSpring({
    ref: cybernetRef,
    ...animationConfig,
  });

  const arkSpring = useSpring({
    ref: arkRef,
    ...animationConfig,
  });

  const textSpring = useSpring({
    ref: textRef,
    ...textAnimationConfig,
  });

  const subTitleSpring = useSpring({
    ref: subTitleRef,
    ...subTitleAnimationConfig,
  });

  useEffect(() => {
    const portfolioSection = portfolioRef.current;
    const ioCallback = (entries) => {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          cybernetRef.start();
          cybernetRef.resume();
          arkRef.start();
          arkRef.resume();
        } else {
          cybernetRef.pause();
          arkRef.pause();
        }
      });
    };

    const aboutObserver = new IntersectionObserver(ioCallback, ioOptions);
    if (portfolioSection) {
      aboutObserver.observe(portfolioSection);
    }
  }, [arkRef, cybernetRef, portfolioRef]);

  useEffect(() => {
    const mainTextSection = mainTextContainer.current;

    const ioCallback = (entries) => {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          textRef.start();
          textRef.resume();
          subTitleRef.start();
          subTitleRef.resume();
        } else {
          textRef.pause();
          subTitleRef.pause();
        }
      });
    };

    const mainTextObserver = new IntersectionObserver(
      ioCallback,
      mainTextOptions,
    );
    if (mainTextSection) {
      mainTextObserver.observe(mainTextSection);
    }
  }, [textRef, mainTextContainer, subTitleRef]);

  return (
    <SectionWrapper>
      <SectionTitle>ABOUT</SectionTitle>
      <SubTitleContainer ref={subTitleRef} style={subTitleSpring}>
        <SubTitleText>
          Looking for a reliable IT partner for your business?
        </SubTitleText>
        <SubTitleText>
          Are you worried about technicalities and having a lack of
          well-articulated specs?
        </SubTitleText>
      </SubTitleContainer>
      <MainContentContainer ref={mainTextContainer}>
        <MainContentNumber>02</MainContentNumber>
        <MainContentText ref={textRef} style={textSpring}>
          Then you came to the right place, whenever you talk to us you will
          have a feeling of human beings talking to you. Our team understands
          the importance of digitization in almost any industry. We have a
          unique approach consisting of the finest IT specialists with a more
          human approach.
        </MainContentText>
      </MainContentContainer>
      <PortfolioTitle>PORTFOLIO</PortfolioTitle>
      <PortfolioContainer ref={portfolioRef}>
        <PortfolioItem
          title='Cybernet'
          text={`CyberNet is a platform that ensures the safety of your company on the internet. 
          It provides all details about the thread activity and 
          potential cyberattacks on your business.`}
          variant='primary'
          image={cybernetImg}
          rootCSS={CybernetCSS}
          ref={cybernetRef}
          animateStyle={cybernetSpring}
          href={routes.cases.cyberSecurity}
        />
        <PortfolioItem
          title='Ark'
          text={`ARK It's a social video-game that will help to build a good relationship 
          in your company. It relies on psychological safety & tensions & 
          perceptions within teams.`}
          variant='secondary'
          image={arkImg}
          rootCSS={ArkCSS}
          ref={arkRef}
          animateStyle={arkSpring}
          href={routes.cases.ark}
        />
        <PortfolioItem
          title='fixpdq'
          text={`FixPDQ is a management platform to see the performance of your team and 
          manage the work in general. This tool provides the capability to align the 
          process of your work with teammates.`}
          variant='primary'
          image={fixpdqImg}
          rootCSS={FixpdqCSS}
          href={routes.cases.fixpdq}
        />
        <PortfolioItem
          title='saikt'
          text={`Saikt is a great example of the landing page that we have built.
           It intended to describe the main cases of the company and bring new clients.`}
          variant='secondary'
          image={saiktImg}
          rootCSS={CanktCSS}
          href={routes.cases.saikt}
        />
        <PortfolioBackground>PORTFOLIO</PortfolioBackground>
      </PortfolioContainer>
    </SectionWrapper>
  );
};

const CybernetCSS = css`
  width: 62%;
  margin-bottom: 50px;
`;

const ArkCSS = css`
  width: 34%;
  margin-left: 4%;

  p:last-child {
    width: 100%;
  }
`;

const FixpdqCSS = css`
  width: 48%;
`;

const CanktCSS = css`
  width: 48%;
  margin-left: 4%;
  transform: translateY(-20%);
`;

const SectionWrapper = styled.section`
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 150px;
  @media ${media.laptopS()} {
    margin-bottom: 100px;
  }
  @media ${media.tabletS()} {
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled.div`
  border-bottom: 1px solid var(--green-main);
  margin-left: 10%;
  margin-bottom: 60px;
  font-size: 2.5vh;
  @media ${media.laptopS()} {
    margin-right: -10%;
    margin-bottom: 34px;
    margin-left: 50%;
  }
  @media ${media.tabletS()} {
    font-size: 14px;
  }
`;

const SubTitleText = styled.p`
  line-height: 1.1em;
  text-transform: none;
`;

const SubTitleContainer = styled(animated.div)`
  font-size: 36px;
  line-height: 0.9em;
  margin-left: 10%;
  max-width: 80%;
  margin-bottom: 2.5em;
  @media ${media.laptopL()} {
    font-size: 32px;
  }
  @media ${media.laptopM()} {
    font-size: 28px;
  }
  @media ${media.laptopS()} {
    font-size: 24px;
    margin-left: 0;
  }
  @media ${media.tabletL()} {
    font-size: 20px;
  }
  @media ${media.tabletS()} {
    font-size: 14px;
  }
  @media ${media.mobile()} {
    font-size: 12px;
  }
`;

const MainContentContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
  @media ${media.laptopS()} {
    margin-bottom: 30px;
  }
  @media ${media.tabletS()} {
    margin-bottom: 20px;
  }
`;

const MainContentNumber = styled.p`
  font-size: 288px;
  color: var(--green-main);
  opacity: 0.4;
  width: 30%;
  font-weight: 700;
  text-align: center;
  line-height: 0.9em;
  height: min-content;
  align-self: center;
  @media ${media.laptopL()} {
    font-size: 224px;
  }
  @media ${media.laptopM()} {
    font-size: 192px;
    text-align: left;
  }
  @media ${media.laptopS()} {
    font-size: 144px;
  }
  @media ${media.tabletL()} {
    font-size: 112px;
  }
  @media ${media.tabletS()} {
    font-size: 96px;
  }
  @media ${media.mobile()} {
    font-size: 56px;
    margin-right: 10px;
    width: auto;
  }
`;

const MainContentText = styled(animated.p)`
  width: 50%;
  font-size: 50px;
  line-height: 0.9em;
  @media ${media.laptopL()} {
    width: 70%;
    font-size: 44px;
  }
  @media ${media.laptopM()} {
    font-size: 40px;
  }
  @media ${media.laptopS()} {
    font-size: 36px;
  }
  @media ${media.tabletL()} {
    font-size: 24px;
  }
  @media ${media.tabletS()} {
    font-size: 18px;
  }
  @media ${media.mobile()} {
    font-size: 12px;
  }
`;

const PortfolioTitle = styled.div`
  border-bottom: 1px solid var(--green-main);
  margin-right: 50%;
  margin-bottom: 50px;
  text-align: right;
  margin-left: -15%;
  font-size: 2.5vh;
  @media ${media.laptopS()} {
    margin-bottom: 30px;
  }
  @media ${media.tabletS()} {
    font-size: 14px;
  }
`;

const PortfolioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 36px;
  position: relative;
  z-index: 2;
  @media ${media.laptopL()} {
    font-size: 32px;
  }
  @media ${media.laptopM()} {
    font-size: 28px;
  }
  @media ${media.laptopS()} {
    font-size: 24px;
  }
  @media ${media.tabletL()} {
    font-size: 20px;
  }
  @media ${media.tabletS()} {
    font-size: 16px;
    > div:not(:last-child) {
      width: 100%;
      margin-bottom: 30px;
      margin-left: 0;
      transform: translateY(0);
    }
  }
  @media ${media.mobile()} {
    font-size: 12px;
  }
`;

const PortfolioBackground = styled.div`
  color: transparent;
  -webkit-text-stroke: 1px var(--green-main);
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12vw;
  z-index: -15;
  opacity: 0.4;
  font-weight: 700;
  margin-right: -120px;
  line-height: 1.1;
  @media ${media.laptopM()} {
    margin-right: -30px;
  }
  @media ${media.laptopS()} {
    margin-right: 0;
  }
  @media ${media.tabletS()} {
    top: unset;
    bottom: calc(100% - 30px);
    left: 40%;
    font-size: 11vh;
  }
  @media ${media.mobile()} {
    left: 15%;
  }
`;

export default AboutInformation;
