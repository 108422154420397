import { ReactComponent as Backend } from 'src/assets/images/backend.svg';
import { ReactComponent as Frontend } from 'src/assets/images/frontend.svg';
import { ReactComponent as Cms } from 'src/assets/images/cms.svg';
import { ReactComponent as Mobile } from 'src/assets/images/mobile.svg';
import {
  TechnologiesFrontend,
  TechnologiesBackend,
  TechnologiesMobile,
  TechnologiesCms,
} from './components';
import styled from 'styled-components/macro';

import { SectionTitle } from 'src/components';
import { Circle } from './components';
import { media } from 'src/assets/style/media';

const TechnologiesContent = () => {
  return (
    <SectionWrapper>
      <SectionTitle orientation='left'>Technologies</SectionTitle>
      <TitleContainer>
        <Title>
          We
          <FilledWord>Specia-lise</FilledWord>
          in
        </Title>
        <Number>01</Number>
      </TitleContainer>
      <CirclesContainer>
        <CmsCircle icon={<Cms />} title='CMS' size={15} hoverSize={17}>
          <TechnologiesCms />
        </CmsCircle>
        <FrontendCircle
          icon={<Frontend />}
          title='FRONTEND'
          size={23.4}
          hoverSize={29}
        >
          <TechnologiesFrontend />
        </FrontendCircle>
        <BackendCircle
          icon={<Backend />}
          title='BACKEND'
          size={20.5}
          hoverSize={24}
        >
          <TechnologiesBackend />
        </BackendCircle>
        <MobileCircle icon={<Mobile />} title='MOBILE' size={16} hoverSize={18}>
          <TechnologiesMobile />
        </MobileCircle>
      </CirclesContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  height: auto;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 2em;
  padding-top: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11vw;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 1px white;
  margin-bottom: 30px;
`;

const FilledWord = styled.span`
  color: var(--green-main);
  -webkit-text-stroke: 0;
`;

const Title = styled.div`
  position: relative;
  z-index: 100;
  width: 35%;
  line-height: 0.9em;
`;

const Number = styled.div`
  font-size: 1.6em;
  line-height: 1.1;
  -webkit-text-stroke: 1px var(--green-main);
`;

const CirclesContainer = styled.div`
  display: flex;
  height: 20vw;
  position: relative;
  margin: 0 auto;
  width: 75vw;
  @media ${media.laptopM()} {
    height: 50vw;
  }

  @media ${media.tabletL()} {
    height: 140vw;
  }
`;

const CmsCircle = styled(Circle)`
  left: 7.5vw;
  top: 7.5vw;

  @media ${media.laptopM()} {
    top: 10.5vw;
  }

  @media ${media.tabletL()} {
    top: 115vw;
    left: 26vw;
  }
`;

const FrontendCircle = styled(Circle)`
  left: 27vw;
  top: 12.5vw;
  @media ${media.laptopM()} {
    top: 33.5vw;
  }

  @media ${media.tabletL()} {
    top: 20vw;
    left: 50vw;
  }
`;

const BackendCircle = styled(Circle)`
  left: 49vw;
  top: 4.5vw;

  @media ${media.laptopM()} {
    left: 47vw;
  }

  @media ${media.tabletL()} {
    top: 66vw;
    left: 17vw;
  }
`;

const MobileCircle = styled(Circle)`
  left: 67vw;
  top: 12.5vw;

  @media ${media.laptopM()} {
    top: 26.5vw;
  }

  @media ${media.tabletL()} {
    top: 90vw;
    left: 58vw;
  }
`;

export default TechnologiesContent;
