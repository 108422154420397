import styled from 'styled-components/macro';
import './input-field.css';
import { useRef } from 'react';

const InputField = ({
  label,
  value,
  onChange,
  className,
  name,
  variant = 'input',
  type = 'text',
  maxlength,
  error,
}) => {
  const textField = useRef();
  const errorStyle = error ? ' error' : '';

  const autoResize = () => {
    let textarea = textField.current;
    textarea.style.cssText = '-moz-box-sizing:content-box';
    textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
  };

  return (
    <Wrapper className={className}>
      {variant === 'input' ? (
        <input
          className={'input-field__input' + errorStyle}
          spellCheck={false}
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          maxLength={maxlength}
          placeholder=' '
        />
      ) : (
        <textarea
          ref={textField}
          onKeyDown={autoResize}
          className='input-field__textarea'
          value={value}
          onChange={onChange}
          name={name}
          maxLength={maxlength}
          placeholder=' '
        />
      )}
      <label htmlFor='' className='input-field__label'>
        {label}
      </label>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  font-size: inherit;
`;

const ErrorMessage = styled.p`
  color: var(--red);
  font-size: 15px;
  margin-bottom: 8px;
`;

export default InputField;
