import React from 'react';

function MSquares3() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='139'
      height='139'
      fill='none'
      viewBox='0 0 139 139'
    >
      <path
        fill='#DDE9FF'
        d='M6.994 82.397c-9.478-11.699-7.677-28.866 4.022-38.343L56.88 6.897C68.579-2.58 85.746-.78 95.223 10.92l37.157 45.864c9.477 11.699 7.677 28.866-4.022 38.343l-45.864 37.157c-11.699 9.478-28.866 7.677-38.343-4.022L6.994 82.397z'
      />
    </svg>
  );
}

export default MSquares3;
