import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/style/media';
import {
  ParallaxControl,
  ParallaxControlScroll,
  SharedContainer,
} from 'src/components';
import styled from 'styled-components/macro';
import { ObjectivesLayers } from './components';
import anchor from 'src/assets/images/cases/ark/anchor.svg';
import birds from 'src/assets/images/cases/ark/birds.png';

const ArkObjectives = () => {
  const { t } = useTranslation(['ark']);
  return (
    <SectionWrapper>
      <SharedContainer>
        <ObjectivesMain>
          <BirdsItem>
            <ParallaxControlScroll x={[-20, 20]}>
              <ObjectivesTitle>
                <h2>
                  {t('ark:ark.objectives.1.title')}
                  <MobileBirds src={birds} alt='birds' />
                </h2>
                <BirdShape>
                  <ParallaxControl speed='.2' limitY={1}>
                    {t('ark:ark.objectives.1.title')}
                  </ParallaxControl>
                </BirdShape>
              </ObjectivesTitle>
              <p>{t('ark:ark.objectives.1.text.1')}</p>
              <p>{t('ark:ark.objectives.1.text.2')}</p>
            </ParallaxControlScroll>
          </BirdsItem>
          <AnchorItem>
            <MobileAnchor src={anchor} alt='anchor' />
            <ParallaxControlScroll x={[20, 0]}>
              <ObjectivesTitle>
                <h2>{t('ark:ark.objectives.2.title')} </h2>
                <AnchorShape>
                  <ParallaxControl speed='-.2' limitY={1}>
                    {t('ark:ark.objectives.2.title')}
                  </ParallaxControl>
                </AnchorShape>
              </ObjectivesTitle>
              <p>{t('ark:ark.objectives.2.text.1')}</p>
            </ParallaxControlScroll>
          </AnchorItem>
          <ShipItem>
            <ParallaxControlScroll x={[-20, 10]}>
              <ObjectivesTitle>
                <h2>{t('ark:ark.objectives.3.title')}</h2>
                <ShipShape>
                  <ParallaxControl speed='.2' limitY={1}>
                    {t('ark:ark.objectives.3.title')}
                  </ParallaxControl>
                </ShipShape>
              </ObjectivesTitle>
              <p>{t('ark:ark.objectives.3.text.1')}</p>
              <p>{t('ark:ark.objectives.3.text.2')}</p>
              <p>{t('ark:ark.objectives.3.text.3')}</p>
            </ParallaxControlScroll>
          </ShipItem>
          <ObjectivesLayers />
        </ObjectivesMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  padding: 180px 0;

  color: black;
  font-size: 18px;
  line-height: 22.94px;

  @media ${media.tabletL()} {
    padding: 90px 0;
  }
`;

const ObjectivesMain = styled.div`
  position: relative;
  z-index: 1;
`;

const ObjectivesItem = styled.div`
  h2 {
    margin-bottom: 28px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
`;

const TitleShape = styled.div`
  position: absolute;
  color: #fff;
  font-size: min(25vw, 160px);
  line-height: min(25vw, 160px);
  font-weight: 700;
  -webkit-text-stroke: 4.4px #e5f7fc;
  white-space: nowrap;
  @media ${media.tabletL()} {
    -webkit-text-stroke: 2.4px #e5f7fca1;
    font-size: 20vw;
  }
`;

const ObjectivesTitle = styled.div`
  display: inline-block;
  position: relative;

  h2 {
    position: relative;
    z-index: 1;
  }
`;

const BirdsItem = styled(ObjectivesItem)`
  max-width: 600px;
  margin-bottom: 107px;

  @media ${media.tabletL()} {
    margin-bottom: 90px;
    h2 {
      display: flex;
      align-items: flex-end;
      margin-top: -40px;
    }
  }
`;

const BirdShape = styled(TitleShape)`
  bottom: calc(100% - 58px);
  left: calc(-15% + 8px);
`;

const AnchorItem = styled(ObjectivesItem)`
  max-width: 936px;
  margin: 0 0 182px auto;

  @media ${media.tabletL()} {
    margin-bottom: 90px;
    display: flex;
  }

  p {
    max-width: 560px;
  }
`;

const AnchorShape = styled(TitleShape)`
  bottom: calc(100% - 56px);
  left: calc(-15% + -32px);
`;

const ShipItem = styled(ObjectivesItem)`
  max-width: 1398px;
  margin-left: auto;

  p {
    max-width: 620px;
  }
`;

const ShipShape = styled(TitleShape)`
  bottom: calc(100% - 59px);
  left: calc(-15% + -1px);
`;

const MobileAnchor = styled.img`
  align-self: flex-start;
  position: relative;
  z-index: 1;
  margin-left: -15%;
  margin-right: 10px;
  max-width: 33% !important;
  display: block;
  @media ${media.tabletL('min')} {
    display: none;
  }
`;

const MobileBirds = styled.img`
  max-width: 30% !important;
  @media ${media.tabletL('min')} {
    display: none;
  }
`;

export default ArkObjectives;
