function MCircle3() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='84'
      height='84'
      fill='none'
      viewBox='0 0 84 84'
    >
      <circle cx='42' cy='42' r='42' fill='#97E2F4' />
    </svg>
  );
}

export default MCircle3;
