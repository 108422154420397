import React from 'react';

function Results({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='682'
      height='362'
      fill='none'
      viewBox='0 0 682 362'
    >
      <g clipPath='url(#clip0)'>
        <path
          fill='#F2F2F2'
          d='M28.893 361.042c-7.95-.655-14.97-6.414-18.963-13.329-1.338-2.318-2.256-5.36-.74-7.567a4.156 4.156 0 017.344.956 40.367 40.367 0 01-.626-8.214c.061-2.33.5-4.964 2.436-6.26 2.245-1.503 5.481-.309 7.114 1.844 1.633 2.154 2.077 4.956 2.468 7.631a7.043 7.043 0 014.359-8.528 7.038 7.038 0 013.455-.346c3.288.498 5.864 3.348 6.87 6.522 1.004 3.173.69 6.614.044 9.88-.759 3.83-1.99 7.629-4.207 10.841-2.216 3.212-5.509 5.801-9.328 6.587l-.226-.017zm557.085-240.887a112.125 112.125 0 01-20.61 64.796 111.795 111.795 0 01-54.232 40.899c-.696.243-1.402.486-2.108.708V112.805H362.749c.042-.708.094-1.415.158-2.112h146.121v-22.22a5.29 5.29 0 00-1.544-3.734 5.27 5.27 0 00-3.727-1.547H368.746c.243-.708.496-1.415.77-2.112a111.9 111.9 0 0149.39-58.186 111.566 111.566 0 0175.141-12.911 111.701 111.701 0 0165.918 38.374 112.079 112.079 0 0126.013 71.798z'
        />
        <path
          fill='#3F3D56'
          d='M464.775 78.488H148.704a4.547 4.547 0 00-4.543 4.548v214.415a4.554 4.554 0 004.543 4.549h316.071a4.547 4.547 0 004.543-4.549V83.036a4.553 4.553 0 00-4.543-4.548zm3.245 218.963a3.248 3.248 0 01-3.245 3.249H148.704a3.248 3.248 0 01-3.245-3.249V83.036a3.248 3.248 0 013.245-3.249h316.071a3.248 3.248 0 013.245 3.249v214.415z'
        />
        <path
          fill='#3F3D56'
          d='M468.669 96.706H144.81v1.3h323.859v-1.3zm-312.826-4.574a3.896 3.896 0 003.894-3.898 3.896 3.896 0 00-3.894-3.899 3.896 3.896 0 00-3.894 3.899 3.896 3.896 0 003.894 3.898zm11.196 0a3.897 3.897 0 003.894-3.898 3.897 3.897 0 00-3.894-3.899 3.896 3.896 0 00-3.894 3.899 3.896 3.896 0 003.894 3.898zm11.195 0a3.896 3.896 0 003.894-3.898 3.896 3.896 0 00-3.894-3.899 3.897 3.897 0 00-3.894 3.899 3.897 3.897 0 003.894 3.898zm54.842 31.188h-57.113a4.544 4.544 0 00-3.211 1.333 4.56 4.56 0 00-1.333 3.215v57.177a4.56 4.56 0 001.333 3.215 4.545 4.545 0 003.211 1.334h57.113a4.547 4.547 0 004.543-4.549v-57.177a4.546 4.546 0 00-4.543-4.548zm3.245 61.725a3.248 3.248 0 01-3.245 3.249h-57.113a3.246 3.246 0 01-3.246-3.249v-57.177a3.25 3.25 0 013.246-3.249h57.113a3.246 3.246 0 013.245 3.249v57.177z'
        />
        <path
          fill='#14BC6F'
          d='M335.621 123.645h-57.114a4.223 4.223 0 00-2.98 1.239 4.233 4.233 0 00-1.238 2.984v57.178a4.233 4.233 0 001.238 2.984 4.223 4.223 0 002.98 1.239h57.114a4.223 4.223 0 002.98-1.239 4.233 4.233 0 001.238-2.984v-57.178a4.233 4.233 0 00-1.238-2.984 4.223 4.223 0 00-2.98-1.239z'
        />
        <path
          fill='#E6E6E6'
          d='M438.165 123.645h-57.113a4.227 4.227 0 00-4.219 4.223v57.178a4.227 4.227 0 004.219 4.223h57.113a4.226 4.226 0 004.219-4.223v-57.178a4.226 4.226 0 00-4.219-4.223zm-205.089 85.766h-57.113a4.226 4.226 0 00-4.219 4.223v57.178a4.226 4.226 0 004.219 4.223h57.113a4.227 4.227 0 004.219-4.223v-57.178a4.227 4.227 0 00-4.219-4.223z'
        />
        <path
          fill='#14BC6F'
          d='M335.621 209.411h-57.114a4.223 4.223 0 00-2.98 1.239 4.233 4.233 0 00-1.238 2.984v57.178a4.233 4.233 0 001.238 2.984 4.223 4.223 0 002.98 1.239h57.114a4.223 4.223 0 002.98-1.239 4.233 4.233 0 001.238-2.984v-57.178a4.233 4.233 0 00-1.238-2.984 4.223 4.223 0 00-2.98-1.239z'
        />
        <path
          fill='#E6E6E6'
          d='M438.165 209.411h-57.113a4.227 4.227 0 00-4.219 4.223v57.178a4.227 4.227 0 004.219 4.223h57.113a4.226 4.226 0 004.219-4.223v-57.178a4.226 4.226 0 00-4.219-4.223z'
        />
        <path
          fill='#14BC6F'
          d='M219.122 218.832h-57.113a4.229 4.229 0 01-4.219-4.223v-57.177a4.227 4.227 0 014.219-4.224h57.113a4.224 4.224 0 014.219 4.224v57.177a4.233 4.233 0 01-1.237 2.985 4.219 4.219 0 01-2.982 1.238z'
        />
        <path
          fill='#FBBEBE'
          d='M131.586 353.386l7.606-2.337-5.384-30.485-11.227 3.449 9.005 29.373z'
        />
        <path
          fill='#2F2E41'
          d='M156.03 353.614l-23.907 7.345-2.835-9.247 14.67-4.508a9.668 9.668 0 0112.072 6.41z'
        />
        <path
          fill='#FBBEBE'
          d='M66.685 353.562l7.957-.001 3.785-30.725-11.743.001.001 30.725z'
        />
        <path
          fill='#2F2E41'
          d='M89.987 360.957l-25.006.001-.001-9.672 15.345-.001a9.654 9.654 0 018.927 5.971 9.677 9.677 0 01.736 3.701z'
        />
        <path
          fill='#FBBEBE'
          d='M66.114 264.496a5.95 5.95 0 01-1.41-7.116 5.96 5.96 0 011.617-2.01l6.169-77.77 12.546 3.138-10.046 75.863a5.99 5.99 0 01-.935 7.895 5.976 5.976 0 01-7.941 0zm25.124-117.06c8.804 0 15.941-7.145 15.941-15.958 0-8.814-7.137-15.959-15.94-15.959-8.804 0-15.941 7.145-15.941 15.959 0 8.813 7.137 15.958 15.94 15.958z'
        />
        <path
          fill='#CCC'
          d='M77.377 175.207c-3.596-4.943-3.707-11.751.167-16.48 2.529-3.085 6.758-5.555 13.82-5.555 18.821 0 25.96 14.944 25.96 14.944s7.788 14.295 3.245 27.29-4.543 14.294-4.543 14.294l-29.855-2.599s5.037-12.885-8.794-31.894z'
        />
        <path
          fill='#CCC'
          d='M68.171 188.143l6.432-21.065a10.071 10.071 0 015.713-6.337 10.05 10.05 0 018.52.33 10.089 10.089 0 014.865 5.591 10.1 10.1 0 01-.265 7.41l-8.177 18.642-17.088-4.571z'
        />
        <path
          fill='#2F2E41'
          d='M115.377 206.451L140.689 337.7l-13.63 3.898-33.1-86.416-14.927 88.365-14.278 1.949S58.49 228.578 86.17 207.101l29.206-.65z'
        />
        <path
          fill='#FBBEBE'
          d='M151.807 200.3a6.77 6.77 0 00-.625.877l-32.149 1.56-6.19-18.92-10.749 5.114 10.116 28.18 39.761-7.784a6.799 6.799 0 007.549 1.727 6.817 6.817 0 004.285-6.457 6.822 6.822 0 00-4.517-6.297 6.802 6.802 0 00-7.481 2z'
        />
        <path
          fill='#CCC'
          d='M97.596 170.004a10.085 10.085 0 012.686-8.102 10.043 10.043 0 017.933-3.127 10.077 10.077 0 016.701 3.16 10.104 10.104 0 012.733 6.892v20.36l-17.482 2.693-2.571-21.876z'
        />
        <path
          fill='#2F2E41'
          d='M94.744 147.581c-2.393-.011-4.238-2.533-4.131-4.925.107-2.393 1.712-4.498 3.647-5.908 1.934-1.41 4.202-2.269 6.359-3.306 2.156-1.037 4.294-2.33 5.626-4.32a9.568 9.568 0 00.664-8.774 14.074 14.074 0 00-6.057-6.643 18.398 18.398 0 00-13.251-2.097 18.414 18.414 0 00-11.157 7.458l-2.73 7.444c-2.784 3.35-3.225 8.326-1.515 12.332 1.711 4.007 5.354 7.021 9.464 8.454a23.204 23.204 0 0012.877.387'
        />
        <path
          fill='#2F2E41'
          d='M80.176 122.786c-1.174-1.883-3.21-3.082-5.313-3.785a16.678 16.678 0 00-14.674 2.032 16.713 16.713 0 00-7.284 12.914c-.223 4.103 1.06 8.115 1.814 12.154.755 4.039.905 8.499-1.32 11.952-1.694 2.631-4.52 4.286-7.308 5.7-2.325 1.179-4.839 2.285-7.434 2.05-2.594-.235-5.202-2.292-5.134-4.9a20.855 20.855 0 00-1.74 5.9c-.183 2.051.322 4.263 1.802 5.693 2.15 2.078 5.557 1.926 8.512 1.478 6.397-.971 12.875-2.791 17.93-6.836 5.054-4.044 8.407-10.704 7.076-17.043-.556-2.648-1.862-5.066-2.918-7.557-1.056-2.49-1.88-5.215-1.395-7.877a9.585 9.585 0 014.863-6.486 12.714 12.714 0 018.127-1.234c1.817.299 4.085.762 5.162-.732a2.93 2.93 0 00-.06-3.124 8.6 8.6 0 00-2.332-2.313'
        />
        <path
          fill='#3F3D56'
          d='M200.212 362H.772a.776.776 0 01-.296-1.489.754.754 0 01.297-.058h199.439a.792.792 0 01.548.225.778.778 0 010 1.096.778.778 0 01-.548.226z'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0h682v362H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Results;
