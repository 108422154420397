import React from 'react';

function MSquaresThree5() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='700'
      height='974'
      fill='none'
      viewBox='0 0 700 974'
    >
      <path
        fill='#CFE0FF'
        d='M33.165 373.872C1.731 335.071 7.703 278.134 46.504 246.7L326.128 20.165C364.929-11.269 421.866-5.297 453.3 33.504l226.535 279.624c31.434 38.801 25.462 95.738-13.339 127.172L386.872 666.835c-38.801 31.434-95.738 25.462-127.172-13.339L33.165 373.872zM7.367 761.597c-11.484-14.177-9.302-34.979 4.874-46.463l102.162-82.767c14.177-11.484 34.979-9.302 46.463 4.874l82.767 102.162c11.484 14.177 9.302 34.979-4.874 46.463l-102.162 82.767c-14.177 11.484-34.979 9.302-46.463-4.874L7.367 761.597zm219.566 151.327c-6.131-7.568-4.966-18.674 2.602-24.805l54.541-44.186c7.568-6.131 18.674-4.966 24.805 2.602l44.186 54.541c6.131 7.568 4.966 18.674-2.602 24.805l-54.541 44.186c-7.568 6.131-18.674 4.966-24.805-2.602l-44.186-54.541z'
      />
    </svg>
  );
}

export default MSquaresThree5;
