import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Instagram } from 'src/assets/images/social/instagram.svg';
import { ReactComponent as Skype } from 'src/assets/images/social/skype.svg';
import { ReactComponent as LinkedIn } from 'src/assets/images/social/linkedin.svg';

const Socials = ({ className, gap = 30, iconSize = 20 }) => {
  return (
    <Container className={className} $gap={gap} iconSize={iconSize}>
      <StyledLink
        target='_blank'
        aria-hidden='true'
        href='https://instagram.com/bitcomsolution?igshid=16kfx175kv0co'
      >
        <Instagram />
      </StyledLink>
      <StyledLink
        target='_blank'
        aria-hidden='true'
        href='https://www.linkedin.com/company/bitcomsolution/'
      >
        <LinkedIn />
      </StyledLink>
      <StyledLink
        target='_blank'
        aria-hidden='true'
        href='skype:mishasav_97?call'
      >
        <Skype />
      </StyledLink>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  gap: ${({ $gap }) => $gap}px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  svg {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    path {
      fill: #434242;
      transition: all 0.3s ease-in-out;
    }
  }

  svg:hover {
    path {
      fill: var(--green-main);
    }
  }
`;

const StyledLink = styled.a`
  z-index: 1;
`;

export default Socials;
