import React from 'react';

function MSquares6() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='173'
      height='173'
      fill='none'
      viewBox='0 0 173 173'
    >
      <path
        fill='#CFE0FF'
        d='M5.078 94.148c-7.916-9.77-6.412-24.108 3.359-32.024L78.852 5.078c9.77-7.916 24.108-6.412 32.024 3.359l57.046 70.415c7.916 9.77 6.412 24.108-3.359 32.024l-70.415 57.046c-9.77 7.916-24.108 6.412-32.024-3.359L5.078 94.148z'
      />
    </svg>
  );
}

export default MSquares6;
