import styled from 'styled-components/macro';
import { SharedContainer } from 'src/components';
import { default as technicalBackend } from 'src/assets/images/cases/cs/backend-platform.svg';
import { default as technicalFrontend } from 'src/assets/images/cases/cs/frontend-platform.svg';
import technicalEarth from 'src/assets/images/cases/cs/technical-earth.png';
import technicalTheme from 'src/assets/images/cases/cs/technical-theme.png';
import technicalBackground from 'src/assets/images/cases/cs/technical.png';
import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/style/media';
import listStyle from 'src/assets/images/cases/cs/list-style.svg';
import { SliderProject } from 'src/components';
import { MDecoration } from 'src/components/svg/cases/cs';

const TechnicalSection = () => {
  const { t } = useTranslation(['cs']);
  return (
    <SectionWrapper>
      <MDecoration />
      <SharedContainer>
        <TechnicalMain>
          <Title>{t('cs:technical.frontend.title')}</Title>
          <TechnicalItem>
            <p>{t('cs:technical.frontend.text.1')}</p>
            <ul>
              <li>{t('cs:technical.frontend.list.text.1')}</li>
              <li>{t('cs:technical.frontend.list.text.2')}</li>
              <li>{t('cs:technical.frontend.list.text.3')}</li>
              <li>{t('cs:technical.frontend.list.text.4')}</li>
            </ul>
            <p>{t('cs:technical.frontend.text.3')}</p>
            <p>{t('cs:technical.frontend.text.4')}</p>
          </TechnicalItem>
          <TechnicalItem>
            <p>{t('cs:technical.frontend.text.2')}</p>
            <img src={technicalTheme} alt='' />
          </TechnicalItem>
          <TechnicalItemEarth>
            <img src={technicalEarth} alt='' />
            <p>{t('cs:technical.frontend.text.5')}</p>
          </TechnicalItemEarth>
          <TechnicalItem>
            <h2>{t('cs:technical.backend.title')}</h2>
            <p>{t('cs:technical.backend.text.1')}</p>
            <p>{t('cs:technical.backend.text.2')}</p>
            <p>{t('cs:technical.backend.text.3')}</p>
            <p>{t('cs:technical.backend.text.4')}</p>
            <p>{t('cs:technical.backend.text.5')}</p>
          </TechnicalItem>
          <TechnicalItem>
            <h2>{t('cs:technical.requirements.title')}</h2>
            <TechnicalItemCol>
              <h4>{t('cs:technical.requirements.front')}</h4>
              <object
                data={technicalFrontend}
                type='image/svg+xml'
                aria-label='frontend technologies'
              />
            </TechnicalItemCol>
            <TechnicalItemCol>
              <h4>{t('cs:technical.requirements.back')}</h4>
              <object
                data={technicalBackend}
                type='image/svg+xml'
                aria-label='backend technologies'
              />
            </TechnicalItemCol>
          </TechnicalItem>
          <TechnicalItem>
            <h2>{t('cs:technical.conclusion.title')}</h2>
            <p>{t('cs:technical.conclusion.text')}</p>
          </TechnicalItem>
          <TechnicalItem>
            <StyledSliderProject
              prevName='pdq'
              nextName='saikt'
              fillColor='var(--cs-title-color)'
            />
          </TechnicalItem>
        </TechnicalMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: url(${technicalBackground}) center top / cover no-repeat #000d30;
  padding: 14px 0 min(303px, 16.8vw);
  position: relative;
  z-index: 0;

  @media ${media.tabletL()} {
    padding: 14px 0 60px;
  }

  h2 {
    color: var(--cs-title-color);
    margin-bottom: 28px;
  }

  p,
  ul,
  li,
  img {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  p,
  ul li {
    font-size: 18px;
    color: var(--white);
    line-height: 22.94px;
  }

  ul li {
    padding-left: 33px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      background: url(${listStyle}) center / contain no-repeat;
      width: 23px;
      height: 23px;
    }
  }

  > svg {
    position: absolute;
    bottom: -1px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 13.96vw;

    @media ${media.tabletL()} {
      display: none;
    }
  }
`;

const TechnicalMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 0;
  user-select: text;
  padding-left: 30px;

  @media ${media.laptopS()} {
    padding-left: 0;
  }
`;

const Title = styled.h2`
  width: 100%;
`;

const TechnicalItem = styled.div`
  @media ${media.laptopL()} {
    margin-bottom: 60px;
  }

  &:nth-of-type(1) {
    width: calc(30% + 60px);
    margin-bottom: 65px;

    @media ${media.laptopM()} {
      width: 100%;
      margin-bottom: 22px;
    }
  }

  &:nth-of-type(2) {
    width: calc(60% - 34px);
    margin-left: auto;

    @media ${media.laptopM()} {
      width: 100%;
    }

    p {
      margin: 15px 0 -76px 228px;

      @media ${media.laptopL()} {
        margin: 0 0 30px;
      }
    }
  }

  &:nth-of-type(3) {
    margin-bottom: -25px;
  }

  &:nth-of-type(4) {
    width: calc(50% + -54px);
    margin-left: auto;
    margin-top: -163px;

    @media ${media.laptopM()} {
      width: 100%;
      margin-top: 0;
    }
  }

  &:nth-of-type(5) {
    width: 100%;
    margin-top: -195px;
    margin-bottom: 97px;

    @media ${media.desktopS()} {
      h2 {
        max-width: 500px;
      }
    }

    @media ${media.laptopM()} {
      margin-top: 0;
      h2 {
        max-width: unset;
      }
    }
  }

  &:nth-of-type(6) {
    width: 100%;
    max-width: 600px;
    margin-bottom: 70px;
  }

  &:nth-of-type(7) {
    width: 100%;
  }
`;

const TechnicalItemEarth = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;

  @media ${media.laptopM()} {
    margin: 0 0 60px;
  }

  @media ${media.laptopS()} {
    flex-wrap: wrap;
  }

  img {
    width: min(100%, 568px);
  }

  p {
    @media ${media.laptopS('min')} {
      max-width: 374px;
      margin: 0 20px 52px;
    }
  }
`;

const TechnicalItemCol = styled.div`
  text-align: center;

  @media ${media.tabletL('min')} {
    display: inline-block;
    vertical-align: top;

    &:first-of-type {
      margin-right: 151px;
    }
  }

  @media ${media.tabletL()} {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  h4 {
    font-weight: 600;
    margin-bottom: 26px;

    color: var(--white);
    font-size: 20px;
  }

  img {
    user-select: none;
  }
`;

const StyledSliderProject = styled(SliderProject)`
  margin: 0 0 0 auto;

  @media ${media.tabletL()} {
    margin: 0 auto;
  }

  color: var(--white);
`;

export default TechnicalSection;
