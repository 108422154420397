import { PreviewLogo } from 'src/components/svg/cases/cs';
import styled from 'styled-components/macro';
import { media } from 'src/assets/style/media';
import previewBackground from 'src/assets/images/cases/cs/preview.jpg';
import { useTranslation } from 'react-i18next';

const PreviewSection = () => {
  const { t } = useTranslation(['cs']);

  return (
    <PreviewWrapper>
      <PreviewContainer>
        <PreviewMain>
          <PreviewSubtitle>{t('cs:preview.subtitle')}</PreviewSubtitle>
          <PreviewLogo />
        </PreviewMain>
      </PreviewContainer>
    </PreviewWrapper>
  );
};

const PreviewWrapper = styled.section`
  background: url(${previewBackground}) center / cover no-repeat;
  padding: 100px 15px;
  font-family: 'gyre heros';
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px - 120px);
  max-height: 1000px;
  background-color: linear-gradient(180deg, #83c652, #b1e46e);

  margin-left: auto;
  margin-right: auto;
  @media ${media.laptopL('min')} {
    max-width: calc(1440px + 15px);
  }

  @media ${media.laptopM('min')} {
    max-width: calc(1280px + 15px);
  }

  @media ${media.laptopS('min')} {
    max-width: calc(992px + 15px);
  }
`;

const PreviewMain = styled.div`
  background: linear-gradient(
    90.14deg,
    rgba(0, 0, 0, 0) 0.13%,
    #000000 51.74%,
    rgba(0, 0, 0, 0) 101.25%
  );
  text-align: center;
  padding: 15px 0;

  svg {
    width: min(540px, 100%);
    display: inline-block;
  }
`;

const PreviewSubtitle = styled.h1`
  margin-bottom: 17px;

  font-size: 56px;
  color: var(--white);

  @media ${media.tabletL()} {
    font-size: 36px;
  }
`;

export default PreviewSection;
