function MCircle2() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='67'
      height='67'
      fill='none'
      viewBox='0 0 67 67'
    >
      <circle cx='33.5' cy='33.5' r='33.5' fill='#fff' />
    </svg>
  );
}

export default MCircle2;
