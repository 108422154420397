import Parallax from 'parallax-js';
import React, { useEffect, useRef } from 'react';

const ParallaxControl = ({
  children,
  speed = '1',
  limitY,
  limitX,
  hoverOnly,
}) => {
  const sceneEl = useRef(null);

  const isMobileTo = window.innerWidth < 768;

  useEffect(() => {
    if (isMobileTo) return;
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
      ...(limitY && { limitY }),
      ...(limitX && { limitX }),
      ...(hoverOnly && { hoverOnly }),
    });

    parallaxInstance.enable();

    return () => {
      return isMobileTo ? null : parallaxInstance.disable();
    };
  }, [isMobileTo, limitY, limitX, hoverOnly]);

  return !isMobileTo ? (
    <div className='control' ref={sceneEl}>
      {speed !== '' ? (
        <div className='control__type' data-depth={speed}>
          {children}
        </div>
      ) : (
        { children }
      )}
    </div>
  ) : (
    <>{children}</>
  );
};

export default ParallaxControl;
