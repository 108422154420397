import React from 'react';

function MLeaflet({ className = '' }) {
  return (
    <svg
      viewBox='0 0 67 82'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='67'
      height='82'
      fill='none'
    >
      <path
        fill='#14BC6F'
        d='M32.724 44.945C38.797 67.395 59.6 81.299 59.6 81.299s10.956-22.495 4.883-44.945C58.407 13.904 37.605 0 37.605 0S26.65 22.495 32.723 44.945z'
      />
      <path
        fill='#3F3D56'
        d='M41.648 40.12c16.665 16.223 18.7 41.16 18.7 41.16S35.365 79.917 18.7 63.695C2.035 47.47 0 22.534 0 22.534s24.983 1.364 41.648 17.586z'
      />
    </svg>
  );
}

export default MLeaflet;
