import styled from 'styled-components/macro';
import { ParallaxControl, ParallaxControlScroll } from 'src/components';
import {
  MCircle,
  MCircleIn,
  MDecorBig,
  MDecorLess,
  MDecorSmall,
  MLeaflet,
} from 'src/components/svg/cases/pdq/models';
import { media } from 'src/assets/style/media';

const DecorLayers = () => {
  return (
    <>
      <DecorLayer1>
        <MDecorSmall />
      </DecorLayer1>
      <DecorLayer2>
        <MDecorBig />
      </DecorLayer2>
      <DecorLayer3>
        <MCircleIn />
      </DecorLayer3>
      <DecorLayer4>
        <ParallaxControlScroll className='custom-class' y={[-20, 100]}>
          <ParallaxControl speed='-.5'>
            <MLeaflet className='layer--leaflet' />
          </ParallaxControl>
        </ParallaxControlScroll>
      </DecorLayer4>
      <DecorLayer5>
        <ParallaxControl speed='.3' limitY={1}>
          <MDecorLess />
        </ParallaxControl>
      </DecorLayer5>
      <DecorLayer6>
        <ParallaxControlScroll className='custom-class' y={[-20, 50]}>
          <ParallaxControl speed='.3'>
            <MCircle />
          </ParallaxControl>
        </ParallaxControlScroll>
      </DecorLayer6>
      <DecorLayer7>
        <ParallaxControlScroll className='custom-class' y={[-20, 50]}>
          <ParallaxControl speed='-.1'>
            <MCircleIn />
          </ParallaxControl>
        </ParallaxControlScroll>
      </DecorLayer7>
      <DecorLayer8>
        <ParallaxControlScroll className='custom-class' y={[-20, 50]}>
          <ParallaxControl speed='.1'>
            <MCircle />
          </ParallaxControl>
        </ParallaxControlScroll>
      </DecorLayer8>
      <DecorLayer9>
        <ParallaxControlScroll className='custom-class' y={[0, 50]}>
          <ParallaxControl speed='-.3'>
            <MCircle />
          </ParallaxControl>
        </ParallaxControlScroll>
      </DecorLayer9>
    </>
  );
};

const DecorLayer1 = styled.div`
  display: none;
  @media ${media.laptopS('min')} {
    display: block;
    position: absolute;
    right: 23%;
    bottom: calc(100% - 49px);
    z-index: -2;
  }
`;

const DecorLayer2 = styled.div`
  display: none;
  @media ${media.laptopS('min')} {
    display: block;
    z-index: 2;
    position: absolute;
    top: 134%;
    transform: translateX(-50%);
    width: min(13vw, 245px);
  }
`;

const DecorLayer3 = styled.div`
  display: none;
  @media ${media.laptopS('min')} {
    display: block;
    position: absolute;
    top: 136%;
    left: 6%;
    width: min(11vw, 160px);
    height: min(11vw, 160px);
  }
`;

const DecorLayer4 = styled.div`
  position: absolute;
  top: calc(100% + 13vw - -6px);
  right: calc(100%);
  width: 67px;
  height: 82px;

  @media ${media.laptopS()} {
    right: calc(100% - 40px);
    width: 37px;
    height: 62px;
    top: calc(100% + 1vw - -6px);
  }
`;

const DecorLayer5 = styled.div`
  display: none;
  @media ${media.laptopS('min')} {
    display: block;
    position: absolute;
    top: 100%;
    width: min(30vw, 228px);
    left: 0;
    transform: translate(-70%, -70%);
  }
`;

const DecorLayer6 = styled.div`
  position: absolute;
  top: calc(15% + 9px);
  left: calc(100% + 50px);
  width: min(20vw, 319px);
  height: min(20vw, 319px);
  opacity: 0.5;
  z-index: -2;
`;

const DecorLayer7 = styled.div`
  position: absolute;
  top: calc(100% + 36px);
  z-index: -1;
  left: calc(100% + 105px);
  width: min(10vw, 162px);
  height: min(10vw, 162px);
`;

const DecorLayer8 = styled.div`
  position: absolute;
  top: calc(100% + 107px);
  left: calc(100% - 37px);
  width: min(20vw, 319px);
  height: min(20vw, 319px);
  opacity: 0.5;
  z-index: -2;
`;

const DecorLayer9 = styled.div`
  position: absolute;
  top: calc(150% + 107px);
  left: calc(109% - 37px);
  width: min(20vw, 414px);
  height: min(20vw, 414px);
  opacity: 0.5;
  z-index: -2;
`;

export default DecorLayers;
