import styled from 'styled-components/macro';
import conceptOne from 'src/assets/images/cases/ark/concept-1.png';
import conceptTwo from 'src/assets/images/cases/ark/concept-2.png';
import conceptThree from 'src/assets/images/cases/ark/concept-3.png';
import image from 'src/assets/images/cases/ark/image.png';
import { ParallaxControl } from 'src/components';
import {
  MShape,
  MCircle1,
  MCircle2,
  MCircle3,
  MCircle5,
} from 'src/components/svg/cases/ark/models';
import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/style/media';

const ProjectLayers = () => {
  const { t } = useTranslation(['ark']);

  return (
    <ProjectLayersWrapper>
      <Layer1>
        <MShape />
      </Layer1>
      <Layer2>
        <ParallaxControl speed='.05'>
          <Concept>
            <label>{t('ark:ark.project.title.6')}</label>
            <img src={conceptOne} alt='' />
          </Concept>
        </ParallaxControl>
      </Layer2>
      <Layer3>
        <ParallaxControl speed='-.05'>
          <Concept>
            <label>{t('ark:ark.project.title.6')}</label>
            <img src={conceptTwo} alt='' />
          </Concept>
        </ParallaxControl>
      </Layer3>
      <Layer4>
        <ParallaxControl speed='.05'>
          <Concept>
            <label>{t('ark:ark.project.title.6')}</label>
            <img src={conceptThree} alt='' />
          </Concept>
        </ParallaxControl>
      </Layer4>
      <Layer5>
        <ParallaxControl speed='-.2'>
          <img src={image} alt='' />
        </ParallaxControl>
      </Layer5>
      <Layer6>
        <ParallaxControl speed='-.02'>
          <MCircle1 />
        </ParallaxControl>
      </Layer6>
      <Layer7>
        <ParallaxControl speed='.03'>
          <MCircle2 />
        </ParallaxControl>
      </Layer7>
      <Layer8>
        <ParallaxControl speed='-.4'>
          <MCircle3 />
        </ParallaxControl>
      </Layer8>
      <Layer9>
        <ParallaxControl speed='.02'>
          <MCircle5 />
        </ParallaxControl>
      </Layer9>
      <Layer10>
        <ParallaxControl speed='.01'>
          <MCircle2 />
        </ParallaxControl>
      </Layer10>
      <Layer11>
        <ParallaxControl speed='.3'>
          <MCircle2 />
        </ParallaxControl>
      </Layer11>
      <Layer12>
        <ParallaxControl speed='-.01'>
          <MCircle5 />
        </ParallaxControl>
      </Layer12>
      <Layer13>
        <ParallaxControl speed='.3'>
          <MCircle1 />
        </ParallaxControl>
      </Layer13>
      <Layer14>
        <MCircle1 />
      </Layer14>
    </ProjectLayersWrapper>
  );
};

const ProjectLayersWrapper = styled.div``;

const Concept = styled.div`
  label {
    color: black;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    letter-spacing: 1px;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Layer1 = styled.div`
  position: absolute;
  top: calc(100% - 20px);
  left: calc(50% - -114px);
  width: auto;
  transform: translate(-50%, -50%);

  @media ${media.laptopL()} {
    top: calc(100% + 100px);
  }

  @media ${media.laptopM()} {
    width: 100vw;
    left: 50%;
    top: calc(100% - 100px);
  }

  @media ${media.laptopS()} {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    width: 100%;
    background-color: #ffffff;
    height: 50%;
    z-index: 1;
  }

  svg {
    filter: drop-shadow(-10px -10px 20px rgba(0, 0, 0, 0.4));
  }
`;

const Layer2 = styled.div`
  position: absolute;
  top: calc(22% - 56px);
  right: calc(100% - min(40vw / 2, 550px / 2));
  width: min(40vw, 500px);
  height: min(40vw, 500px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer3 = styled.div`
  position: absolute;
  top: calc(33% - 60px);
  left: calc(6% - -1px);
  width: min(40vw, 500px);
  height: min(40vw, 500px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer4 = styled.div`
  position: absolute;
  top: calc(33% - -15px);
  left: calc(50% - 102px);
  width: min(40vw, 500px);
  height: min(40vw, 500px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer5 = styled.div`
  position: absolute;
  top: calc(32% - 13px);
  right: -13%;
  width: min(40vw, 500px);
  height: min(40vw, 500px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer6 = styled.div`
  position: absolute;
  top: calc(22% - -12px);
  left: 18%;
  width: min(10vw, 45px);
  height: min(10vw, 45px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer7 = styled.div`
  position: absolute;
  top: calc(22% - -90px);
  left: calc(19% - 5px);
  width: min(10vw, 67px);
  height: min(10vw, 67px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer8 = styled.div`
  position: absolute;
  top: calc(24% - -45px);
  left: calc(23% - 4px);
  width: min(10vw, 84px);
  height: min(10vw, 84px);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer9 = styled.div`
  position: absolute;
  top: calc(27% - -65px);
  left: calc(29% - 8px);
  width: min(10vw, 55px);
  height: min(10vw, 55px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer10 = styled.div`
  position: absolute;
  top: calc(29% - -59px);
  left: calc(38% - 6px);
  width: min(10vw, 37px);
  height: min(10vw, 37px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer11 = styled.div`
  position: absolute;
  top: calc(28% - -66px);
  right: calc(34% - 10px);
  width: min(10vw, 67px);
  height: min(10vw, 67px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer12 = styled.div`
  position: absolute;
  top: calc(30% - -62px);
  right: calc(23% - 10px);
  width: min(10vw, 164px);
  height: min(10vw, 164px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer13 = styled.div`
  position: absolute;
  top: calc(29% - -41px);
  right: calc(100% - 85px);
  width: min(10vw, 70px);
  height: min(10vw, 70px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer14 = styled.div`
  position: absolute;
  top: calc(31% - -62px);
  right: calc(100% - -58px);
  width: min(20vw, 221px);
  height: min(20vw, 221px);
  z-index: -1;

  @media ${media.laptopL()} {
    display: none;
  }
`;

export default ProjectLayers;
