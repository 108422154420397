import styled from 'styled-components/macro';
import objectivesBackground from 'src/assets/images/cases/cs/objectives.jpg';
import listIcon from 'src/assets/images/cases/cs/list-style.svg';
import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/style/media';
import { ParallaxControlScroll, SharedContainer } from 'src/components';

const ObjectivesSection = () => {
  const { t } = useTranslation(['cs']);
  return (
    <SectionWrapper>
      <SharedContainer>
        <ObjectivesMain>
          <ObjectivesItem>
            <ParallaxControlScroll x={[-10, 10]}>
              <h2>{t('cs:objectives.1.title')}</h2>
              <p>{t('cs:objectives.1.text')}</p>
            </ParallaxControlScroll>
          </ObjectivesItem>
          <ObjectivesItem>
            <ParallaxControlScroll x={[-20, 10]}>
              <h2>{t('cs:objectives.2.title')}</h2>
              <ul>
                <li>{t('cs:objectives.2.list.1')}</li>
                <li>{t('cs:objectives.2.list.2')}</li>
                <li>{t('cs:objectives.2.list.3')}</li>
              </ul>
            </ParallaxControlScroll>
          </ObjectivesItem>
          <ObjectivesItem>
            <ParallaxControlScroll x={[-10, 20]}>
              <h2>{t('cs:objectives.3.title')}</h2>
              <ul>
                <li>{t('cs:objectives.3.list.1')}</li>
                <li>{t('cs:objectives.3.list.2')}</li>
                <li>{t('cs:objectives.3.list.3')}</li>
                <li>{t('cs:objectives.3.list.4')}</li>
                <li>{t('cs:objectives.3.list.5')}</li>
              </ul>
            </ParallaxControlScroll>
          </ObjectivesItem>
        </ObjectivesMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: url(${objectivesBackground}) center / cover no-repeat;
  padding: 77px 0;
  box-shadow: 1px -42px 20px -8px #358effa1, 1px -66px 40px -3px #358effa1;
  position: relative;
  z-index: 3;
  user-select: text;
`;

const ObjectivesMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  h2 {
    line-height: 1.25;
    margin-bottom: 29px;
    color: var(--cs-title-color);
  }

  p,
  ul li {
    color: var(--white);
    font-size: 18px;
    line-height: 22.94px;

    @media ${media.laptopM('min')} {
      max-width: 80%;
    }
  }

  ul li {
    padding-left: 33px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      background: url(${listIcon}) center / contain no-repeat;
      width: 23px;
      height: 23px;
    }
  }
`;

const ObjectivesItem = styled.div`
  position: relative;
  z-index: 1;
  --bs-general--brand-color: #{$cs-color};

  &:first-child {
    width: calc(50% - 70px);

    @media ${media.laptopL()} {
      width: 50%;
      margin-bottom: 40px;
    }

    @media ${media.laptopM()} {
      width: 100%;
    }
  }

  &:nth-child(2) {
    width: calc(50% - 60px);
    margin: 187px 0 0 auto;

    @media ${media.laptopL()} {
      width: calc(50% - 15px);
      margin: 187px 0 30px 15px;
    }

    @media ${media.laptopM()} {
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &:last-child {
    width: calc(50% + 180px);
    margin: -56px 0 0 71px;

    @media ${media.laptopL()} {
      width: calc(50% + 180px);
      margin: 15px 0 30px 71px;
    }

    @media ${media.laptopM()} {
      width: 100%;
      margin: 0 0 40px;
    }
  }
`;

export default ObjectivesSection;
