import { useTranslation } from 'react-i18next';
import { SharedContainer, ParallaxControl } from 'src/components';
import styled, { css } from 'styled-components/macro';
import desktopImage from 'src/assets/images/cases/saikt/desktop.png';
import desktopImageSmall from 'src/assets/images/cases/saikt/desktop-small.webp';
import mobileImage from 'src/assets/images/cases/saikt/mobile.png';

import { media } from 'src/assets/style/media';
import { SaiktLayers } from './components';

const SaiktProjectSection = () => {
  const { t } = useTranslation(['saikt']);

  return (
    <SectionWrapper>
      <SaiktShape />
      <SharedContainer>
        <SaiktMain>
          <h2>{t('saikt:saikt.project.title')}</h2>
          <SaiktProjectDesign>
            <SaiktTitle $CSS={ProjectDesignTitle}>
              <h2>{t('saikt:saikt.project.decs.title')}</h2>
              <SaiktTitleShape $CSS={ProjectDesignTitleShape}>
                <ParallaxControl speed='.2' limitY={1}>
                  {t('saikt:saikt.project.decs.title')}
                </ParallaxControl>
              </SaiktTitleShape>
            </SaiktTitle>
            <p>{t('saikt:saikt.project.desc.text.1')}</p>
          </SaiktProjectDesign>
          <SaiktProjectDesktop>
            <SaiktTitle $CSS={ProjectDesktopTitle}>
              <h2>{t('saikt:saikt.project.desktop.title')}</h2>
              <SaiktTitleShape $CSS={ProjectDesktopShape}>
                <ParallaxControl speed='-.2' limitY={1}>
                  {t('saikt:saikt.project.desktop.title')}
                </ParallaxControl>
              </SaiktTitleShape>
            </SaiktTitle>
            <DesktopDesignLarge src={desktopImage} alt='desktop design' />
            <DesktopDesignSmall src={desktopImageSmall} alt='desktop design' />
          </SaiktProjectDesktop>
          <SaiktProjectMobile>
            <SaiktTitle>
              <h2>{t('saikt:saikt.project.mobile.title')}</h2>
              <SaiktTitleShape $CSS={ProjectMobileShape}>
                <ParallaxControl speed='-.2' limitY={1}>
                  {t('saikt:saikt.project.mobile.title')}
                </ParallaxControl>
              </SaiktTitleShape>
            </SaiktTitle>
            <img src={mobileImage} alt='mobile' />
          </SaiktProjectMobile>
          <SaiktLayers />
        </SaiktMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  padding: 78px 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 300px;
    background-color: var(--saikt-main-color);
  }

  &:before {
    top: 0;
    height: 400px;
    border-radius: 90px;

    @media ${media.tabletL()} {
      border-radius: 30px;
    }
  }

  &:after {
    top: calc(100% - calc(20vw - 64px));
    border-radius: 90px;
    height: min(50%, 140vw);
    z-index: -1;
    left: 50%;
    transform: translate(-50%, -50%) skewY(11.5deg) translateY(-50%);

    @media ${media.tabletL()} {
      border-radius: 30px;
    }
  }

  p {
    color: var(--saikt-text-color);
    font-size: 18px;
    line-height: 22.94px;

    @media ${media.laptopM('min')} {
      max-width: 56%;
    }
  }

  @media ${media.laptopM()} {
    margin-bottom: 70px;
  }

  @media ${media.tabletS()} {
    padding: 30px 0;
  }
`;

const SaiktShape = styled.div`
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  bottom: min(600px, 30vw);
  background-color: var(--saikt-main-color);
`;

const SaiktMain = styled.div`
  position: relative;

  > h2 {
    text-align: center;
    margin-bottom: 74px;
    position: relative;
    z-index: 1;

    @media ${media.tabletS()} {
      margin-bottom: 30px;
    }
  }
`;

const SaiktProjectDesign = styled.div`
  margin: 0 min(168px, calc(10vw - 24px));
  margin-bottom: 39px;
  position: relative;
  z-index: 1;
`;

const ProjectDesignTitle = css`
  margin-bottom: 27px;
`;

const ProjectDesignTitleShape = css`
  bottom: 0;
  left: calc(50% + 17px);
  line-height: min(15vw, 125px);
`;

const SaiktTitle = styled.div`
  display: inline-block;
  position: relative;

  h2 {
    position: relative;
    z-index: 1;
  }
  ${({ $CSS }) => $CSS};
`;

const SaiktTitleShape = styled.div`
  position: absolute;
  color: #cfe0ff;
  font-size: min(15vw, 160px);
  line-height: min(15vw, 195px);
  font-weight: 700;

  ${({ $CSS }) => $CSS};
`;

const SaiktProjectDesktop = styled.div`
  text-align: center;
  margin-bottom: min(303px, calc(16vw - 5px));
  position: relative;
  z-index: 1;

  img {
    position: relative;
    z-index: 1;
  }
`;

const ProjectDesktopTitle = css`
  margin-bottom: 67px;
  @media ${media.tabletS()} {
    margin-bottom: 30px;
  }
`;

const ProjectDesktopShape = css`
  top: calc(100% - 53px);
  left: 50%;
  transform: translateX(-50%);
  line-height: min(25vw, 125px);
  white-space: nowrap;
`;

const SaiktProjectMobile = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: min(50%, calc(50vw));

  @media ${media.laptopL('min')} {
    margin: 0 min(186px, calc(10vw - 6px));
  }

  img {
    position: absolute;
    bottom: 0;
    left: 100%;
    max-width: min(1229px, 70vw);
    max-height: min(1388px, calc(70vw + 48px));
    transform: translate(-65%, 10%);
    z-index: 0;

    @media ${media.tabletS()} {
      position: relative;
      max-width: unset;
      max-height: unset;
      transform: translate(0, 0);
      left: 0;
    }
  }

  @media ${media.tabletS()} {
    padding-bottom: 0;
  }
`;

const ProjectMobileShape = css`
  top: calc(50% - -11px);
  left: calc(50% + 21px);
  transform: translate(-50%, -50%);
`;

const DesktopDesignLarge = styled.img`
  @media ${media.tabletS()} {
    display: none;
  }
`;

const DesktopDesignSmall = styled.img`
  display: none;
  @media ${media.tabletS()} {
    display: block;
  }
`;

export default SaiktProjectSection;
