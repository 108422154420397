import { media } from 'src/assets/style/media';
import { ParallaxControl } from 'src/components';
import {
  MSquaresThree6,
  MSquaresThree7,
  MSquaresTwo3,
  MSquares6,
  MSquares7,
} from 'src/components/svg/cases/saikt/models';
import styled from 'styled-components/macro';

const TechnicalLayers = () => {
  return (
    <LayersWrapper>
      <Layer1>
        <ParallaxControl speed='.2'>
          <MSquaresThree6 />
        </ParallaxControl>
      </Layer1>
      <Layer2>
        <ParallaxControl speed='.2'>
          <MSquaresThree7 />
        </ParallaxControl>
      </Layer2>
      <Layer3>
        <MSquaresTwo3 />
      </Layer3>
      <Layer4>
        <MSquares6 />
      </Layer4>
      <Layer5>
        <MSquares7 />
      </Layer5>
    </LayersWrapper>
  );
};

const LayersWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
`;

const Layer1 = styled.div`
  position: absolute;
  top: -50px;
  right: 14%;
  width: min(20vw, 344px);
  height: min(30vw, 445px);

  @media ${media.laptopM()} {
    top: -100px;
  }

  @media ${media.tabletS()} {
    top: 0;
    right: 50%;
  }
`;

const Layer2 = styled.div`
  position: absolute;
  left: calc(-19% + 10px);
  top: calc(100% - min(30vw / 2, 493px / 2));
  width: min(40vw, 583px);
  height: min(30vw, 469px);

  @media ${media.laptopM()} {
    display: none;
  }
`;

const Layer3 = styled.div`
  position: absolute;
  left: calc(50% + 145px);
  top: calc(100% - min(1vw / 2, 259px / 2));
  width: min(10vw, 206px);
  height: min(15vw, 259px);

  @media ${media.laptopM()} {
    display: none;
  }
`;

const Layer4 = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  top: calc(50% - min(-7vw / 2, 260px / 2));
  width: min(20vw, 260px);
  height: min(20vw, 260px);
`;

const Layer5 = styled.div`
  position: absolute;
  left: calc(20% + -30px);
  top: calc(100% - min(-7vw / 2, 260px / 2));
  width: min(20vw, 260px);
  height: min(20vw, 260px);

  @media ${media.laptopM()} {
    display: none;
  }
`;

export default TechnicalLayers;
