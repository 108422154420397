import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { media } from 'src/assets/style/media';
import { SectionTitle } from 'src/components';

const TechnologiesSection = () => {
  const container = useRef(null);
  const firstLine = useRef(null);
  const secondLine = useRef(null);
  const thirdLine = useRef(null);
  const fourthLine = useRef(null);

  const onScroll = useCallback(() => {
    const sectionTop = container.current?.getBoundingClientRect().top;
    const sectionBottom = container.current?.getBoundingClientRect().bottom;
    if (sectionTop < 0 + window.innerHeight && sectionBottom > 0) {
      firstLine.current.style.transform = `translateX(${
        -320 + (sectionBottom / 10) * -1.5
      }px)`;
      secondLine.current.style.transform = `translateX(${
        -840 + (sectionBottom / 10) * 2.5
      }px)`;
      thirdLine.current.style.transform = `translateX(${
        -320 + (sectionBottom / 10) * -1
      }px)`;
      fourthLine.current.style.transform = `translateX(${
        -320 + (sectionBottom / 10) * -2.5
      }px)`;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <SectionContainer ref={container}>
      <SectionTitle orientation='right'>Technologies</SectionTitle>
      <AnimationContainer>
        <FirstLine ref={firstLine}>
          <FilledWord>of</FilledWord>
          <HollowWord>a</HollowWord>
          <FilledWord>mobile</FilledWord>
          <HollowWord>application.</HollowWord>
          <FilledWord>development</FilledWord>
          <HollowWord>of</HollowWord>
          <FilledWord>a</FilledWord>
          <FilledWord>mobile</FilledWord>
          <HollowWord>application.</HollowWord>
          <FilledWord>development</FilledWord>
        </FirstLine>
        <GreenLine ref={secondLine}>
          <OpacityFilledWord>03</OpacityFilledWord>
          <FilledWord>create a website</FilledWord>
          <OpacityFilledWord>03</OpacityFilledWord>
          <FilledWord>create a website</FilledWord>
          <OpacityFilledWord>03</OpacityFilledWord>
          <FilledWord>create a website</FilledWord>
        </GreenLine>
        <Line ref={thirdLine}>
          <FilledWord>individual</FilledWord>
          <HollowWord>design.</HollowWord>
          <FilledWord>individual</FilledWord>
          <HollowWord>design.</HollowWord>
          <FilledWord>individual</FilledWord>
          <HollowWord>design.</HollowWord>
          <FilledWord>individual</FilledWord>
          <HollowWord>design.</HollowWord>
        </Line>
        <Line ref={fourthLine}>
          <HollowWord>develop</HollowWord>
          <FilledWord>a</FilledWord>
          <HollowWord>mobile.</HollowWord>
          <FilledWord>app.</FilledWord>
          <FilledWord>develop</FilledWord>
          <HollowWord>a</HollowWord>
          <FilledWord>mobile.</FilledWord>
          <HollowWord>app.</HollowWord>
          <FilledWord>develop</FilledWord>
          <HollowWord>a</HollowWord>
          <FilledWord>mobile.</FilledWord>
          <HollowWord>app.</HollowWord>
          <FilledWord>develop</FilledWord>
          <HollowWord>a</HollowWord>
        </Line>
      </AnimationContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  color: #fff;
  font-size: 1.875vw;
  margin-bottom: 100px;
  @media ${media.laptopS()} {
    font-size: 1.814vw;
    margin-bottom: 44px;
  }
  @media ${media.tabletS()} {
    font-size: 1.5625vw;
    margin-bottom: 20px;
  }
`;

const AnimationContainer = styled.div`
  text-transform: uppercase;
  position: relative;
  z-index: 100;
`;

const Line = styled.div`
  display: flex;
  font-size: 4em;
  line-height: 0.85em;
  white-space: nowrap;
  font-weight: 700;
`;

const GreenLine = styled(Line)`
  color: var(--green-main);
  font-size: 8em;
`;

const FirstLine = styled(Line)`
  font-size: 2.7em;
  @media ${media.laptopS()} {
    font-size: 4em;
  }
`;

const HollowWord = styled.span`
  color: transparent;
  -webkit-text-stroke: 1px white;
  margin-right: 10px;
`;

const FilledWord = styled.span`
  margin-right: 10px;
`;

const OpacityFilledWord = styled(FilledWord)`
  opacity: 0.4;
  margin-right: 20px;
`;

export default TechnologiesSection;
