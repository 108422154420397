import { media } from 'src/assets/style/media';
import styled from 'styled-components/macro';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { BackButton } from 'src/components';

const CsHeader = () => {
  return (
    <HeaderWrapper>
      <Logo />
      <StyledBackButton />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  height: 120px;
  background-color: var(--cs-background);
  padding: 0 140px;
  display: flex;
  align-items: flex-end;
  position: relative;
  @media ${media.laptopS()} {
    svg {
      width: 160px;
      height: 42px;
    }
  }

  @media ${media.tabletS()} {
    svg {
      width: 88px;
      height: 23px;
    }
  }
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  top: calc(100% + 50px);
`;

export default CsHeader;
