import { useTranslation } from 'react-i18next';
import { SharedContainer } from 'src/components';
import { SaiktLogo } from 'src/components/svg/cases/saikt/images';
import styled from 'styled-components/macro';
import saiktPreviewBackground from 'src/assets/images/cases/saikt/preview.jpg';
import { media } from 'src/assets/style/media';
import { PreviewLayers } from './components';

const SaiktPreviewSection = () => {
  const { t } = useTranslation(['saikt']);

  return (
    <SectionWrapper>
      <SharedContainer>
        <PreviewMain>
          <h3>{t('saikt:saikt.preview.subtitle')}</h3>
          <h2>{t('saikt:saikt.preview.title')}</h2>
          <SaiktLogo />
          <PreviewLayers />
        </PreviewMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  padding: 100px 0;
  background-color: var(--saikt-main-color);
  z-index: 2;
  color: var(--saikt-text-color);

  &:before,
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    content: '';
    background: url(${saiktPreviewBackground}) center left / cover no-repeat;
    width: calc(50% + 123px);

    @media ${media.tabletL()} {
      width: 100%;
      background-position: center left;
    }
  }

  &:after {
    left: 0;

    @media ${media.tabletL()} {
      content: '';
      background-color: var(--saikt-main-color);
      opacity: 0.7;
      z-index: 0;
    }
  }

  > div {
    height: 100vh;
    max-height: 727px;
    min-height: 500px;
    display: flex;
    align-items: center;
  }

  h2 {
    margin: 16px 0 66px;
  }

  h3 {
    font-size: 48px;
    font-weight: 500;
  }
`;

const PreviewMain = styled.div`
  position: relative;
  z-index: 1;
  width: calc(100% - calc(3vw - 1px));
  margin: 0 calc(3vw - 1px) 120px;

  > svg {
    width: 189px;
    height: 71px;
    margin-left: 5px;
  }
`;

export default SaiktPreviewSection;
