import { sizes } from './sizes';

export const media = {
  desktopL: (mediaType = 'min') =>
    `screen and (${mediaType}-width:${sizes.desktopL})`,
  desktopM: (mediaType = 'min') =>
    `screen and (${mediaType}-width:${sizes.desktopM})`,
  desktopMin: (mediaType = 'min') =>
    `screen and (${mediaType}-width:${sizes.desktop})`,
  desktop: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.desktop})`,
  desktopS: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.desktopS})`,
  laptopL: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.laptopL})`,
  laptopM: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.laptopM})`,
  laptopS: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.laptopS})`,
  tabletL: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.tabletL})`,
  tabletS: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.tabletS})`,
  mobile: (mediaType = 'max') =>
    `screen and (${mediaType}-width:${sizes.mobile})`,
};
