import {
  HomeMainInformation,
  OurTeamSection,
  TechnologiesSection,
  AboutInformation,
  TechnologiesContent,
} from 'src/screen-components/home';
import { ContactUsSection, ContactUsModal } from 'src/components';
import { BaseLayout } from 'src/layouts';
import { useToggle } from 'src/hooks/use-toggle';
import { useEffect } from 'react';
import { ButtonLink } from 'src/components/button-link';

const Home = () => {
  const { isOpen, openToggle, closeToggle } = useToggle();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <BaseLayout>
      <HomeMainInformation setModalOpen={openToggle} />
      <TechnologiesContent />
      <AboutInformation />
      <TechnologiesSection />
      <OurTeamSection />
      <ContactUsSection setModalOpen={openToggle} />
      {isOpen && <ContactUsModal closeModal={closeToggle} />}
      <ButtonLink />
    </BaseLayout>
  );
};

export default Home;
