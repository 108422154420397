import React from 'react';

function MDecorLess({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='245'
      height='235'
      fill='none'
      viewBox='0 0 245 235'
    >
      <path
        fill='#3F3D56'
        d='M187.768 194.775a102.582 102.582 0 01-7.624 3.72l-.553-1.263a101.615 101.615 0 007.522-3.671l.655 1.214zm-15.53 6.796a103.02 103.02 0 01-8.132 2.409l-.337-1.339a101.105 101.105 0 008.023-2.376l.446 1.306zm-16.436 4.135a103.94 103.94 0 01-8.416 1.037l-.113-1.375c2.774-.227 5.568-.571 8.304-1.023l.225 1.361zm-16.89.006v1.379c-2.82.002-5.67-.113-8.471-.34l.113-1.375c2.763.224 5.575.337 8.358.336zm-16.66-1.356l-.225 1.361a102.886 102.886 0 01-8.301-1.726l.337-1.338a101.84 101.84 0 008.189 1.703zm-16.209-4.079l-.447 1.305a103.022 103.022 0 01-7.907-3.077l.554-1.264a101.567 101.567 0 007.8 3.036zm-8.75-188.814a101.586 101.586 0 00-7.48 3.75l-.668-1.207a102.89 102.89 0 017.582-3.8l.567 1.257zM90.722 193.57l-.655 1.214a103.568 103.568 0 01-7.29-4.336l.753-1.156c2.33 1.519 4.75 2.958 7.192 4.278zM82.668 19.567a102.228 102.228 0 00-6.764 4.927l-.857-1.08a103.737 103.737 0 016.855-4.995l.766 1.148zm-5.954 164.871l-.846 1.09a103.531 103.531 0 01-6.481-5.469l.932-1.017c2.048 1.877 4.2 3.692 6.395 5.396zM69.566 29.96c-2.03 1.9-4.004 3.908-5.87 5.967L62.674 35a104.16 104.16 0 015.95-6.047l.942 1.007zm-5.178 143.181l-1.012.937a103.615 103.615 0 01-5.501-6.452l1.085-.852a102.371 102.371 0 005.428 6.367zM58.333 42.354a102.55 102.55 0 00-4.818 6.845l-1.16-.747a103.971 103.971 0 014.884-6.938l1.094.84zm-4.259 117.632l-1.151.759a103.116 103.116 0 01-4.37-7.264l1.21-.662a101.84 101.84 0 004.311 7.167zm-4.799-103.57a101.72 101.72 0 00-3.63 7.544l-1.268-.547a103.499 103.499 0 013.68-7.645l1.218.649zm-3.215 88.906l-1.26.56A102.667 102.667 0 0141.688 138l1.303-.454a101.29 101.29 0 003.07 7.776zm-3.41-73.545a101.234 101.234 0 00-2.337 8.039l-1.34-.33a102.7 102.7 0 012.369-8.148l1.307.44zm-2.075 57.76l-1.336.343a103.13 103.13 0 01-1.769-8.293l1.36-.232a101.809 101.809 0 001.745 8.182zM38.652 88.02a101.739 101.739 0 00-.978 8.311l-1.376-.104c.215-2.814.548-5.648.992-8.424l1.362.217zm-.886 25.036l-1.375.12c-.243-2.801-.374-5.66-.39-8.472l1.38-.009c.015 2.777.145 5.596.385 8.361z'
      />
    </svg>
  );
}

export default MDecorLess;
