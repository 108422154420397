import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/style/media';
import { SharedContainer } from 'src/components';
import styled from 'styled-components/macro';
import { ObjectivesLayers } from './components';

const SaiktObjectivesSection = () => {
  const { t } = useTranslation(['saikt']);
  return (
    <SectionWrapper>
      <SharedContainer>
        <ObjectivesMain>
          <ObjectivesItem>
            <h2>{t('saikt:saikt.objectives.1.title')}</h2>
            <p>{t('saikt:saikt.objectives.1.text.1')}</p>
            <p>{t('saikt:saikt.objectives.1.text.2')}</p>
            <p>{t('saikt:saikt.objectives.1.text.3')}</p>
          </ObjectivesItem>
          <ObjectivesItem>
            <h2>{t('saikt:saikt.objectives.2.title')}</h2>
            <ul>
              <li>{t('saikt:saikt.objectives.2.list.1')}</li>
              <li>{t('saikt:saikt.objectives.2.list.2')}</li>
              <li>{t('saikt:saikt.objectives.2.list.3')}</li>
              <li>{t('saikt:saikt.objectives.2.list.4')}</li>
              <li>{t('saikt:saikt.objectives.2.list.5')}</li>
            </ul>
          </ObjectivesItem>
          <ObjectivesItem>
            <h2>{t('saikt:saikt.objectives.3.title')}</h2>
            <ul>
              <li>{t('saikt:saikt.objectives.3.list.1')}</li>
              <li>{t('saikt:saikt.objectives.3.list.2')}</li>
              <li>{t('saikt:saikt.objectives.3.list.3')}</li>
            </ul>
          </ObjectivesItem>
          <ObjectivesLayers />
        </ObjectivesMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  padding: 90px 0 66px;
  z-index: 1;
  color: var(--saikt-text-color);
`;

const ObjectivesMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media ${media.tabletL('min')} {
    margin: 0 min(130px, calc(7vw - 8px));
  }

  h2 {
    line-height: 1.25;
    margin-bottom: 29px;
  }

  p,
  ul li {
    font-size: 18px;
    line-height: 22.94px;
    color: black;

    @media ${media.laptopM('min')} {
      max-width: 80%;
    }
  }

  p {
    margin-bottom: 22px;
  }

  ul li {
    padding-left: 33px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 3px;
      width: 14px;
      height: 14px;
      background-color: var(--saikt-main-color);
    }
  }
`;

const ObjectivesItem = styled.div`
  position: relative;
  z-index: 1;

  &:first-child {
    width: calc(50% + 70px);

    @media ${media.laptopL()} {
      width: 50%;
      margin-bottom: 40px;
    }

    @media ${media.laptopM()} {
      width: 100%;
    }
  }

  &:nth-child(2) {
    width: calc(50% - 70px);
    margin: 310px 0 0 auto;

    @media ${media.laptopL()} {
      width: calc(50% - 15px);
      margin: 187px 0 30px 15px;
    }

    @media ${media.laptopM()} {
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &:nth-child(3) {
    width: calc(50% + 180px);
    margin: -71px 0 0 -80px;

    @media ${media.laptopL()} {
      width: calc(50% + 180px);
      margin: 15px 0 30px 71px;
    }

    @media ${media.laptopM()} {
      width: 100%;
      margin: 0 0 40px;
    }
  }
`;

export default SaiktObjectivesSection;
