import React from 'react';

function MPopover() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='873'
      height='572'
      fill='none'
      viewBox='0 0 873 572'
    >
      <path
        fill='#2F6984'
        d='M75.611 15.231h703.107l85.404 28.808v138.576l-12.232 4.347v166.292l12.232 9.238v152.706l-25.567 26.057-60.058 14.157H638.916l-10.88 11.954H250.227l-9.247-13.055H97.37L8.702 533.64V359.024l11.968-7.072V189.303l-14.144-8.159V55.485l69.085-40.254z'
      />
      <path
        fill='#41E8FF'
        d='M17.61 87.725H0V31.376l17.61-16.648h26.893L61.472 0h71.396v18.57h-30.735L90.607 31.376H77.8L65.634 43.863H31.376L17.61 58.59v29.135zm-.321 395.724H0v56.349l17.61 16.648h26.893l16.969 14.728H132v-18.495h-30.277l-11.116-12.881H77.8l-12.166-12.487H31.376L17.29 512.584v-29.135zM855.391 87.725H873V31.376l-17.609-16.648h-26.894L811.528 0h-71.396v18.57h30.735l11.526 12.806H795.2l12.166 12.487h34.258l13.767 14.727v29.135zm0 395.724H873v56.349l-17.609 16.648h-26.894l-16.969 14.728H740v-18.57h30.547l11.846-12.806H795.2l12.166-12.487h34.258l13.767-14.727v-29.135z'
      />
      <path
        fill='#41E8FF'
        d='M129.467 14.087h620.719v4.483H129.467v-4.483zm-9.711 538.517h121.97l8.645 12.487h372.922l12.806-12.487h111.738v4.483H639.301l-12.166 12.166H248.13l-8.325-12.166H119.756v-4.483zM6.403 87.725v95.813l12.487 3.846v163.812l-12.487 5.698v126.555h4.483V359.865l12.166-6.083V184.735l-12.166-4.162V87.725H6.403zm862.115-20.478v117.572l-12.487 3.846v163.812l12.487 5.698V484.73h-4.483V361.146l-12.166-6.083V186.016l12.166-4.162V67.247h4.483zM275 47h5.129V32H275zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.128 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zM442 47h5.129V32H442zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.128 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129zm15.129 0h5.129V32h-5.129z'
      />
    </svg>
  );
}

export default MPopover;
