import React from 'react';

function MCircleIn({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='162'
      height='162'
      fill='none'
      viewBox='0 0 162 162'
    >
      <path
        fill='#d4f3e5'
        d='M81 162c44.735 0 81-36.265 81-81S125.735 0 81 0C36.264 0 0 36.265 0 81s36.264 81 81 81z'
      />
      <path
        fill='#14BC6F'
        d='M81.5 123a42.86 42.86 0 01-23.612-7.078 42.114 42.114 0 01-15.653-18.85 41.541 41.541 0 01-2.419-24.266 41.85 41.85 0 0111.631-21.505 42.66 42.66 0 0121.761-11.494 42.97 42.97 0 0124.556 2.39 42.398 42.398 0 0119.073 15.47A41.65 41.65 0 01124 81c-.013 11.135-4.495 21.811-12.462 29.685-7.968 7.874-18.77 12.303-30.038 12.315z'
      />
    </svg>
  );
}

export default MCircleIn;
