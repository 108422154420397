import React from 'react';

function MSquareSmall({ className = '' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='110'
      height='110'
      fill='none'
      viewBox='0 0 110 110'
      className={className}
    >
      <path
        fill='#14BC6F'
        d='M110 110H0V0h110v110zM1.527 108.472h106.945V1.528H1.527v106.944z'
      />
    </svg>
  );
}

export default MSquareSmall;
