import styled from 'styled-components/macro';
import { forwardRef } from 'react';
import { ReactComponent as ArrowRightDouble } from 'src/assets/images/ArrowRightDouble.svg';
import { animated } from 'react-spring';
import { Link } from 'react-router-dom';

const PortfolioItem = forwardRef(
  (
    { variant = 'primary', title, text, image, rootCSS, animateStyle, href },
    ref,
  ) => {
    return (
      <ItemWrapper $CSS={rootCSS} ref={ref} style={animateStyle}>
        <ImageContainer>
          <StyledImage src={image} alt='project-preview' />
          <ArrowLink to={href}>
            <ArrowRightDouble style={{ width: '49%', height: '44%' }} />
          </ArrowLink>
        </ImageContainer>
        <ItemTitle $variant={variant}>{title}</ItemTitle>
        <ItemText>{text}</ItemText>
      </ItemWrapper>
    );
  },
);
const ItemWrapper = styled(animated.div)`
  text-transform: uppercase;
  :hover {
    a {
      visibility: visible;
      transform: scale(1) rotate(0);
    }
    img {
      clip-path: polygon(
        0% 15%,
        0 0,
        15% 0%,
        85% 0%,
        91% 6%,
        100% 15%,
        100% 85%,
        100% 100%,
        85% 100%,
        15% 100%,
        7% 92%,
        0% 85%
      );
      opacity: 0.4;
      transform: scale(1.025);
    }
  }
  ${({ $CSS }) => $CSS};
`;

const ImageContainer = styled.div`
  position: relative;
`;

const StyledImage = styled.img`
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  clip-path: polygon(
    0% 15%,
    0 0,
    15% 0%,
    85% 0%,
    100% 0,
    100% 15%,
    100% 85%,
    100% 100%,
    85% 100%,
    15% 100%,
    0 100%,
    0% 85%
  );
`;

const ArrowLink = styled(Link)`
  visibility: hidden;
  width: 4em;
  height: 4em;
  background-color: var(--green-main);
  position: absolute;
  border-radius: 50%;
  right: 0.66em;
  bottom: 0.66em;
  transition: all 0.3s ease-in-out;
  transform: scale(0) rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemTitle = styled.p`
  font-weight: 700;
  font-size: ${({ $variant }) => ($variant === 'primary' ? '4em' : '2.6em')};
  color: var(--green-main);
`;

const ItemText = styled.p`
  color: var(--white);
  width: 80%;
  text-transform: none;
`;

export default PortfolioItem;
