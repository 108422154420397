import { media } from 'src/assets/style/media';
import styled from 'styled-components/macro';
import arrow from 'src/assets/images/cases/ark/arrow.svg';

export const SectionWrapper = styled.section`
  padding: 96px 0 300px;
  position: relative;
  background-color: var(--ark-main-color);

  @media ${media.laptopL()} {
    padding: 158px 0 400px;
  }

  @media ${media.laptopS()} {
    padding: 78px 0;
    margin: 0;
  }
`;

export const ProjectShape = styled.div`
  position: absolute;
  bottom: calc(100% - 40px);
`;

export const ProjectMain = styled.div`
  position: relative;

  > h2 {
    margin-bottom: 28px;
    position: relative;
    z-index: 1;
  }

  p,
  ul li {
    color: black;
    font-size: 18px;
    line-height: 22.94px;
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 22px;
    }
  }

  ul {
    margin: -7px 0 14px auto;

    @media ${media.laptopL('min')} {
      max-width: 778px;
    }

    + p + p {
      strong {
        display: block;
      }
    }

    li {
      padding-left: 25px;
      position: relative;

      &:before {
        content: '-';
        font-weight: 700;
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const ProjectList = styled.p`
  margin-left: 22px;

  strong {
    margin-left: -22px;
  }
`;

export const ProjectTitle = styled.div`
  display: inline-block;
  position: relative;

  h2 {
    position: relative;
    z-index: 1;
    margin-bottom: 28px;
  }
`;

export const ProjectTitleShape = styled.div`
  position: absolute;
  color: transparent;
  font-size: min(25vw, 160px);
  line-height: min(25vw, 160px);
  font-weight: 700;
  -webkit-text-stroke: 4.4px #e5f7fca1;
  white-space: nowrap;
  @media ${media.tabletL()} {
    -webkit-text-stroke: 2.4px #e5f7fca1;
    font-size: 15vw;
  }
`;

export const ProjectData = styled.div`
  @media ${media.laptopL('min')} {
    max-width: 800px;
    margin-right: auto;

    + img {
      margin-right: -97px;
      margin-bottom: 16px;
    }
  }
`;

export const ProjectDesc = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 154px;

  @media ${media.laptopL()} {
    flex-wrap: wrap;
  }

  @media ${media.tabletL()} {
    margin-bottom: 104px;
  }

  img {
    &:last-child {
      position: absolute;
      bottom: calc(10% + 18px);
      right: calc(6% - -5px);
      width: auto;
      @media ${media.tabletL()} {
        display: none;
      }
    }
  }

  p {
    strong {
      margin-right: 4px;

      span {
        margin-right: 15px;
      }
    }
  }
`;

export const DescShape = styled(ProjectTitleShape)`
  bottom: calc(100% - 60px);
  left: calc(-10% + -16px);
`;

export const ProjectInterface = styled.div`
  max-width: 1095px;
  margin: 0 0 702px auto;
  position: relative;
  z-index: 1;

  @media ${media.laptopL()} {
    margin: 0 0 160px auto;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 90px;
  }

  strong {
    display: block;
    margin-bottom: 11px;

    span {
      margin-right: 15px;
    }

    + span {
      max-width: 1072px;
      display: block;
      margin: 0 0 -7px;
      width: 80%;
    }
  }
`;

export const InterfaceShape = styled(ProjectTitleShape)`
  bottom: calc(100% - 58px);
  left: calc(-10% + -34px);
`;

export const ProjectDesktop = styled.div`
  text-align: center;
  margin-bottom: 160px;

  @media screen and (max-width: 600px) {
    margin-bottom: 90px;
  }
`;

export const DesktopTitle = styled(ProjectTitle)`
  margin-bottom: 23px;
`;

export const DesktopTitleShape = styled(ProjectTitleShape)`
  bottom: calc(100% - 51px);
  left: calc(-10% + -25px);
`;

export const ProjectMobile = styled.div`
  text-align: center;
  margin-bottom: 72px;

  .ark-project__title {
    margin-bottom: -28px;
  }

  .ark-project__title-shape {
    bottom: calc(100% - 60px);
    left: calc(-10% + -22px);
  }
`;

export const MobileTitle = styled(ProjectTitle)`
  margin-bottom: -28px;
`;

export const MobileTitleShape = styled(ProjectTitleShape)`
  bottom: calc(100% - 60px);
  left: calc(-10% + -22px);
`;

export const ProjectRequirements = styled.div`
  max-width: 1575px;
  margin-left: auto;
`;

export const RequirementsTitle = styled(ProjectTitle)`
  margin-bottom: 0;
`;

export const RequirementsTitleShape = styled(ProjectTitleShape)`
  bottom: calc(100% - 56px);
  left: calc(-10% + 10px);
`;

export const ProjectSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 590px;

  > div {
    margin-right: auto;

    label {
      color: black;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      display: block;
      margin-bottom: 20px;
    }
  }
`;

export const ProjectSlider = styled.div`
  max-width: 1600px;
  margin: auto;

  .swiper-slide {
    &:not(.swiper-slide-active) {
      opacity: 0 !important;
    }
  }

  .ark-project__button-next,
  .ark-project__button-prev {
    position: absolute;
    top: 50%;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    z-index: 1;
    background: url(${arrow}) center / auto no-repeat var(--ark-main-color);
    z-index: 1;
    outline: none;

    @media ${media.laptopS()} {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.5);
    }
  }

  .ark-project__button-next {
    transform: rotate(180deg);
    right: 0;
    background-position: 16px center;
  }

  .ark-project__button-prev {
    background-position: 18px center;
  }

  .ark-project__pagination {
    position: absolute;
    bottom: 8%;
    right: 7%;
    display: flex;
    z-index: 2;
    left: unset;
    width: auto;

    .swiper-pagination-bullet {
      border-radius: 50%;
      background-color: var(--white);
      border: 3px solid var(--ark-main-color);
      width: 26px;
      height: 26px;
      transition: $transition;
      outline: none;

      &.swiper-pagination-bullet-active {
        box-shadow: 0 0 0 2px var(--white);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

export const StyledMobileImage = styled.img`
  display: block;
  @media ${media.tabletS('min')} {
    display: none;
  }
`;

export const StyledDesktopImage = styled.img`
  display: block;
  @media ${media.tabletS()} {
    display: none;
  }
`;

export const StyledShip = styled.img`
  display: block !important;
  position: static !important;
  max-width: 10vw !important;
  align-self: flex-end;
`;

export const MobileParagrapgh = styled.div`
  display: flex;
  > p {
    margin-bottom: 22px;
  }
  @media ${media.tabletL('min')} {
    display: none;
  }
`;

export const DesktopParagraph = styled.p`
  @media ${media.tabletL()} {
    display: none;
  }
`;
