import React from 'react';

function MDecorBig({ className = '' }) {
  return (
    <svg
      viewBox='0 0 245 71'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='245'
      height='71'
      fill='none'
    >
      <path
        fill='#E3E3E3'
        d='M245 71h-6.622V0H245v71zm-13.243 0h-6.622V0h6.622v71zm-13.244 0h-6.622V0h6.622v71zm-13.243 0h-6.621V0h6.621v71zm-13.243 0h-6.622V0h6.622v71zm-13.244 0h-6.621V0h6.621v71zm-13.243 0h-6.621V0h6.621v71zm-13.243 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.244 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.244 0h-6.621V0h6.621v71zm-13.243 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.243 0h-6.622V0h6.622v71zm-13.244 0h-6.621V0h6.621v71zM6.621 71H0V0h6.62v71z'
      />
    </svg>
  );
}

export default MDecorBig;
