import { media } from 'src/assets/style/media';
import styled from 'styled-components';

const ContactUsButton = ({ onClick, className }) => {
  return (
    <ContactUsButtonContainer className={className}>
      <ContactUsButtonTag onClick={onClick}>
        <ButtonText>contact us</ButtonText>
      </ContactUsButtonTag>
    </ContactUsButtonContainer>
  );
};

const ContactUsButtonContainer = styled.div`
  font-size: 14px;
  :hover {
    button {
      transform: scale(0.6);
      border-width: 8px;
      font-size: 23.3px;
      border-color: var(--green-hover);
    }
    p:after {
      height: 16px;
      background-color: var(--green-hover);
    }
  }
  @media ${media.laptopM()} {
    font-size: 24px;
    :hover {
      button {
        transform: scale(0.8);
        font-size: 30px;
      }
    }
  }
  @media ${media.laptopS()} {
    font-size: 14px;
    :hover {
      button {
        transform: scale(0.9);
        font-size: 15.6px;
      }
    }
  }
  @media ${media.mobile()} {
    font-size: 10px;
    :hover {
      button {
        font-size: 9px;
      }
    }
  }
`;

const ContactUsButtonTag = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--green-main);
  color: inherit;
  min-width: 15.625vw;
  min-height: 15.625vw;
  max-width: 15.625vw;
  max-height: 15.625vw;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  @media ${media.mobile()} {
    max-width: 20.625vw;
    max-height: 20.625vw;
  }
`;

const ButtonText = styled.p`
  text-transform: uppercase;
  position: relative;
  :after {
    content: '';
    width: 100%;
    position: absolute;
    top: 100%;
    background-color: var(--green-main);
    height: 2px;
    left: 0;
    transition: all 0.4s ease-in-out;
  }

  @media ${media.desktopMin()} {
    font-size: 2em;
  }

  @media ${media.desktopM()} {
    font-size: 2vh;
  }

  @media ${media.desktopL()} {
    font-size: 2.5vh;
  }

  @media ${media.desktop()} {
    font-size: 1.7vh;
  }

  @media ${media.desktopS()} {
    font-size: 1.4vh;
  }

  @media ${media.laptopM()} {
    font-size: 0.8em;
    max-width: 5em;
    :after {
      display: none;
    }
  }
`;

export default ContactUsButton;
