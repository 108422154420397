import { useTranslation } from 'react-i18next';
import {
  SharedContainer,
  ParallaxControlScroll,
  ParallaxControl,
  Slider,
} from 'src/components';
import { Front, Back } from 'src/components/svg/cases/ark/images';
import mobile from 'src/assets/images/cases/ark/mobile.webp';
import mobileCut from 'src/assets/images/cases/ark/mobile-cut.webp';
import ship from 'src/assets/images/cases/ark/ship-7.svg';
import shipTwo from 'src/assets/images/cases/ark/ship-8.svg';
import { MShapeLine } from 'src/components/svg/cases/ark/models';
import { ProjectLayers, SliderContent } from './components';
import {
  DescShape,
  DesktopTitle,
  DesktopTitleShape,
  InterfaceShape,
  MobileTitle,
  MobileTitleShape,
  ProjectData,
  ProjectDesc,
  ProjectDesktop,
  ProjectInterface,
  ProjectList,
  ProjectMain,
  ProjectMobile,
  ProjectRequirements,
  ProjectShape,
  ProjectSkills,
  ProjectSlider,
  ProjectTitle,
  RequirementsTitle,
  RequirementsTitleShape,
  SectionWrapper,
  StyledMobileImage,
  StyledDesktopImage,
  StyledShip,
  MobileParagrapgh,
  DesktopParagraph,
} from './components/styles';

const params = {
  slidesPerView: 1,
  effect: 'fade',
  navigation: {
    nextEl: '.ark-project__button-next',
    prevEl: '.ark-project__button-prev',
  },
  pagination: {
    el: '.ark-project__pagination',
    clickable: true,
  },
};

const ArkProject = () => {
  const { t } = useTranslation(['ark']);

  return (
    <SectionWrapper>
      <ProjectShape>
        <MShapeLine />
      </ProjectShape>
      <SharedContainer>
        <ProjectMain>
          <ProjectDesc>
            <ProjectData>
              <ParallaxControlScroll y={[20, -20]}>
                <ProjectTitle>
                  <h2>{t('ark:ark.project.title.1')}</h2>
                  <DescShape>
                    <ParallaxControl speed='.2' limitY={1}>
                      {t('ark:ark.project.title.1')}
                    </ParallaxControl>
                  </DescShape>
                </ProjectTitle>
                <p>{t('ark:ark.project.decs.text.1')}</p>
                <p>{t('ark:ark.project.decs.text.2')}</p>
                <ProjectList>
                  <strong>
                    <span>1</span>
                    {t('ark:ark.project.stage.1.title')}
                  </strong>
                  {t('ark:ark.project.stage.1.text')}
                </ProjectList>
                <ul>
                  <li>{t('ark:ark.project.list.1')}</li>
                  <li>{t('ark:ark.project.list.2')}</li>
                  <li>{t('ark:ark.project.list.3')}</li>
                  <li>{t('ark:ark.project.list.4')}</li>
                  <li>{t('ark:ark.project.list.5')}</li>
                </ul>
                <DesktopParagraph>
                  {t('ark:ark.project.decs.text.3')}
                </DesktopParagraph>
                <MobileParagrapgh>
                  <p>{t('ark:ark.project.decs.text.3')}</p>
                  <StyledShip src={shipTwo} alt='beacon' />
                </MobileParagrapgh>

                <ProjectList>
                  <strong>
                    <span>2</span>
                    {t('ark:ark.project.stage.2.title')}
                  </strong>
                  {t('ark:ark.project.stage.2.text')}
                </ProjectList>
                <ul>
                  <li>{t('ark:ark.project.list.6')}</li>
                </ul>
                <p>{t('ark:ark.project.decs.text.4')}</p>
              </ParallaxControlScroll>
            </ProjectData>
            <img src={ship} alt='ship' />
            <img src={shipTwo} alt='ship' />
          </ProjectDesc>
          <ProjectInterface>
            <ParallaxControlScroll x={[20, -5]}>
              <ProjectTitle>
                <h2>{t('ark:ark.project.title.2')}</h2>
                <InterfaceShape>
                  <ParallaxControl speed='-.2' limitY={1}>
                    {t('ark:ark.project.title.2')}
                  </ParallaxControl>
                </InterfaceShape>
              </ProjectTitle>
              <p>{t('ark:ark.project.desc.2.text')}</p>
              <p>
                <strong>
                  <span>1</span>
                  {t('ark:ark.project.variant.1.title')}
                </strong>
                <span>{t('ark:ark.project.variant.1.text')}</span>
              </p>
              <p>
                <strong>
                  <span>2</span>
                  {t('ark:ark.project.variant.2.title')}
                </strong>
                <span>{t('ark:ark.project.variant.2.text')}</span>
              </p>
              <p>
                <strong>
                  <span>3</span>
                  {t('ark:ark.project.variant.3.title')}
                </strong>
                <span>{t('ark:ark.project.variant.3.text')}</span>
              </p>
            </ParallaxControlScroll>
          </ProjectInterface>
          <ProjectDesktop>
            <DesktopTitle>
              <h2>{t('ark:ark.project.title.3.name.0')}</h2>
              <DesktopTitleShape>
                <ParallaxControl speed='.2' limitY={1}>
                  {t('ark:ark.project.title.3.name.0')}
                </ParallaxControl>
              </DesktopTitleShape>
            </DesktopTitle>
            <ProjectSlider>
              <Slider {...params} rebuildOnUpdate={true}>
                <SliderContent />
              </Slider>
            </ProjectSlider>
          </ProjectDesktop>
          <ProjectMobile>
            <MobileTitle>
              <h2>{t('ark:ark.project.title.4')}</h2>
              <MobileTitleShape>
                <ParallaxControl speed='-.2' limitY={1}>
                  {t('ark:ark.project.title.4')}
                </ParallaxControl>
              </MobileTitleShape>
            </MobileTitle>
            <StyledDesktopImage src={mobile} alt='Mobile design' />
            <StyledMobileImage src={mobileCut} alt='Mobile design' />
          </ProjectMobile>
          <ProjectRequirements>
            <RequirementsTitle>
              <h2>{t('ark:ark.project.title.5')}</h2>
              <RequirementsTitleShape>
                <ParallaxControl speed='.2' limitY={1}>
                  {t('ark:ark.project.title.5')}
                </ParallaxControl>
              </RequirementsTitleShape>
            </RequirementsTitle>
            <ProjectSkills>
              <div>
                <label>Frontend</label>
                <Front />
              </div>
              <div>
                <label>Backend</label>
                <Back />
              </div>
            </ProjectSkills>
          </ProjectRequirements>
          <ProjectLayers />
        </ProjectMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

export default ArkProject;
