import { useState } from 'react';

export const useToggle = () => {
  const [isOpen, setOpen] = useState(false);

  const openToggle = () => {
    setOpen(true);
  };

  const closeToggle = () => {
    setOpen(false);
  };
  return { isOpen, openToggle, closeToggle };
};
