import React from 'react';

function MSquares2() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='248'
      height='248'
      fill='none'
      viewBox='0 0 248 248'
    >
      <path
        fill='#DDE9FF'
        d='M10.962 147.091C-6.126 125.998-2.88 95.046 18.214 77.957l82.695-66.995c21.093-17.088 52.045-13.842 69.134 7.252l66.995 82.695c17.088 21.093 13.842 52.045-7.252 69.134l-82.695 66.995c-21.093 17.088-52.045 13.842-69.134-7.252l-66.995-82.695z'
      />
    </svg>
  );
}

export default MSquares2;
