import React from 'react';

function MCircle({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='319'
      height='319'
      fill='none'
      viewBox='0 0 319 319'
    >
      <path
        fill='#D2F2E4'
        d='M159.5 319c88.089 0 159.5-71.411 159.5-159.5C319 71.41 247.589 0 159.5 0 71.41 0 0 71.41 0 159.5 0 247.589 71.41 319 159.5 319z'
      />
    </svg>
  );
}

export default MCircle;
