import { ReactComponent as Smiley } from 'src/assets/images/smail.svg';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Rotate from 'react-reveal/Rotate';
import styled from 'styled-components/macro';
import homeBackground from 'src/assets/images/home-hero-background.jpg';

import { media } from 'src/assets/style/media';
import { ContactUsButton } from 'src/components';

const HomeMainInformation = ({ setModalOpen }) => {
  return (
    <SectionContainer>
      <MainInfoContainer>
        <AnimationContainer>
          <Industry>
            <IndustryTitle>
              <Fade left big cascade collapse>
                <p>Industry</p>
              </Fade>
            </IndustryTitle>
            <Smiley />
          </Industry>
          <GridContainer>
            <LeadingContainer>
              <LeadingText>
                <Zoom bottom>
                  Finding the best team for your project can take a lot of time.
                  Yet, there is a shortcut that can save your precious time and
                  make your idea into life
                  <GreenString>unacceptably faster.</GreenString>
                </Zoom>
              </LeadingText>
            </LeadingContainer>
            <LeadingTitle>
              <div>
                <Flip top>leading</Flip>
              </div>
            </LeadingTitle>
            <Rotate>
              <ContactUsButton onClick={setModalOpen} />
            </Rotate>
            <LeadingSubtitle>
              <Fade right big cascade collapse>
                web agency
              </Fade>
            </LeadingSubtitle>
          </GridContainer>
        </AnimationContainer>
      </MainInfoContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  font-size: 3vw;
  width: 100%;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  position: relative;
`;

const MainInfoContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  margin: -50px -140px;
  background-image: url('${homeBackground}');
  background-size: cover;
  margin-bottom: 30px;

  @media ${media.laptopM()} {
    margin: -50px -70px 0 -70px;
  }

  @media ${media.laptopS()} {
    margin: -20px -20px 0 -20px;
  }

  @media ${media.mobile()} {
    height: fit-content;
  }

  :before {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(72, 75, 76, 0) 23.99%,
      rgba(28, 28, 28, 0.75) 100%
    );
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
`;

const AnimationContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 130px 40px 50px;
  z-index: 2;

  @media ${media.laptopM()} {
    padding: 130px 70px 50px;
  }

  @media ${media.laptopS()} {
    padding: 60px 20px 20px;
  }

  @media ${media.tabletL()} {
    height: 100%;
  }
`;

const Industry = styled.div`
  display: flex;
  align-items: baseline;
  svg {
    width: 3em;
    height: 3em;
    margin-left: 5px;
  }

  @media ${media.tabletL()} {
    svg {
      width: 4.3em;
      height: 4.3em;
      margin-left: 5px;
    }
  }

  @media ${media.mobile()} {
    svg {
      width: 3em;
      height: 3em;
      margin-left: 5px;
    }
  }
`;

const IndustryTitle = styled.div`
  font-size: 4em;
  line-height: 1em;
  height: 1em;

  @media ${media.tabletL()} {
    font-size: 6em;
  }

  @media ${media.mobile()} {
    font-size: 4em;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  @media ${media.laptopS()} {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 0.3em;
    & > .react-reveal {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 3;
      margin: 5px;

      @media ${media.tabletL()} {
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-start: 3;
        align-self: center;
      }

      @media ${media.tabletS()} {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        align-self: end;
        justify-self: center;
      }

      @media ${media.mobile()} {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 4;
        padding-top: 2rem;
        justify-self: center;
      }
    }
  }

  @media ${media.tabletL()} {
    grid-template-rows: auto auto auto;
  }
`;

const LeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${media.laptopS()} {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @media ${media.tabletS()} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @media ${media.mobile()} {
    grid-row-end: 4;
  }
`;

const LeadingText = styled.div`
  text-align: right;
  font-size: 0.5em;
  width: 70%;
  line-height: 1.1;
  margin-left: auto;
  @media ${media.laptopS()} {
    width: 100%;
    font-size: 0.6em;
  }

  @media ${media.tabletL()} {
    font-size: 1.2em;
    text-align: right;
  }

  @media ${media.tabletS()} {
    font-size: 0.9em;
  }

  @media ${media.mobile()} {
    font-size: 1.2em;
  }
`;

const GreenString = styled.p`
  color: var(--green-main);
`;

const LeadingTitle = styled.div`
  width: 100%;
  color: var(--green-main);
  font-size: 4.6em;
  padding-left: 0.06em;
  line-height: 0.67em;

  @media ${media.laptopS()} {
    grid-column-start: 3;
  }

  @media ${media.tabletL()} {
    grid-row-start: 1;
  }

  @media ${media.tabletS()} {
    font-size: 4em;
  }
`;

const LeadingSubtitle = styled.div`
  font-size: 2.3em;
  transform: translateX(-11vw);
  align-self: start;
  @media ${media.laptopL()} {
    transform: translateX(-9vw);
  }

  @media ${media.laptopS()} {
    grid-column-start: 3;
    grid-row-start: 2;
    transform: translateX(0);
    align-self: start;
    padding-left: 0.2em;
  }

  @media ${media.tabletL()} {
    padding-top: 4rem;
    grid-row-start: 1;
    justify-self: right;
    align-self: start;
    font-size: 2.6em;
  }

  @media ${media.tabletS()} {
    padding-top: 0;
    grid-row-start: 2;
    font-size: 2.2em;
    justify-self: left;
  }
`;

export default HomeMainInformation;
