import React from 'react';

function MLine({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='502'
      height='345'
      fill='none'
      viewBox='0 0 502 345'
    >
      <path
        fill='#E7F8F1'
        d='M502 0L0 345v-78.603L387.69 0H502zM312 0L0 214v-78.381L197.727 0H312zM106 0L0 73V0h106z'
      />
    </svg>
  );
}

export default MLine;
