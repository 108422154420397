import React from 'react';

function MSquaresThree3() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='311'
      height='244'
      fill='none'
      viewBox='0 0 311 244'
    >
      <path
        fill='#CFE0FF'
        d='M7.137 132.319c-11.125-13.732-9.012-33.883 4.72-45.008l98.964-80.174c13.732-11.125 33.883-9.012 45.008 4.72l80.174 98.964c11.125 13.732 9.011 33.883-4.721 45.008l-98.963 80.174c-13.732 11.125-33.883 9.011-45.008-4.721L7.137 132.319zm223.292 69.844c-3.797-4.687-3.076-11.566 1.612-15.364l33.783-27.369c4.687-3.797 11.566-3.076 15.364 1.612l27.369 33.783c3.798 4.687 3.076 11.566-1.612 15.364l-33.782 27.369c-4.688 3.798-11.567 3.076-15.365-1.612l-27.369-33.783z'
      />
    </svg>
  );
}

export default MSquaresThree3;
