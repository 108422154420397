import React from 'react';
import processOne from 'src/assets/images/cases/cs/popover/process-1.jpg';
import processTwo from 'src/assets/images/cases/cs/popover/process-2.jpg';
import processThree from 'src/assets/images/cases/cs/popover/process-3.jpg';
import processFour from 'src/assets/images/cases/cs/popover/process-4.jpg';
import processFive from 'src/assets/images/cases/cs/popover/process-5.jpg';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const state = {
  1: processOne,
  2: processTwo,
  3: processThree,
  4: processFour,
  5: processFive,
};

const SLIDE_CHANGING_TIME = 10;
const ANIMATION_DURATION = Object.keys(state).length * SLIDE_CHANGING_TIME;
const ANIMATION_DELAY = SLIDE_CHANGING_TIME;

function Popover() {
  return (
    <PopoverContainer>
      <PopoverPolygon>
        <ImageContainer1 />
      </PopoverPolygon>
      <ImageContainer2 />
    </PopoverContainer>
  );
}

const PopoverContainer = styled.div`
  position: absolute;
  top: 11%;
  left: 4%;
  right: 4%;
  bottom: 11%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const popovers = keyframes`
    0% {
      height: 0;
    }
    50% {
      height: 100%;
    } 
    
    100% {
      height: 0;
    }
`;

const image1 = keyframes`
  0% {
    background-image: url(${state[1]});
  }
  20% {
    background-image: url(${state[5]});
  }
  40% {
    background-image: url(${state[3]});
  }
  60% {
    background-image: url(${state[4]});
  }
  80% {
    background-image: url(${state[5]});
  }
  100% {
    background-image: url(${state[2]});
  }
`;

const image2 = keyframes`
  0% {
    background-image: url(${state[4]});
  }
  20% {
    background-image: url(${state[3]});
  }
  40% {
    background-image: url(${state[2]});
  }
  60% {
    background-image: url(${state[1]});
  }
  80% {
    background-image: url(${state[2]});
  }
  100% {
    background-image: url(${state[3]});
  }
`;

const PopoverPolygon = styled.div`
  position: absolute;
  animation: ${popovers} ${SLIDE_CHANGING_TIME}s linear infinite;
  height: 100%;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 9px;
    background: linear-gradient(to top, #41e8ff 15%, #fff 59%, #41e8ff 80%);
    box-shadow: 0 0 10px #41e8ff;
  }
`;

const ImageContainer1 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${state[1]});
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${image1} ${ANIMATION_DURATION}s steps(1, end) infinite;
  animation-delay: ${ANIMATION_DELAY}s;
`;

const ImageContainer2 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${state[2]});
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${image2} ${ANIMATION_DURATION}s steps(1, end) infinite;
  animation-delay: ${ANIMATION_DELAY / 2}s;
`;

export default Popover;
