import React from 'react';

function MSquaresThree7() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='583'
      height='469'
      fill='none'
      viewBox='0 0 583 469'
    >
      <path
        fill='#CFE0FF'
        d='M5.776 244.392c-9.004-11.114-7.293-27.422 3.82-36.426l215.012-174.19c11.114-9.004 27.422-7.293 36.426 3.82l174.19 215.012c9.004 11.114 7.293 27.422-3.82 36.426l-215.012 174.19c-11.114 9.004-27.422 7.293-36.426-3.82L5.776 244.392zM381.672 130.1c-4.165-5.141-3.374-12.685 1.767-16.85L482.9 32.672c5.142-4.165 12.686-3.374 16.85 1.767l80.578 99.461c4.165 5.142 3.374 12.686-1.767 16.85L479.1 231.328c-5.142 4.165-12.685 3.374-16.85-1.767L381.672 130.1zm-55.611-90.355a4.756 4.756 0 01.702-6.69l39.492-31.994a4.756 4.756 0 016.69.702l31.994 39.492a4.756 4.756 0 01-.702 6.69l-39.492 31.994a4.756 4.756 0 01-6.69-.702l-31.994-39.492z'
      />
    </svg>
  );
}

export default MSquaresThree7;
