import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const ParallaxControlScroll = ({ children, className, ...rest }) => {
  const isMobileTo = window.innerWidth < 768;

  const isMobile = isMobileTo;

  return !isMobile ? (
    <Parallax className={className} {...rest}>
      {children}
    </Parallax>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default ParallaxControlScroll;
