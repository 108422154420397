import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import { Home } from 'src/screens/home';
import { CyberSecurityPage } from 'src/screens/cases/cyber-security';
import { SaiktPage } from 'src/screens/cases/saikt';
import { routes } from './routes';
import { ArkPage } from 'src/screens/cases/ark';
import 'swiper/css/swiper.css';
import { FixpdqPage } from 'src/screens/cases/fixpdq';
import NotFound from '../screens/not-found/NotFound';
import { Terms } from 'src/screens/terms';
import { Privacy } from 'src/screens/privacy';

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={routes.home.short} component={Home} />
        <Route exact path={routes.home.full} component={Home} />
        <Route
          exact
          path={routes.cases.cyberSecurity}
          component={CyberSecurityPage}
        />
        <Route exact path={routes.cases.saikt} component={SaiktPage} />
        <Route exact path={routes.cases.ark} component={ArkPage} />
        <Route exact path={routes.cases.fixpdq} component={FixpdqPage} />
        <Route path={routes.terms} component={Terms} />
        <Route path={routes.privacy} component={Privacy} />
        <Route path={routes.page404} component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
