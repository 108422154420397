export const sizes = {
  desktopL: '3840px',
  desktopM: '2048px',
  desktop: '1920px',
  desktopS: '1641px',
  laptopL: '1440px',
  laptopM: '1280px',
  laptopS: '992px',
  tabletL: '768px',
  tabletS: '576px',
  mobile: '414px',
};
