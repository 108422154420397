import React from 'react';

function MSquaresThree4() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='525'
      height='585'
      fill='none'
      viewBox='0 0 525 585'
    >
      <path
        fill='#CFE0FF'
        d='M13.12 243.262c-20.452-25.246-16.567-62.292 8.68-82.745L203.737 13.12c25.246-20.452 62.292-16.567 82.745 8.68l147.396 181.938c20.453 25.246 16.568 62.292-8.678 82.745L243.262 433.879c-25.246 20.453-62.292 16.568-82.745-8.678L13.12 243.262zm284.046 261.519c-8.053-9.94-6.523-24.527 3.417-32.58l71.636-58.035c9.94-8.053 24.527-6.523 32.58 3.417l58.035 71.636c8.053 9.94 6.523 24.527-3.417 32.58l-71.636 58.035c-9.94 8.053-24.527 6.523-32.58-3.417l-58.035-71.636zm130.769-151.36c-4.575-5.648-3.706-13.936 1.942-18.511l40.702-32.975c5.648-4.575 13.936-3.706 18.511 1.942l32.975 40.702c4.575 5.648 3.706 13.936-1.942 18.511l-40.702 32.975c-5.648 4.575-13.936 3.706-18.511-1.942l-32.975-40.702z'
      />
    </svg>
  );
}

export default MSquaresThree4;
