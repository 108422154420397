import { ReactComponent as Adonis } from 'src/assets/images/backend/adonis.svg';
import { ReactComponent as Elastic } from 'src/assets/images/backend/elastic.svg';
import { ReactComponent as Expressjs } from 'src/assets/images/backend/expressjs.svg';
import { ReactComponent as Node } from 'src/assets/images/backend/node.svg';
import { ReactComponent as Python } from 'src/assets/images/backend/python.svg';
import { ReactComponent as Laravel } from 'src/assets/images/backend/laravel.svg';
import { TechnologieItem } from '../technologie-item';

const TechnologiesBackend = () => {
  const items = [
    { title: 'Node.js', SvgLogo: <Node /> },
    { title: 'Express.js', SvgLogo: <Expressjs /> },
    { title: 'Elastic', SvgLogo: <Elastic /> },
    { title: 'Python', SvgLogo: <Python /> },
    { title: 'Laravel', SvgLogo: <Laravel /> },
    { title: 'Adonis', SvgLogo: <Adonis /> },
  ];

  return items.map((item) => (
    <TechnologieItem
      key={item.title}
      title={item.title}
      svgLogo={item.SvgLogo}
    />
  ));
};

export default TechnologiesBackend;
