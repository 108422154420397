import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouter from './routes/AppRouter';
import './utils/i18next';

ReactDOM.render(<AppRouter />, document.getElementById('root'));

reportWebVitals();
