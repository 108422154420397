import styled from 'styled-components/macro';
import { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { media } from 'src/assets/style/media';
import { isMobile } from 'react-device-detect';

const Circle = ({ icon, children, title, hoverSize, size, className }) => {
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    !isMobile && setHovered(true);
  };

  const onMouseLeave = () => {
    !isMobile && setHovered(false);
  };

  const onClick = () => {
    isMobile && setHovered(!hovered);
  };

  const transitions = useTransition(hovered, {
    from: {
      opacity: 0,
      height: 0,
      transform: 'scale(0.9)',
    },
    enter: { opacity: 1, height: 'min-content', transform: 'scale(1)' },
    leave: { opacity: 0, height: 0, transform: 'scale(0.9)' },
    delay: 200,
  });

  const transitions2 = useTransition(!hovered, {
    from: { opacity: 0, height: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, height: 'min-content', transform: 'scale(1)' },
    leave: { opacity: 0, height: 0, transform: 'scale(0.9)' },
    delay: 200,
  });

  return (
    <CircleContainer
      hovered={hovered}
      hoverSize={hoverSize}
      size={size}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={className}
    >
      {transitions(
        (style, item) =>
          item && (
            <Animated style={style}>
              <HiddenCircleTitle>{title}</HiddenCircleTitle>
              <ItemsContainer>{children}</ItemsContainer>
            </Animated>
          ),
      )}
      {transitions2(
        (style, item) =>
          item && (
            <Animated style={style}>
              <CircleTitle>{title}</CircleTitle>
              {icon}
            </Animated>
          ),
      )}
    </CircleContainer>
  );
};

const CircleContainer = styled.div`
  --size-multiplier: 1;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.144);
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ hovered, hoverSize, size }) =>
    hovered
      ? `calc(${hoverSize}vw * var(--size-multiplier))`
      : `calc(${size}vw * var(--size-multiplier))`};
  height: ${({ hovered, hoverSize, size }) =>
    hovered
      ? `calc(${hoverSize}vw * var(--size-multiplier))`
      : `calc(${size}vw * var(--size-multiplier))`};
  padding: 1.875vw;
  transition: all 0.5s cubic-bezier(0.41, 0.01, 0.31, 0.89);
  flex-direction: column;
  font-size: 1.1vw;

  @media ${media.laptopM()} {
    --size-multiplier: 1.5;
    padding: 2.875vw;
    font-size: 2.1vw;
  }

  @media ${media.tabletL()} {
    --size-multiplier: 2.5;
    padding: 3.875vw;
    font-size: 3.6vw;
  }
`;

const CircleTitle = styled.p`
  margin-bottom: 1.3vw;
  transition: all 0.5s cubic-bezier(0.41, 0.01, 0.31, 0.89);
  text-align: center;
`;

const HiddenCircleTitle = styled(CircleTitle)``;

const ItemsContainer = styled.div``;

const Animated = styled(animated.div)`
  min-height: 0;
  overflow: hidden;
  text-align: center;
`;

export default Circle;
