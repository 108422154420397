import { ReactComponent as D3 } from 'src/assets/images/front/d3.svg';
import { ReactComponent as Canvas } from 'src/assets/images/front/canvas.svg';
import { ReactComponent as Three } from 'src/assets/images/front/threejs.svg';
import { ReactComponent as Vue } from 'src/assets/images/front/vue.svg';
import { ReactComponent as HtmlLogo } from 'src/assets/images/front/html.svg';
import { ReactComponent as CssLogo } from 'src/assets/images/front/css.svg';
import { TechnologieItem } from '../technologie-item';
import { Mern } from './components/mern';

const TechnologiesFrontend = () => {
  const items = [
    { title: 'D3', SvgLogo: <D3 /> },
    { title: 'Canvas', SvgLogo: <Canvas /> },
    { title: 'Three.JS', SvgLogo: <Three /> },
    { title: 'Vue.JS', SvgLogo: <Vue /> },
    { title: 'HTML5', SvgLogo: <HtmlLogo /> },
    { title: 'css3', SvgLogo: <CssLogo /> },
  ];

  return (
    <>
      {items.map((item) => (
        <TechnologieItem
          key={item.title}
          title={item.title}
          svgLogo={item.SvgLogo}
        />
      ))}
      <Mern />
    </>
  );
};

export default TechnologiesFrontend;
