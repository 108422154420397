import {
  CasesHeader,
  CasesPageWrapper,
  ContactUsModal,
  ContactUsSection,
  Footer,
} from 'src/components';
import {
  SaiktPreviewSection,
  SaiktObjectivesSection,
  SaiktProjectSection,
  SaiktTechnicalSection,
  SaiktSliderSection,
} from 'src/screen-components/saikt';
import { ParallaxProvider } from 'react-scroll-parallax';
import styled from 'styled-components/macro';
import { useToggle } from 'src/hooks/use-toggle';
import { media } from 'src/assets/style/media';
import { useEffect } from 'react';

const SaiktPage = () => {
  const { isOpen, openToggle, closeToggle } = useToggle();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <ParallaxProvider>
      <StyledCasesPageWrapper>
        <StyledCasesHeader variant='dark' />
        <SaiktPreviewSection />
        <SaiktObjectivesSection />
        <SaiktProjectSection />
        <SaiktTechnicalSection />
        <SaiktSliderSection />
        <StyledContactUsSection setModalOpen={openToggle} />
        <StyledFooter isLogoReversed={true} />
        {isOpen && <ContactUsModal closeModal={closeToggle} />}
      </StyledCasesPageWrapper>
    </ParallaxProvider>
  );
};

const StyledCasesPageWrapper = styled(CasesPageWrapper)`
  overflow: hidden;
  h1,
  h2,
  h4 {
    color: var(--saikt-text-color);
  }
`;

const StyledContactUsSection = styled(ContactUsSection)`
  color: black;
  position: relative;
  z-index: 3;
  font-family: 'gyre heros', serif;
  padding: 50px 140px;

  h2 {
    font-size: 18px;
  }

  @media ${media.laptopM()} {
    padding: 50px 70px;
  }

  @media ${media.laptopS()} {
    padding: 20px;
  }
  @media ${media.tabletL()} {
    margin-top: 0;
  }
`;

const StyledFooter = styled(Footer)`
  color: black;
  font-family: 'gyre heros', serif;
  padding: 50px 140px;

  @media ${media.laptopM()} {
    padding: 50px 70px;
  }

  @media ${media.laptopS()} {
    padding: 20px;
  }
`;

const StyledCasesHeader = styled(CasesHeader)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

export default SaiktPage;
