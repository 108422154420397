import { ParallaxControl } from 'src/components';
import styled from 'styled-components/macro';
import shipOne from 'src/assets/images/cases/ark/ship-1.svg';
import shipTwo from 'src/assets/images/cases/ark/ship-2.svg';
import shipThree from 'src/assets/images/cases/ark/ship-3.svg';
import shipFor from 'src/assets/images/cases/ark/ship-4.svg';
import shipFive from 'src/assets/images/cases/ark/ship-5.svg';
import { media } from 'src/assets/style/media';

const PreviewLayers = () => {
  return (
    <PreviewLayersWrapper>
      <Layer1>
        <ParallaxControl speed='.2'>
          <img src={shipOne} alt='ship' />
        </ParallaxControl>
      </Layer1>
      <Layer2>
        <ParallaxControl speed='-.05'>
          <img src={shipTwo} alt='ship' />
        </ParallaxControl>
      </Layer2>
      <Layer3>
        <ParallaxControl speed='.05'>
          <img src={shipThree} alt='ship' />
        </ParallaxControl>
      </Layer3>
      <Layer4>
        <ParallaxControl speed='-.1'>
          <img src={shipFor} alt='ship' />
        </ParallaxControl>
      </Layer4>
      <Layer5>
        <ParallaxControl speed='.5'>
          <img src={shipFive} alt='ship' />
        </ParallaxControl>
      </Layer5>
    </PreviewLayersWrapper>
  );
};

const PreviewLayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Layer1 = styled.div`
  position: absolute;
  top: calc(5% - 3px);
  right: calc(100% - min(30vw / 2, 464px / 2));
  width: min(30vw, 264px);
  height: min(30vw, 208px);

  @media ${media.laptopS()} {
    top: auto;
    bottom: 100%;
  }
`;

const Layer2 = styled.div`
  position: absolute;
  top: calc(5% + 6px);
  right: 21px;
  width: min(10vw, 45px);
  height: min(15vw, 95px);
`;

const Layer3 = styled.div`
  position: absolute;
  top: calc(50% + 6px);
  left: -9px;
  width: min(15vw, 71px);
  height: min(20vw, 145px);
`;

const Layer4 = styled.div`
  position: absolute;
  bottom: -13px;
  left: calc(15% + 19px);
  width: min(15vw, 91px);
  height: min(20vw, 187px);
`;

const Layer5 = styled.div`
  position: absolute;
  bottom: calc(-40% + -24px);
  left: calc(100% - min(60vw / 2, 777px / 2));
  width: min(60vw, 798px);
  height: min(50vw, 602px);

  @media ${media.laptopL()} {
    left: calc(100% - min(60vw / 2, 377px / 2));
  }

  @media ${media.laptopS()} {
    bottom: calc(-70% + -24px);
    left: calc(100% - min(60vw / 2, 377px / 2));
    width: min(80vw, 798px);
    height: min(70vw, 602px);
  }
`;

export default PreviewLayers;
