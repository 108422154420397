import React from 'react';

function MDecorSmall({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='139'
      fill='none'
      viewBox='0 0 30 139'
    >
      <path
        fill='#E3E3E3'
        d='M30 0v3.757H0V0h30zm0 7.513v3.757H0V7.513h30zm0 7.514v3.757H0v-3.757h30zm0 7.514v3.756H0v-3.756h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.756H0v-3.756h30zm0 7.513v3.757H0V45.08h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.757H0v-3.757h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.757H0v-3.757h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.757H0v-3.757h30zm0 7.514v3.756H0v-3.756h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.756H0v-3.756h30zm0 7.513v3.757H0v-3.757h30zm0 7.514v3.757H0v-3.757h30zm0 7.513V139H0v-3.757h30z'
      />
    </svg>
  );
}

export default MDecorSmall;
