import React from 'react';
import Swiper from 'react-id-swiper';
import styled from 'styled-components/macro';

const Slider = ({ rebuildOnUpdate, children, ...rest }) => {
  return (
    <SliderPrimary className={`slider--primary`}>
      <Swiper rebuildOnUpdate={rebuildOnUpdate} {...rest}>
        {children}
      </Swiper>
    </SliderPrimary>
  );
};

const SliderPrimary = styled.div`
  [class^='swiper-button'] {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 40%;
    outline: none;
    z-index: 1;

    &:after {
      content: none;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
`;

export default Slider;
