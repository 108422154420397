import { media } from 'src/assets/style/media';
import styled from 'styled-components/macro';

const TechnologieItem = ({ title, svgLogo }) => {
  return (
    <ItemContainer>
      {svgLogo}
      <ItemTitle>{title}</ItemTitle>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media ${media.laptopM()} {
    margin-bottom: 7px;
  }

  @media ${media.laptopS()} {
    margin-bottom: 4px;
  }

  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 10px;
  }
`;

const ItemTitle = styled.p`
  font-size: 1.27em;
`;

export default TechnologieItem;
