import React from 'react';

function Front() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='209'
      height='71'
      fill='none'
      viewBox='0 0 209 71'
    >
      <path
        fill='#E44D26'
        d='M49.587 13.767l-4.518 50.61-20.307 5.633-20.25-5.634L0 13.766h49.587z'
      />
      <path
        fill='#F16529'
        d='M24.79 65.705l16.408-4.548 3.854-43.251H24.79v47.799z'
      />
      <path
        fill='#EBEBEB'
        d='M16.013 30.322h8.781v-6.208H9.23l.149 1.666 1.526 17.107h13.892V36.68h-8.215l-.569-6.357zm1.411 15.67H11.2l.869 9.748 12.7 3.54h.029v-6.478h-.027l-6.913-1.868-.433-4.942z'
      />
      <path
        fill='#000'
        d='M4.618.01h3.154v3.116h2.884V.01h3.155v9.44h-3.155V6.29H7.772v3.16H4.618V.01zm13.34 3.13H15.18V.01h8.71v3.13h-2.778v6.31h-3.151V3.14h-.003zM25.274.01h3.29l2.026 3.317L32.61.01h3.291v9.44H32.76V4.77l-2.17 3.356h-.054L28.363 4.77v4.68h-3.089V.01zm12.196 0h3.154v6.318h4.447v3.119H37.48V.01h-.009z'
      />
      <path
        fill='#fff'
        d='M24.77 42.887h7.643l-.72 8.053-6.923 1.868v6.472l12.727-3.528.092-1.05 1.459-16.345.15-1.666H24.77v6.208-.012zm0-12.58v.015h14.995l.124-1.396.297-3.149.148-1.666H24.77v6.197z'
      />
      <path
        fill='#264DE4'
        d='M93.842 70.01l-19.836-5.617-4.42-50.62h48.571l-4.424 50.613-19.891 5.624z'
      />
      <path
        fill='#2965F1'
        d='M109.945 61.165l3.781-43.251h-19.85v47.79l16.069-4.54z'
      />
      <path
        fill='#EBEBEB'
        d='M79.732 36.68l.545 6.207h13.608V36.68H79.732zm-1.096-12.566l.553 6.207h14.694v-6.207H78.625h.011zm15.247 28.69h-.026l-6.773-1.868-.433-4.952h-6.116l.852 9.748 12.455 3.538h.029l.012-6.466z'
      />
      <path
        fill='#000'
        d='M81.019.01h7.364v3.145h-4.286V6.3h4.286v3.145h-7.364V.01zm8.849 0h7.364v2.735h-4.296v.547h4.286v6.29h-7.366V6.71h4.286v-.547h-4.286V.01h.012zm8.849 0h7.364v2.735h-4.308v.547h4.286v6.29h-7.366V6.71h4.286v-.547h-4.286V.01h.024z'
      />
      <path
        fill='#fff'
        d='M107.988 36.68l1.097-12.566H93.844v6.207h8.569l-.553 6.35h-8.016v6.207h7.488l-.718 8.058-6.77 1.873v6.459l12.465-3.529.091-1.05 1.435-16.346.148-1.668.005.005z'
      />
      <g clipPath='url(#clip0)'>
        <path fill='#F7DF1E' d='M138.156.01h70v70h-70v-70z' />
        <path
          fill='#000'
          d='M156.559 58.507l5.357-3.242c1.033 1.832 1.973 3.383 4.228 3.383 2.162 0 3.525-.846 3.525-4.135V32.147h6.578v22.46c0 6.813-3.994 9.914-9.821 9.914-5.262 0-8.317-2.725-9.867-6.015v.001zm23.261-.705l5.356-3.101c1.41 2.303 3.243 3.994 6.485 3.994 2.725 0 4.463-1.363 4.463-3.242 0-2.256-1.785-3.055-4.792-4.37l-1.645-.705c-4.746-2.02-7.894-4.558-7.894-9.914 0-4.934 3.759-8.694 9.633-8.694 4.182 0 7.189 1.457 9.35 5.263l-5.122 3.29c-1.128-2.02-2.349-2.82-4.228-2.82-1.927 0-3.149 1.222-3.149 2.82 0 1.973 1.222 2.773 4.041 3.994l1.645.705c5.591 2.397 8.74 4.84 8.74 10.337 0 5.92-4.652 9.163-10.902 9.163-6.108 0-10.055-2.913-11.981-6.72'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path
            fill='#fff'
            d='M0 0h70v70H0z'
            transform='translate(138.156 .01)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Front;
