import React from 'react';

function MSlider() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1645'
      height='956'
      fill='none'
      viewBox='0 0 1645 956'
    >
      <path
        fill='#41E8FF'
        d='M1589.63 178.408h-8.42v35.345l-12.63 6.733v52.176l26.95 26.088v345.876l-24.42 23.564v88.362h20.21v-79.947l32-29.454v-90.046l-10.95-10.94V397.211l10.95-14.306v-86.68l-33.69-29.454v-88.363zm10.95-88.362l20.21 18.514V61.433h13.47V37.87h-24.42V26.088H1593V15.989L1560.16 0l-1.68 5.05 19.36 10.94 4.21 6.732h-36.2V32.82h17.68l18.52 11.782v16.831l18.53 15.148v13.465z'
      />
      <path
        fill='#2F6984'
        d='M1513 41.236H822.499v879.418h671.981l34.52-25.246 51.37-46.286v-16.83l5.89-5.05v-73.215l-5.89-78.264 22.74-44.602V295.384l-22.74-26.088V90.887l-29.47-31.979h-15.16L1513 41.236z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='3.368'
        d='M822.502 40.394h691.348l22.73 18.514h14.32l29.47 31.138v124.549l-11.79 6.732v51.335l26.95 26.088v345.035l-22.74 24.405v53.859h13.48v106.035h-7.58v21.88L1499 917'
      />
      <path
        fill='#41E8FF'
        d='M1474.27 30.296v9.257h40.42l21.05 19.355h17.46l-21.67-33.661h-29.47v5.049h-27.79zm76.63 881.101l-4.21 9.257h45.47v-7.574l41.26-15.148v-25.246h-10.95v-37.87l-21.05 19.356-.84 8.415-17.68 15.148v12.623l-14.32 17.673-17.68 3.366z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='6.737'
        d='M1630.9 58.067L1641 70.69v789.373l-10.95 18.514'
      />
      <path
        fill='#41E8FF'
        d='M1623.32 396.369l16-12.623V559.63l-16-13.465V396.369z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='1.684'
        d='M1620.79 116.134L1577 74.898m43.79 49.651l-28.63-26.93m28.63 35.346l-28.63-26.93m28.63 35.345l-28.63-26.929m28.63 35.344l-28.63-26.929m28.63 35.345l-28.63-26.93m28.63 35.346l-28.63-26.93m28.63 35.345l-28.63-26.93m28.63 35.346l-28.63-26.93m28.63 35.345l-28.63-26.93m28.63 35.345l-28.63-26.929m28.63 35.345l-23.58-21.88m23.58 30.295l-23.58-21.88m23.58 30.296l-23.58-21.88m23.58 30.296l-23.58-21.881m23.58 30.296l-23.58-21.88m23.58 30.295l-23.58-21.88m23.58 30.296l-23.58-21.88m23.58 30.295l-23.58-21.88m23.58 30.296l-23.58-21.88m23.58 30.295l-23.58-21.88m25.26 563.838l-43.78 41.236m43.78-49.652l-28.63 26.93m28.63-35.345l-28.63 26.929m28.63-35.345l-28.63 26.93m28.63-35.345l-28.63 26.929m28.63-35.345l-28.63 26.93m28.63-35.345l-28.63 26.929m28.63-35.345l-28.63 26.93m28.63-35.345l-28.63 26.929m28.63-35.345l-28.63 26.93m28.63-35.345l-28.63 26.929m28.63-35.345l-23.57 21.881m23.57-30.296l-23.57 21.88m23.57-30.296l-23.57 21.881m23.57-30.296l-23.57 21.88m23.57-30.295l-23.57 21.88m23.57-30.296l-23.57 21.88m23.57-30.295l-23.57 21.88m23.57-30.296l-23.57 21.88m23.57-30.295l-23.57 21.88m23.57-30.296l-23.57 21.88'
      />
      <path
        fill='#41E8FF'
        d='M55.367 178.408h8.42v35.345l12.632 6.733v52.176L49.472 298.75v345.876l24.42 23.564v88.362h-20.21v-79.947l-31.998-29.454v-90.046l10.947-10.94V397.211l-10.947-14.306v-86.68l33.683-29.454v-88.363zM44.42 90.046L24.21 108.56V61.433H10.736V37.87h24.42V26.088h16.842V15.989L84.84 0l1.684 5.05-19.367 10.94-4.21 6.732h36.209V32.82H81.47L62.945 44.602v16.831L44.42 76.581v13.465z'
      />
      <path
        fill='#2F6984'
        d='M131.997 41.236h690.504v879.418H150.523l-34.526-25.246-51.366-46.286v-16.83l-5.895-5.05v-73.215l5.895-78.264-22.736-44.602V295.384l22.736-26.088V90.887l29.472-31.979h15.158l22.736-17.672z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='3.368'
        d='M822.498 40.394H131.152l-22.736 18.514H94.101L64.628 90.046v124.549l11.79 6.732v51.335L49.47 298.75v345.035l22.736 24.405v53.859H58.734v106.035h7.578v21.88L149 919'
      />
      <path
        fill='#41E8FF'
        d='M170.729 30.296v9.257H130.31l-21.052 19.355h-17.46l21.67-33.661h29.473v5.049h27.788zM94.102 911.397l4.21 9.257H52.84v-7.574l-41.262-15.148v-25.246h10.947v-37.87l21.052 19.356.842 8.415 17.684 15.148v12.623l14.315 17.673 17.684 3.366z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='6.737'
        d='M14.105 58.067L4 70.69v789.373l10.947 18.514'
      />
      <path
        fill='#41E8FF'
        d='M21.683 396.369l-16-12.623V559.63l16-13.465V396.369z'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='1.684'
        d='M24.21 116.134L68 74.898m-43.79 49.651l28.632-26.93M24.21 132.965l28.632-26.93M24.21 141.38l28.632-26.929M24.21 149.796l28.632-26.93M24.21 158.211l28.632-26.929M24.21 166.627l28.632-26.93M24.21 175.042l28.632-26.929M24.21 183.458l28.632-26.93M24.21 191.873l28.632-26.929M24.21 200.289l28.632-26.93M24.21 208.704l23.58-21.88M24.21 217.12l23.58-21.881m-23.58 30.296l23.58-21.88m-23.58 30.296l23.58-21.881m-23.58 30.296l23.58-21.88m-23.58 30.295l23.58-21.88m-23.58 30.296l23.58-21.88m-23.58 30.296l23.58-21.881m-23.58 30.296l23.58-21.88m-23.58 30.295l23.58-21.88M22.526 826.401l43.788 41.236m-43.789-49.652l28.631 26.93M22.525 809.57l28.631 26.929m-28.631-35.345l28.631 26.93m-28.631-35.345l28.631 26.929m-28.631-35.345l28.631 26.93m-28.631-35.345l28.631 26.929m-28.631-35.345l28.631 26.93m-28.631-35.345l28.631 26.929m-28.631-35.345l28.631 26.93m-28.631-35.345l28.631 26.929M22.525 733.83l23.579 21.881m-23.579-30.296l23.579 21.88m-23.579-30.296l23.579 21.881m-23.579-30.296l23.579 21.88m-23.579-30.295l23.579 21.88m-23.579-30.296l23.579 21.88m-23.579-30.295l23.579 21.88m-23.579-30.296l23.579 21.88m-23.579-30.295l23.579 21.88m-23.579-30.296l23.579 21.88'
      />
      <path
        stroke='#41E8FF'
        strokeWidth='4'
        d='M821.671 13.465v16.83m17.683-16.83v16.83m16.841-16.83v16.83m16.841-16.83v16.83m16.843-16.83v16.83m16.841-16.83v16.83m16.842-16.83v16.83m16.842-16.83v16.83m16.841-16.83v16.83m16.843-16.83v16.83m16.841-16.83v16.83m16.841-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m16.85-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m16.85-16.83v16.83m16.84-16.83v16.83m16.84-16.83v16.83m0-16.83v16.83m-391.357-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.843-16.83v16.83m-16.84-16.83v16.83m-16.842-16.83v16.83m-16.843-16.83v16.83m-16.841-16.83v16.83m-16.843-16.83v16.83m-16.84-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.843-16.83v16.83m-16.842-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.841-16.83v16.83m-16.845-16.83v16.83m-16.841-16.83v16.83M448 13.465v16.83m0-16.83v16.83M777.043 939v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831M1017.04 939v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m20-16.831v16.831m40-16.831v16.831m-20-16.831v16.831m-459.367-16.663v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.843-16.831v16.831m-16.84-16.831v16.831m-16.842-16.831v16.831m-16.843-16.831v16.831m-16.841-16.831v16.831m-16.843-16.831v16.831m-16.84-16.831v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.843-16.831v16.831m-16.842-16.831v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.841-16.831v16.831m-16.845-16.831v16.831m-16.841-16.831v16.831M404 939.169V956m0-16.831V956'
      />
      <path
        fill='#41E8FF'
        d='M1585.42 852.489v-24.405h-6.73v21.88l-87.58 70.69 40.42.842 26.1-29.455v-15.147l27.79-24.405zm-1525.842 0v-24.405h6.737v21.88l87.576 70.69-40.42.842-26.104-29.455v-15.147l-27.789-24.405z'
      />
    </svg>
  );
}

export default MSlider;
