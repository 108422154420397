import React, { useRef, useEffect } from 'react';
import { media } from 'src/assets/style/media';
import { SectionTitle } from 'src/components';
import styled from 'styled-components/macro';
import teamPhoto from 'src/assets/images/home-image-min.png';
import { useTrail, a, useSpringRef, useSpring } from '@react-spring/web';

const about = ['A', 'B', 'O', 'U', 'T'];
const us = ['U', 'S'];

const ioOptions = {
  threshold: 0.5,
};

const OurTeamSection = () => {
  const aboutRef = useRef(null);
  const usRef = useRef(null);
  const textSpringRef = useSpringRef();
  const textRef = useRef(null);

  const [firstTrail, firstTrailApi] = useTrail(5, () => ({
    config: { tension: 2000, mass: 5, friction: 300 },
    from: { scaleY: 0 },
    to: { scaleY: 1 },
  }));

  const [secondTrail, secondTrailApi] = useTrail(2, () => ({
    config: { tension: 2000, mass: 5, friction: 300 },
    from: { scaleY: 0 },
    to: { scaleY: 1 },
  }));

  const spring = useSpring({
    ref: textSpringRef,
    from: { opacity: 0, x: -100 },
  });

  useEffect(() => {
    const about = aboutRef.current;
    const us = usRef.current;
    const text = textRef.current;

    const ioCallback = (entries, observer) => {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          firstTrailApi.start({ scaleY: 1 });
          secondTrailApi.start({ scaleY: 1 });
          textSpringRef.start({
            to: {
              opacity: 1,
              x: 0,
            },
            config: {
              tension: 100,
              friction: 30,
              mass: 3,
            },
          });
        } else {
          firstTrailApi.stop();
          secondTrailApi.stop();
          textSpringRef.stop();
        }
      });
    };
    const observer = new IntersectionObserver(ioCallback, ioOptions);

    if (about && us && text) {
      observer.observe(about);
      observer.observe(us);
      observer.observe(text);
    }
  }, [firstTrailApi, secondTrailApi, textSpringRef]);

  return (
    <SectionWrapper>
      <SectionTitle>Our team</SectionTitle>
      <GridContainer>
        <About ref={aboutRef}>
          {firstTrail.map((item, index) => {
            return (
              <a.span key={index} style={item}>
                {about[index]}
              </a.span>
            );
          })}
        </About>
        <StyledImage src={teamPhoto} alt='team-photo' />
        <MainContentNumber>04</MainContentNumber>
        <Us ref={usRef}>
          {secondTrail.map((item, index) => {
            return (
              <a.span key={index} style={item}>
                {us[index]}
              </a.span>
            );
          })}
        </Us>
        <DesktopText ref={textRef} style={spring}>
          <Text>
            Looking for a reliable IT partner for your business? Are you worried
            about technicalities and having a lack of well-articulated specs?
          </Text>
        </DesktopText>

        <TabletText>
          Looking for a reliable IT partner for your business?
        </TabletText>
        <BottomText>
          Are you worried about technicalities and having a lack of
          well-articulated specs?
        </BottomText>
      </GridContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  margin-bottom: 100px;
  color: var(--white);
  @media ${media.laptopS()} {
    margin-bottom: 44px;
  }
  @media ${media.tabletS()} {
    margin-bottom: 20px;
  }
`;

const GridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 12fr 8fr 20fr;
  gap: 20px;
  text-transform: uppercase;
  @media ${media.laptopS()} {
    gap: 10px;
    grid-template-columns: 14fr 8fr 30fr;
  }
`;

const BigWord = styled.p`
  font-size: calc(15vw - 10px);
  line-height: 0.65em;
  font-weight: 700;
  margin-bottom: 0.1em;
  @media ${media.laptopL()} {
    font-size: calc(15vw - 15px);
  }
`;

const About = styled(BigWord)`
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  span {
    transform-origin: bottom center;
  }
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;
`;

const Us = styled(BigWord)`
  grid-column-start: 2;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  span {
    transform-origin: top center;
  }
`;

const DesktopText = styled(a.div)`
  grid-column-start: 1;
  grid-row-start: 2;
  ${({ style }) => {
    return style;
  }}
  @media ${media.tabletL()} {
    display: none;
  }
`;

const Text = styled.p`
  font-size: 1.875vw;
  line-height: 0.9em;
  @media ${media.laptopS()} {
    font-size: 3.47vw;
    line-height: 1em;
  }
`;
const TabletText = styled(Text)`
  display: none;
  @media ${media.tabletL()} {
    display: block;
    grid-column-start: 1;
    grid-row-start: 2;
  }
  @media ${media.tabletS} {
    line-height: 1em;
  }
`;

const BottomText = styled(TabletText)`
  display: none;
  @media ${media.tabletL()} {
    display: block;
    grid-row-start: 3;
    grid-column-end: 4;
  }
  @media ${media.tabletS} {
    line-height: 1em;
  }
`;

const MainContentNumber = styled.p`
  position: absolute;
  grid-column-start: 3;
  right: -1.5rem;
  bottom: -30%;
  font-size: 288px;
  color: var(--green-main);
  opacity: 0.4;
  width: fit-content;
  font-weight: 700;
  text-align: center;
  line-height: 0.9em;
  height: min-content;
  align-self: center;
  @media ${media.desktopM()} {
    bottom: -25%;
    right: -10%;
  }
  @media ${media.desktopL()} {
    bottom: -20%;
    right: 0;
  }
  @media ${media.laptopL()} {
    font-size: 224px;
  }
  @media ${media.laptopM()} {
    font-size: 192px;
    text-align: left;
  }
  @media ${media.laptopS()} {
    font-size: 144px;
    bottom: 25%;
  }
  @media ${media.tabletL()} {
    font-size: 112px;
    bottom: 0;
  }
  @media ${media.tabletS()} {
    font-size: 96px;
    right: 0;
  }
  @media ${media.mobile()} {
    font-size: 56px;
    margin-right: 10px;
    width: auto;
    bottom: 10%;
    right: -10%;
  }
`;

export default OurTeamSection;
