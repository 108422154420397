import { useTranslation } from 'react-i18next';
import { SharedContainer, SliderProject } from 'src/components';
import {
  Assign,
  Solution,
  SomeTime,
} from 'src/components/svg/cases/pdq/images';
import {
  MAnalysis,
  MLineBot,
  MLineRes,
  MLiners,
} from 'src/components/svg/cases/pdq/models';
import styled from 'styled-components/macro';
import { DescLayers } from './components';
import screenA from 'src/assets/images/cases/pdq/screen-a.svg';
import screenB from 'src/assets/images/cases/pdq/screen-b.svg';
import screenC from 'src/assets/images/cases/pdq/screen-c.svg';
import screenD from 'src/assets/images/cases/pdq/screen-d.svg';
import { media } from 'src/assets/style/media';

const DescSection = () => {
  const { t } = useTranslation(['fixpdq']);

  return (
    <SectionWrapper>
      <SharedContainer>
        <DescMain>
          <h2>{t('fixpdq:project.title')}</h2>
          <DescLayers />
          <div>
            <AnalysisItem>
              <AnalysisData>
                <p>{t('fixpdq:project.analysis')}</p>
                <AnalysisDecor>
                  <MAnalysis />
                </AnalysisDecor>
              </AnalysisData>
              <AnalysisScreen>
                <MLineRes />
                <img src={screenA} alt='' />
              </AnalysisScreen>
            </AnalysisItem>
            <AssignItem>
              <AssignScreen>
                <MLiners />
                <img src={screenB} alt='' />
              </AssignScreen>
              <AssignData>
                <AssignDecor>
                  <Assign />
                </AssignDecor>
                <p>{t('fixpdq:project.assign')}</p>
              </AssignData>
            </AssignItem>
            <SometimeItem>
              <SometimeData>
                <p>{t('fixpdq:project.sometime.1')}</p>
                <p>{t('fixpdq:project.sometime.2')}</p>
              </SometimeData>
              <DescScreen>
                <SometimeDecor>
                  <SomeTime />
                </SometimeDecor>
                <img src={screenC} alt='' />
              </DescScreen>
            </SometimeItem>
            <SolutionItem>
              <DescScreen>
                <img src={screenD} alt='' />
              </DescScreen>
              <SolutionData>
                <p>{t('fixpdq:project.solution.1')}</p>
                <p>{t('fixpdq:project.solution.2')}</p>
                <p>{t('fixpdq:project.solution.3')}</p>
                <SolutionDecor>
                  <Solution />
                </SolutionDecor>
              </SolutionData>
            </SolutionItem>
            <FooterItem>
              <FooterData>
                <p>{t('fixpdq:project.footer')}</p>
                <MLineBot />
              </FooterData>
              <SliderProject
                prevName='ark'
                nextName='cyber-security'
                fillColor='var(--pdq-main-color)'
              />
            </FooterItem>
          </div>
        </DescMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  z-index: 2;
  position: relative;
  overflow: hidden;
  padding-top: 362px;
  margin-top: -291px;
  margin-bottom: -230px;

  @media ${media.laptopM()} {
    margin-bottom: -150px;
  }
`;

const DescMain = styled.div`
  position: relative;

  h2 {
    text-align: center;
    margin-bottom: 67px;

    @media ${media.laptopM()} {
      margin-bottom: 30px;
    }

    @media ${media.laptopS} {
      font-size: 40px;
    }
    @media ${media.tabletL()} {
      font-size: 32px;
    }
    @media ${media.tabletS()} {
      font-size: 24px;
    }
  }
`;

const DescEntryItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 126px;
  }

  @media ${media.tabletL()} {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1641px) {
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }
`;

const DescData = styled.div`
  p {
    font-size: 18px;
    line-height: 22.94px;
    @media ${media.laptopS()} {
      font-size: 14px;
    }
    @media ${media.mobile()} {
      font-size: 12px;
    }
  }
`;

const DescScreen = styled.div`
  width: max(10vw, 989px);
  position: relative;
  min-height: min(57%, 626px);

  @media ${media.laptopL()} {
    width: max(10vw, 789px);
  }

  @media ${media.laptopM()} {
    width: max(10vw, 40%);
  }

  @media ${media.tabletL()} {
    width: 90vw;
    margin: auto;
  }
`;

const AnalysisItem = styled(DescEntryItem)`
  justify-content: flex-end;
  position: relative;

  @media ${media.laptopL('min')} {
    margin: 0 70px;
  }
`;

const AnalysisDecor = styled.div`
  position: absolute;
  left: calc(25% - -7px);
  bottom: 0;
  transform: translateX(-50%);

  @media ${media.laptopL()} {
    width: max(25vw, 320px);
    left: calc(40% - -7px);
  }

  @media ${media.laptopM()} {
    position: relative;
  }

  @media ${media.tabletL()} {
    display: none;
  }
`;

const AnalysisScreen = styled(DescScreen)`
  svg {
    position: absolute;
    width: auto;
    height: auto;
    top: calc(100% - 150px);
    left: calc(100% - 60px);
    transform: translate(-50%, -50%);

    @media ${media.laptopM()} {
      display: none;
    }
  }
`;

const AnalysisData = styled(DescData)`
  width: 389px;
  margin-top: 100px;
  padding-right: 27px;
  position: relative;

  @media ${media.laptopM()} {
    flex: auto;
    margin: 0;
  }

  @media ${media.tabletL()} {
    order: 1;
    width: 100%;
    margin-top: 30px;
  }

  p {
    @include xs(min) {
      max-width: 362px;
    }
  }
`;

const AssignItem = styled(DescEntryItem)`
  justify-content: flex-start;
  position: relative;
  align-items: flex-end;

  p {
    margin-bottom: 66px;

    @media ${media.laptopS()} {
      margin-bottom: 26px;
    }
  }
`;

const AssignDecor = styled.div`
  margin-right: -134px;

  @media ${media.laptopS()} {
    margin-right: -104px;
    width: max(25vw, 220px);
  }

  @media ${media.tabletL()} {
    display: none;
  }
`;

const AssignScreen = styled(DescScreen)`
  svg {
    position: absolute;
    z-index: -1;
    width: min(108vw, 2061px);
    top: -112px;
    left: -22%;
  }
`;

const AssignData = styled(DescData)`
  display: flex;
  align-items: flex-end;
  width: max(580px, 30%);
  margin-left: -41px;
  margin-bottom: -71px;
  position: relative;

  @media ${media.tabletL()} {
    order: 1;
    width: 100%;
    margin: 30px 0 0 0;
  }
`;

const SometimeItem = styled(DescEntryItem)`
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
`;

const SometimeData = styled(DescData)`
  width: 632px;
  margin-top: 328px;
  padding-right: 107px;
  position: relative;

  @media ${media.tabletL()} {
    order: 1;
    width: 100%;
    margin-top: 30px;
    padding: 0;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const SometimeDecor = styled.div`
  position: absolute;
  top: -25px;
  right: calc(100% - -10px);

  @media ${media.tabletL()} {
    display: none;
  }
`;

const SolutionItem = styled(DescEntryItem)`
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
`;

const SolutionData = styled(DescData)`
  width: 636px;
  margin-top: 62px;
  position: relative;
  padding-left: 36px;

  @media ${media.laptopM()} {
    margin-top: 0;
    svg {
      width: 100%;
    }
  }

  @media ${media.tabletL()} {
    order: 1;
    width: 100%;
    margin-top: 30px;
    padding: 0;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const SolutionDecor = styled.div`
  @media ${media.laptopL()} {
    display: none;
  }

  @media ${media.tabletL()} {
    display: block;
  }
`;

const FooterItem = styled(DescEntryItem)`
  margin-top: -72px;
  padding-bottom: 300px;
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 96%;

  @media ${media.tabletL()} {
    margin-top: 0;
  }

  p {
    max-width: 600px;
  }
`;

const FooterData = styled(DescData)`
  > svg {
    position: absolute;
    left: calc(50% + 35px);
    transform: translate(-50%, 50%);
    bottom: calc(100% + 14px);

    @media ${media.laptopL()} {
      display: none;
    }
  }
`;

export default DescSection;
