import React from 'react';

function MSquaresThree1() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='272'
      height='355'
      fill='none'
      viewBox='0 0 272 355'
    >
      <path
        fill='#fff'
        d='M7.145 209.591c-11.125 13.732-9.012 33.883 4.72 45.008l114.682 92.908c13.732 11.125 33.883 9.012 45.008-4.721l92.908-114.681c11.125-13.732 9.011-33.883-4.721-45.008L145.061 90.189c-13.733-11.125-33.883-9.012-45.008 4.72L7.145 209.592zM115.553 30.89c-5.215 6.438-4.225 15.884 2.212 21.098l25.236 20.445c6.437 5.215 15.883 4.224 21.098-2.213l20.444-25.236c5.215-6.437 4.224-15.882-2.212-21.097L157.095 3.442c-6.437-5.215-15.883-4.224-21.098 2.213l-20.444 25.236zM68.33 60.383a7 7 0 001.032 9.846l14.041 11.375a7 7 0 009.846-1.032l11.375-14.042a7 7 0 00-1.032-9.845L89.55 45.309a7 7 0 00-9.845 1.033L68.329 60.383z'
      />
    </svg>
  );
}

export default MSquaresThree1;
