export const routes = {
  page404: '*',
  home: {
    full: '/home',
    short: '/',
  },
  cases: {
    cyberSecurity: '/cases/cyber-security',
    saikt: '/cases/saikt',
    ark: '/cases/ark',
    fixpdq: '/cases/pdq',
  },
  terms: '/tos',
  privacy: '/privacy',
};
