import { ParallaxControl } from 'src/components';
import anchor from 'src/assets/images/cases/ark/anchor.svg';
import birds from 'src/assets/images/cases/ark/birds.png';
import ship from 'src/assets/images/cases/ark/ship-6.svg';
import styled from 'styled-components/macro';
import { media } from 'src/assets/style/media';

const ObjectivesLayers = () => {
  return (
    <ObjectivesLayersWrapper>
      <Layer1>
        <ParallaxControl speed='-.1'>
          <img src={birds} alt='birds' />
        </ParallaxControl>
      </Layer1>
      <Layer2>
        <ParallaxControl speed='-.05'>
          <img src={anchor} alt='anchor' />
        </ParallaxControl>
      </Layer2>
      <Layer3>
        <ParallaxControl speed='-.05'>
          <img src={ship} alt='ship' />
        </ParallaxControl>
      </Layer3>
    </ObjectivesLayersWrapper>
  );
};

const ObjectivesLayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Layer1 = styled.div`
  position: absolute;
  bottom: calc(100% - min(25vw / 2, 462px / 2));
  right: calc(24%);
  width: min(20vw, 358px);
  height: min(20vw, 358px);

  @media ${media.laptopL()} {
    right: calc(14%);
    bottom: calc(100% - min(45vw / 2, 662px / 2));
  }

  @media ${media.laptopS()} {
    display: none;
  }
`;

const Layer2 = styled.div`
  position: absolute;
  top: calc(25% + 26px);
  left: 7%;
  width: min(20vw, 288px);
  height: min(20vw, 359px);

  @media ${media.laptopL()} {
    left: -7%;
  }

  @media ${media.laptopM()} {
    display: none;
  }
`;

const Layer3 = styled.div`
  position: absolute;
  bottom: 26px;
  left: calc(100% - min(70vw, 729px));
  width: min(70vw, 854px);
  height: min(50vw, 673px);
  z-index: 1;

  @media ${media.laptopL()} {
    bottom: -20%;
    left: calc(100% - min(70vw / 2, 729px));
  }

  @media screen and (max-width: 600px) {
    bottom: -10%;
  }
`;

export default ObjectivesLayers;
