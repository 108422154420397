import { createBrowserHistory } from 'history';

let lastLocation = {
  pathname: window.location.pathname,
  search: window.location.search,
  hash: window.location.hash,
};

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    callback(false);
  },
});
const prevHistoryPush = history.push;

history.listen((location) => {
  lastLocation = location;
});

history.push = (path, state) => {
  const pathname =
    typeof path !== 'string' ? path.pathname + path.search + path.hash : path;
  state = typeof path !== 'string' ? path.state : state;

  if (
    pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state || {}) !== JSON.stringify(lastLocation.state || {})
  ) {
    prevHistoryPush(path, state || {});
  }
};

export default history;
