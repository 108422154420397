import React from 'react';

function MSquares1() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='494'
      height='494'
      fill='none'
      viewBox='0 0 494 494'
    >
      <path
        fill='#DDE9FF'
        d='M12.97 263.623c-20.218-24.957-16.377-61.58 8.58-81.799L229.975 12.97c24.958-20.218 61.58-16.377 81.799 8.58l168.854 208.425c20.219 24.958 16.378 61.58-8.58 81.799L263.623 480.628c-24.957 20.219-61.58 16.378-81.799-8.58L12.97 263.623z'
      />
    </svg>
  );
}

export default MSquares1;
