function MShapeLine() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1920'
      height='120'
      fill='none'
      viewBox='0 0 1920 120'
    >
      <path
        fill='#51CEEC'
        d='M1887.38 18c13.61 21.946 35.79 21.69 49.24 0v101.455H952V18c13.446-21.69 34.625-21.946 48.23 0 13.6 21.946 35.78 21.69 49.23 0 13.45-21.69 36.63-21.946 50.23 0 13.61 21.946 35.79 21.69 49.23 0 13.45-21.69 35.63-21.946 49.23 0 13.61 21.946 35.79 21.69 49.23 0 13.45-21.69 35.63-21.946 49.24 0 13.6 21.946 35.78 21.69 49.23 0 13.44-21.69 35.62-21.946 49.23 0 13.6 21.946 35.78 21.69 49.23 0 13.44-21.69 35.62-21.946 49.23 0 13.6 21.946 35.78 21.69 49.23 0 13.44-21.69 35.63-21.946 49.23 0 13.6 21.946 35.78 21.69 49.23 0 13.45-21.69 35.63-21.946 49.23 0 13.61 21.946 35.79 21.69 49.23 0 13.45-21.69 35.63-21.946 49.23 0 13.61 21.946 35.79 21.69 49.23 0 13.45-21.69 35.63-21.946 49.23 0z'
      />
      <path
        fill='#51CEEC'
        d='M903.8 16.364c13.596 21.946 35.763 21.69 49.2 0v101.454H-31V16.364c13.437-21.69 34.603-21.946 48.2 0 13.596 21.946 35.762 21.69 49.2 0 13.437-21.69 36.604-21.946 50.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0 13.596 21.946 35.763 21.69 49.2 0 13.437-21.69 35.604-21.946 49.2 0z'
      />
    </svg>
  );
}

export default MShapeLine;
