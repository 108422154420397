function MShape() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2212'
      height='925'
      fill='none'
      viewBox='0 0 2212 925'
    >
      <path
        fill='#fff'
        d='M400.268 599.102c-297.789-45.657-374.33 50.469-384.05 134.848L.237 924.025l2154.163-9.807c25.94-333.216 137.68-897.656-46.3-912.6-183.98-14.943-310.84 220.325-406.68 269.074-175.55 89.292-230.81 130.273-506.15 78.859-258.552-48.278-423.945 61.287-460.559 134.785-43.81 87.943-85.481 152.937-334.443 114.766z'
      />
    </svg>
  );
}

export default MShape;
