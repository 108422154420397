import React from 'react';

function MSquaresTwo2() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='305'
      height='434'
      fill='none'
      viewBox='0 0 305 434'
    >
      <path
        fill='#fff'
        d='M28.365 304.1c-13.04-16.097-10.563-39.717 5.534-52.757L149.9 157.365c16.097-13.04 39.717-10.563 52.757 5.534L296.635 278.9c13.04 16.097 10.563 39.717-5.534 52.757L175.1 425.635c-16.097 13.04-39.717 10.563-52.757-5.534L28.365 304.1zM4.11 76.19c-6.406-7.908-5.19-19.51 2.718-25.916L63.81 4.109c7.907-6.405 19.51-5.188 25.915 2.719l46.165 56.983c6.405 7.907 5.188 19.51-2.719 25.915l-56.983 46.165c-7.907 6.405-19.51 5.188-25.915-2.719L4.109 76.189z'
      />
    </svg>
  );
}

export default MSquaresTwo2;
