import React from 'react';

function MLiners({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='2061'
      height='564'
      fill='none'
      viewBox='0 0 2061 564'
    >
      <path
        fill='#D2F2E4'
        d='M1273.03 73.445L1342.6.09l463.796 489.04-69.57 73.356zm254.6 0L1597.2.09l463.796 489.04-69.57 73.356zm-509.21 0L1087.99.09l463.796 489.04-69.57 73.356zm-254.604 0L833.386.09l463.796 489.04-69.57 73.356zm-254.604 0L578.782.09l463.796 489.04-69.57 73.356zM0 73.446L69.48 0l463.206 489.64-69.48 73.445zm254.604-.001L324.174.09 787.97 489.13l-69.57 73.356z'
      />
    </svg>
  );
}

export default MLiners;
