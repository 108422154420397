import React from 'react';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';
import noiseFull from 'src/assets/images/noise-full.png';
import { CasesHeader } from '../../components';
import { Fragment } from 'react';
import { ReactComponent as NotFoundImage } from 'src/assets/images/404.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';

const NotFound = () => {
  return (
    <Fragment>
      <BackgroundNoise />
      <Header>
        <CasesHeader variant='light' />
      </Header>
      <Container>
        <PageNotFoundMessage>Page not found...</PageNotFoundMessage>
        <NotFoundImage />
        <HomeLink to={routes.home.full}>Take me home</HomeLink>
      </Container>
    </Fragment>
  );
};

const Container = styled.main`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  row-gap: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #171717;
  margin: 0 auto;
  padding: 100px 40px 40px 40px;
  text-align: center;
  font-family: 'gyre heros', sans-serif;
  font-size: 3vh;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04rem;

  & > svg {
    width: 80%;
    height: auto;
    z-index: 5;
  }
`;

const Header = styled.header`
  position: fixed;
  z-index: 10;
`;

const HomeLink = styled(Link)`
  color: var(--white);
  border-bottom: 0.2rem rgba(0, 0, 0, 0) solid;

  &:hover {
    border-bottom: 0.2rem var(--green-inactive) solid;
  }
`;

const PageNotFoundMessage = styled.p`
  color: var(--white);
`;

const NoiseAnimation = keyframes`
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
`;

const BackgroundNoise = styled.div`
  background-image: url(${noiseFull});
  background-repeat: repeat repeat;
  animation: ${NoiseAnimation} 0.2s infinite;
  height: 200vh;
  width: 200%;
  opacity: 0.05;
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  z-index: 1;
  pointer-events: none;
`;

export default NotFound;
