import { media } from 'src/assets/style/media';
import { ParallaxControl, ParallaxControlScroll } from 'src/components';
import {
  MCircle,
  MCircleFill,
  MLineRut,
} from 'src/components/svg/cases/pdq/models';
import styled from 'styled-components/macro';

const DescLayers = () => {
  return (
    <Layers>
      <Layer1>
        <ParallaxControl speed='.2'>
          <MCircle />
        </ParallaxControl>
      </Layer1>
      <Layer2>
        <ParallaxControl speed='.05'>
          <MCircle />
        </ParallaxControl>
      </Layer2>
      <Layer3>
        <ParallaxControl speed='.3'>
          <MCircle />
        </ParallaxControl>
      </Layer3>
      <Layer4>
        <ParallaxControl speed='.1'>
          <MCircle />
        </ParallaxControl>
      </Layer4>
      <Layer5 />
      <Layer6>
        <ParallaxControlScroll y={[-50, 100]}>
          <ParallaxControl speed='.4'>
            <MCircleFill />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer6>
      <Layer7>
        <ParallaxControlScroll y={[-160, 50]}>
          <ParallaxControl speed='.4'>
            <MCircleFill />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer7>
      <Layer8>
        <MLineRut />
      </Layer8>
      <Layer9>
        <ParallaxControlScroll y={[-120, 50]}>
          <ParallaxControl speed='-.3'>
            <MCircleFill />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer9>
      <Layer10>
        <ParallaxControl speed='.2'>
          <MCircle />
        </ParallaxControl>
      </Layer10>
      <Layer11>
        <ParallaxControl speed='.15'>
          <MCircle />
        </ParallaxControl>
      </Layer11>
      <Layer12>
        <ParallaxControl speed='.1'>
          <MCircle />
        </ParallaxControl>
      </Layer12>
      <Layer13>
        <ParallaxControl speed='.05'>
          <MCircle />
        </ParallaxControl>
      </Layer13>
    </Layers>
  );
};

const Layers = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Layer1 = styled.div`
  position: absolute;
  width: min(40vw, 607px);
  height: min(40vw, 607px);
  opacity: 0.5;
  z-index: -2;
  left: 33px;
  top: max(-9%, -69px);
  transform: translateX(-50%);
`;

const Layer2 = styled.div`
  position: absolute;
  width: min(62vw, 1183px);
  height: min(62vw, 1183px);
  opacity: 0.5;
  z-index: -2;
  left: calc(50% - 16px);
  top: max(-7%, -170px);
  transform: translateX(-50%);
`;

const Layer3 = styled.div`
  position: absolute;
  width: min(40vw, 475px);
  height: min(40vw, 475px);
  opacity: 0.5;
  z-index: -2;
  left: 71%;
  top: max(-9%, -160px);
  transform: translateX(-50%);
`;

const Layer4 = styled.div`
  position: absolute;
  width: min(40vw, 652px);
  height: min(40vw, 652px);
  opacity: 0.5;
  z-index: -2;
  left: calc(100% + 26px);
  top: max(-19%, -343px);
  transform: translatex(-50%);
`;

const Layer5 = styled.div`
  position: absolute;
  width: min(120vw, 2400px);
  height: min(calc(100% - 580px), 3500px);
  opacity: 0.5;
  z-index: -5;
  left: 50%;
  top: max(250px, -10%);
  transform: translatex(-50%);
  border-radius: 370px;
  background: #d2f2e4;
`;

const Layer6 = styled.div`
  position: absolute;
  width: min(40vw, 475px);
  height: min(40vw, 475px);
  z-index: -1;
  top: max(7%, 160px);
  transform: translateX(-50%);
  left: calc(100%);
  @media ${media.laptopM()} {
    display: none;
  }
`;

const Layer7 = styled.div`
  position: absolute;
  width: min(40vw, 271px);
  height: min(40vw, 271px);
  z-index: -1;
  top: calc(50% + 100px);
  left: -50px;
  transform: translateX(-50%);

  svg {
    width: 100%;
  }
`;

const Layer8 = styled.div`
  position: absolute;
  width: min(40vw, 614px);
  height: min(40vw, 614px);
  z-index: -1;
  //top: calc(50% + -36px);
  top: calc(50% + -238px);
  left: 10px;
  transform: translate(-50%, -50%);

  @media ${media.laptopL()} {
    display: none;
  }
`;

const Layer9 = styled.div`
  position: absolute;
  bottom: 1000px;
  left: 50%;
  width: min(40vw, 449px);
  height: min(40vw, 449px);
  @media ${media.laptopM()} {
    display: none;
  }
`;

const Layer10 = styled.div`
  position: absolute;
  bottom: 121px;
  left: -222px;
  width: min(50vw, 652px);
  height: min(50vw, 652px);
`;

const Layer11 = styled.div`
  position: absolute;
  bottom: calc(6% + 10px);
  left: calc(18% + 10px);
  width: min(50vw, 652px);
  height: min(50vw, 652px);

  @media ${media.tabletL()} {
    left: -20%;
    bottom: 0;
    width: min(100vw, 652px);
    height: min(100vw, 652px);
  }
`;

const Layer12 = styled.div`
  position: absolute;
  bottom: calc(6% + 22px);
  left: calc(46% + 20px);
  width: min(50vw, 652px);
  height: min(50vw, 652px);
`;

const Layer13 = styled.div`
  position: absolute;
  bottom: calc(2% + -9px);
  right: -170px;
  width: min(60vw, 742px);
  height: min(60vw, 742px);
`;

export default DescLayers;
