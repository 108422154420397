import { useTranslation } from 'react-i18next';
import { ParallaxControl, SharedContainer } from 'src/components';
import styled from 'styled-components/macro';
import {
  SaiktBack,
  SaiktFigma,
  SaiktFront,
} from 'src/components/svg/cases/saikt/images';
import { TechnicalLayers } from './components';
import { media } from 'src/assets/style/media';

const SaiktTechnicalSection = () => {
  const { t } = useTranslation(['saikt']);

  return (
    <SectionWrapper>
      <TechnicalShape />
      <SharedContainer>
        <TechnicalMain>
          <TechnicalItemFront>
            <FrontTitle>
              <h2>{t('saikt:saikt.technical.frontend.title')}</h2>
              <FrontShape>
                <ParallaxControl speed='-.2' limitY={1}>
                  {t('saikt:saikt.technical.frontend.title')}
                </ParallaxControl>
              </FrontShape>
            </FrontTitle>
            <p>{t('saikt:saikt.technical.frontend.text.1')}</p>
            <h4>{t('saikt:saikt.technical.frontend.desc')}</h4>
            <p>{t('saikt:saikt.technical.frontend.text.2')}</p>
          </TechnicalItemFront>
          <TechnicalItemRequirements>
            <RequirementsTitle>
              <h2>{t('saikt:saikt.technical.requirements.title')}</h2>
              <RequirementsShape>
                <ParallaxControl speed='.2' limitY={1}>
                  {t('saikt:saikt.technical.requirements.title')}
                </ParallaxControl>
              </RequirementsShape>
            </RequirementsTitle>
            <SaiktFigma />
            <SaiktFront />
            <SaiktBack />
          </TechnicalItemRequirements>
          <TechnicalItemBack>
            <BackTitle>
              <h2>{t('saikt:saikt.technical.backend.title')}</h2>
              <BackShape>
                <ParallaxControl speed='-.2' limitY={1}>
                  {t('saikt:saikt.technical.backend.title')}
                </ParallaxControl>
              </BackShape>
            </BackTitle>
            <p>{t('saikt:saikt.technical.backend.text.1')}</p>
          </TechnicalItemBack>
          <TechnicalItemConclusion>
            <TechnicalTitle>
              <h2>{t('saikt:saikt.technical.conclusion.title')}</h2>
              <ConclusionShape>
                <ParallaxControl speed='.2' limitY={1}>
                  {t('saikt:saikt.technical.conclusion.title')}
                </ParallaxControl>
              </ConclusionShape>
            </TechnicalTitle>
            <p>{t('saikt:saikt.technical.conclusion.text')}</p>
          </TechnicalItemConclusion>
          <TechnicalLayers />
        </TechnicalMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  padding: 60px 0 133px;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: min(100%, 1920px);
    height: 50%;
    left: 50%;
    background-color: var(--saikt-main-color);
    border-radius: 90px;
    z-index: -1;

    @media ${media.tabletL()} {
      border-radius: 30px;
    }
  }

  &:before {
    top: 0;
    transform: translate(-50%, 0%) skewY(11.5deg) translateY(0%);
    border-bottom-left-radius: 0;

    @media ${media.laptopL('min')} {
      top: 76px;
    }
  }

  &:after {
    bottom: -20px;
    transform: translate(-50%, 0%) skewY(-11.5deg) translateY(0%);
    border-top-left-radius: 0;
  }

  h2 {
    margin-bottom: 28px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  p {
    font-size: 18px;
    line-height: 22.94px;
    color: black;
  }
`;

const TechnicalShape = styled.div`
  position: absolute;
  background-color: var(--saikt-main-color);
  top: 280px;
  bottom: 280px;
  width: 100%;

  @media ${media.laptopL('min')} {
    top: 356px;
  }
`;

const TechnicalTitleShape = styled.div`
  position: absolute;
  color: #cfe0ff;
  font-size: min(15vw, 160px);
  line-height: min(15vw, 195px);
  font-weight: 700;
`;

const TechnicalMain = styled.div`
  position: relative;
  z-index: 0;
  @media ${media.laptopS('min')} {
    padding: 0px 40px;
  }
`;

const TechnicalTitle = styled.div`
  display: inline-block;
  position: relative;

  h2 {
    position: relative;
    z-index: 1;
  }
`;

const TechnicalItem = styled.div`
  position: relative;
`;

const TechnicalItemFront = styled(TechnicalItem)`
  max-width: 700px;
  margin-bottom: 67px;

  h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0 19px;
    @media ${media.tabletS()} {
      font-size: 16px;
    }
    @media ${media.mobile()} {
      font-size: 14px;
    }
  }
`;

const FrontTitle = styled(TechnicalTitle)`
  margin-bottom: -3px;
`;

const FrontShape = styled(TechnicalTitleShape)`
  bottom: 8px;
  left: calc(50% + 82px);
  transform: translateX(-50%);
  line-height: min(15vw, 125px);
`;

const TechnicalItemBack = styled(TechnicalItem)`
  max-width: calc(100% - 110px);
  margin: 0 0 181px auto;

  @media ${media.laptopS()} {
    max-width: 100%;
    margin: 0 auto 81px;
  }

  p {
    max-width: 750px;
  }
`;

const BackTitle = styled(TechnicalTitle)`
  margin-bottom: -1px;
`;

const BackShape = styled(TechnicalTitleShape)`
  bottom: 8px;
  left: calc(50% + 130px);
  transform: translateX(-50%);
  line-height: min(15vw, 125px);
`;

const TechnicalItemRequirements = styled(TechnicalItem)`
  max-width: 634px;
  margin: -119px 0 12px auto;

  @media ${media.laptopL()} {
    margin: -29px 0 62px auto;
  }

  h2 {
    line-height: min(15vw, 79px);
  }

  svg {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 39px;
      position: relative;
      z-index: 10;

      @media ${media.tabletS()} {
        margin-right: 30px;
      }
    }
  }
`;

const RequirementsTitle = styled(TechnicalTitle)`
  width: 100%;
  margin-bottom: 25px;
`;

const RequirementsShape = styled(TechnicalTitleShape)`
  top: calc(50% + -9px);
  left: -15px;
  transform: translateY(-50%);
  white-space: nowrap;
`;

const TechnicalItemConclusion = styled(TechnicalItem)`
  max-width: calc(100% - min(36%, 584px));
  margin-left: auto;

  @media ${media.laptopM()} {
    max-width: 100%;
    margin: auto;
  }

  p {
    max-width: 730px;
  }
`;

const ConclusionShape = styled(TechnicalTitleShape)`
  bottom: 0;
  line-height: min(15vw, 195px);

  @media ${media.laptopS()} {
    bottom: 10%;
  }
`;

export default SaiktTechnicalSection;
