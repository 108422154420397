import styled from 'styled-components/macro';
import React from 'react';
import { ReactComponent as MailImage } from 'src/assets/images/mail.svg';
import { media } from 'src/assets/style/media';

function ContactUsModalSuccessScreen({ error = false }) {
  return (
    <SuccessScreenContainer>
      <HeaderText error={error}>
        {error ? 'OOPS, SOMETHING WENT WRONG!' : 'THANKS!'}
      </HeaderText>
      <MessageContainer>
        {error ? (
          <MessageToCustomer>Please, try again</MessageToCustomer>
        ) : (
          <MessageToCustomer>
            Your message has been received.
            <br />
            We'll write you back soon
          </MessageToCustomer>
        )}
      </MessageContainer>
      <MailImageWrapper>
        <MailImage />
      </MailImageWrapper>
      <HomeReference href='/home'>Take me home</HomeReference>
    </SuccessScreenContainer>
  );
}

const SuccessScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  max-width: 100%;
`;

const HeaderText = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--green-main);
  margin-bottom: 20px;
  font-size: 48px;
  ${(props) => props.error && `color: var(--red)`};
  @media ${media.desktopM()} {
    font-size: 1.6em;
  }
`;

const MessageContainer = styled.div``;

const MessageToCustomer = styled.p`
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  margin-bottom: 50px;
  font-size: 24px;

  @media ${media.desktopM()} {
    font-size: 1em;
  }
`;

const MailImageWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto 80px auto;

  svg {
    width: 100%;
    max-width: 100%;
  }

  @media ${media.desktopM()} {
    svg {
      width: 130%;
    }
  }
`;

const HomeReference = styled.a`
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  margin-bottom: 130px;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  width: fit-content;

  @media ${media.desktopM()} {
    font-size: 1em;
  }

  &:hover {
    border-bottom: 2px solid var(--green-main);
  }
`;

export default ContactUsModalSuccessScreen;
