import React from 'react';
import { media } from 'src/assets/style/media';
import { SectionTitle, Socials } from 'src/components';
import styled from 'styled-components/macro';
import { ContactUsButton } from '../contact-us-button';

const ContactUsSection = ({ setModalOpen, className }) => {
  return (
    <SectionWrapper className={className}>
      <SectionTitle orientation='right'>contact</SectionTitle>
      <ContentContainer>
        <LeftSide>
          <address>ARMENIA, YEREVAN</address>
          <MainContentNumber>05</MainContentNumber>
        </LeftSide>
        <RightSide>
          <Text>connect with us if you want to develop thoughtful product</Text>
          <StyledButton onClick={setModalOpen} />
        </RightSide>
      </ContentContainer>
      <SocialLinks>
        <SocialHeding>STAY IN TOUCH</SocialHeding>
        <Socials gap={20} iconSize={40} />
      </SocialLinks>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  color: var(--white);
  padding-bottom: 60px;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 20px;
  @media ${media.laptopM()} {
    font-size: 9px;
  }
  @media ${media.tabletL()} {
    font-size: 8px;
  }
  @media ${media.tabletS()} {
    font-size: 6px;
  }
  @media ${media.mobile()} {
    font-size: 5px;
  }
`;

const LeftSide = styled.div`
  text-align: right;
  padding-left: 10%;
  width: 42%;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  margin-right: 55px;
  @media ${media.laptopS()} {
    margin-right: 28px;
    padding-left: 5%;
  }
  @media ${media.tabletL()} {
    margin-right: 20px;
    padding-left: 0;
  }
  @media ${media.desktopM()} {
    font-size: 3em;
  }
  @media ${media.desktopL()} {
    font-size: 4em;
  }
`;

const RightSide = styled.div`
  width: 58%;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: 4em;
  font-weight: 700;
  line-height: 1.1;
  @media ${media.tabletL()} {
    font-size: 3em;
  }
  @media ${media.desktopM()} {
    font-size: 5em;
  }
  @media ${media.desktopL()} {
    font-size: 7em;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 25%;
  @media ${media.tabletL()} {
    padding-left: 0;
    & > div {
      gap: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const SocialHeding = styled.p`
  margin-right: 20px;
  font-size: 24px;
  @media ${media.tabletL()} {
    font-size: 18px;
  }
  @media ${media.tabletS()} {
    font-size: 14px;
  }
`;

const StyledButton = styled(ContactUsButton)`
  margin-left: 15vw;
  align-self: center;

  @media ${media.laptopM()} {
    margin-left: 10vw;
  }
  @media ${media.laptopS()} {
    margin-left: 3vw;
    margin-right: 3vw;
  }
  @media ${media.tabletS()} {
    position: absolute;
    left: -5%;
    top: -25%;
  }
  @media ${media.mobile()} {
    left: -7%;
    top: -15%;
  }
`;

const MainContentNumber = styled.p`
  font-size: 288px;
  color: var(--green-main);
  opacity: 0.4;
  width: fit-content;
  font-weight: 700;
  text-align: center;
  line-height: 0.9em;
  height: min-content;
  align-self: end;
  @media ${media.desktopM()} {
  }
  @media ${media.laptopL()} {
    font-size: 224px;
  }
  @media ${media.laptopM()} {
    font-size: 192px;
    text-align: left;
  }
  @media ${media.laptopS()} {
    font-size: 144px;
  }
  @media ${media.tabletL()} {
    font-size: 112px;
    position: absolute;
    left: 3%;
    top: -10%;
  }
  @media ${media.tabletS()} {
    font-size: 96px;
    top: 50%;
    left: 80%;
  }
  @media ${media.mobile()} {
    font-size: 56px;
    margin-right: 10px;
    width: auto;
  }
`;

export default ContactUsSection;
