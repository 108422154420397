import { media } from 'src/assets/style/media';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'src/assets/images/arrow.svg';

const ButtonLink = () => {
  return (
    <StyledLink
      href='https://www.designrush.com/best-designs/apps'
      target='_blank'
      rel='noreferrer'
    >
      <TextBlock>
        <p>International article publication by Design Rush</p>
        <TextClick>Click here</TextClick>
        <Arrow />
      </TextBlock>
    </StyledLink>
  );
};

const TextClick = styled.p`
  color: transparent;
  -webkit-text-stroke: 1px var(--white);

  @media ${media.tabletL()} {
    display: none;
  }
`;

const StyledLink = styled.a`
  width: 100%;
  text-align: center;
  padding: 18px 53px;
  background-color: var(--green-main-opacity);
  margin-bottom: 16px;
  display: block;

  p {
    transition: all 0.2s linear;
  }

  svg {
    transition: all 0.2s linear;
  }

  &:hover {
    ${TextClick} {
      -webkit-text-stroke: 1px var(--gray-hover);
      color: transparent;
    }

    p {
      color: var(--gray-hover);
    }

    svg {
      path {
        stroke: var(--gray-hover);
      }
    }
  }

  @media ${media.desktopS()} {
    padding: 18px 40px;
  }

  @media ${media.laptopL()} {
    padding: 18px 34px;
  }

  @media ${media.laptopS()} {
    padding: 16px;
  }

  @media ${media.tabletS()} {
    padding: 8px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: var(--white);
  text-transform: uppercase;
  transition: all 0.2s linear;

  svg {
    display: none;
  }

  @media ${media.desktopS()} {
    font-size: 30px;
    line-height: 34px;
  }

  @media ${media.laptopL()} {
    font-size: 24px;
    line-height: 26px;
  }

  @media ${media.laptopM()} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${media.laptopS()} {
    font-size: 18px;
    line-height: 20px;
  }

  @media ${media.tabletL()} {
    font-size: 14px;
    line-height: 18px;
    justify-content: center;
    gap: 30px;
    svg {
      display: block;
    }
  }

  @media ${media.tabletS()} {
    font-size: 10px;
    gap: 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export default ButtonLink;
