import { media } from 'src/assets/style/media';
import styled from 'styled-components/macro';

export const SharedContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;

  @media ${media.laptopS('min')} {
    max-width: calc(992px + 15px);
  }

  @media ${media.laptopM('min')} {
    max-width: calc(1280px + 15px);
  }
  @media ${media.laptopL('min')} {
    max-width: calc(1440px + 15px);
  }

  @media ${media.desktopS('min')} {
    max-width: 1655px !important;
  }
`;

export const CasesPageWrapper = styled.main`
  font-family: 'Montserrat', serif;
  h1,
  h2,
  h3 {
    font-weight: 700;
  }

  h1,
  h2,
  h4 {
    line-height: 1.32;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  svg {
    fillrule: evenodd;
    cliprule: evenodd;
  }
  h2 {
    font-size: 64px;
  }
  @media ${media.laptopM()} {
    h2 {
      font-size: 40px;
    }
  }
  @media ${media.tabletL()} {
    h2 {
      font-size: 32px;
    }
  }
  @media ${media.tabletS()} {
    h2 {
      font-size: 24px;
    }
  }
  @media ${media.mobile()} {
    h2 {
      font-size: 16px;
    }
    p,
    li {
      font-size: 12px !important;
    }
  }
`;
