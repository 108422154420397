import { PreviewLayers } from './components';
import styled from 'styled-components/macro';
import title from 'src/assets/images/cases/ark/title.svg';
import { useTranslation } from 'react-i18next';
import { SharedContainer } from 'src/components';
import previewBackground from 'src/assets/images/cases/ark/preview.jpg';
import { media } from 'src/assets/style/media';

const ArkPreview = () => {
  const { t } = useTranslation(['ark']);

  return (
    <SectionWrapper>
      <SharedContainer>
        <PreviewMain>
          <h1>{t('ark:ark.preview.subtitle')}</h1>
          <img src={title} alt='title' />
          <PreviewLayers />
        </PreviewMain>
      </SharedContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: url(${previewBackground}) center / cover no-repeat;
  padding: 99px 0;

  > div {
    height: 100vh;
    max-height: 730px;
    min-height: 500px;
    display: flex;
    align-items: center;
  }
`;

const PreviewMain = styled.div`
  text-align: center;
  position: relative;
  width: 100%;

  img {
    max-width: 80%;
    display: inline;
  }

  h1 {
    color: var(--white);
    font-size: 56px;
    margin-bottom: -48px;

    @media ${media.laptopS()} {
      margin: 0 15px;
      font-size: 36px;
    }
  }

  @media ${media.laptopL('min')} {
    img {
      margin-left: -28px;
    }
  }
`;

export default ArkPreview;
