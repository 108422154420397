import React from 'react';

function SaiktFigma() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='65'
      height='76'
      fill='none'
      viewBox='0 0 65 76'
    >
      <path
        fill='#0ACF83'
        d='M23.485 51.37a8.561 8.561 0 008.558-8.558v-8.56h-8.558c-4.724 0-8.56 3.836-8.56 8.56s3.836 8.559 8.56 8.559z'
      />
      <path
        fill='#A259FF'
        d='M14.914 25.685c0-4.724 3.835-8.558 8.559-8.558h8.559v17.126h-8.56c-4.723 0-8.558-3.835-8.558-8.559v-.009z'
      />
      <path
        fill='#F24E1E'
        d='M14.914 8.559C14.914 3.835 18.75 0 23.473 0h8.559v17.127h-8.56c-4.723 0-8.558-3.835-8.558-8.56V8.56z'
      />
      <path
        fill='#FF7262'
        d='M31.965 0h8.559c4.724 0 8.558 3.835 8.558 8.559 0 4.724-3.834 8.559-8.558 8.559h-8.56V0z'
      />
      <path
        fill='#1ABCFE'
        d='M49.172 25.685c0 4.724-3.835 8.56-8.559 8.56-4.724 0-8.648-3.836-8.648-8.56s3.835-8.558 8.559-8.558a8.561 8.561 0 018.558 8.558h.09z'
      />
      <path
        fill='#000'
        d='M0 53.028v17.944h2.308v-8.568h5.55v-2.227h-5.55v-4.904H9.52v-2.245H0zm13.696 3.646c.835 0 1.509-.673 1.509-1.509 0-.835-.683-1.419-1.51-1.419-.825 0-1.508.674-1.508 1.51 0 .403.162.78.45 1.077.287.296.664.449 1.077.449l-.018-.108zm-1.168 2.263v12.035h2.219V58.937h-2.29.071zm10.885-.359c-3.071 0-5.209 2.694-5.209 5.676s2.156 5.658 5.21 5.658a4.581 4.581 0 003.403-1.428v1.599a3.772 3.772 0 01-1.042 2.577 3.593 3.593 0 01-2.568 1.078 3.772 3.772 0 01-2.048-.683l-1.168 2.003a5.928 5.928 0 009.134-5.002V58.919h-2.308v1.078a4.579 4.579 0 00-3.404-1.428v.009zm-2.892 5.676a3.145 3.145 0 011.491-2.964 3.163 3.163 0 014.814 2.964 3.143 3.143 0 01-1.49 2.964 3.163 3.163 0 01-4.815-2.964zm16.926-3.324a2.515 2.515 0 00-2.506 2.507v7.615h-2.308V59.018h2.308v1.015a3.66 3.66 0 012.982-1.455 4.007 4.007 0 013.574 2.038c.898-1.257 2.191-2.02 3.592-2.038 2.632 0 4.266 2.12 4.275 4.742v7.66h-2.308v-7.633a2.514 2.514 0 00-3.835-2.479 2.514 2.514 0 00-1.113 2.479v7.616h-2.219v-7.607a2.515 2.515 0 00-2.505-2.506l.063.08zm24.248-.628a5.57 5.57 0 00-4.068-1.715c-3.44 0-5.838 2.91-5.838 6.286 0 3.377 2.425 6.287 5.838 6.287a5.57 5.57 0 004.068-1.715v1.365h2.308V58.775h-2.308v1.527zm-7.634 4.67a3.83 3.83 0 011.814-3.574c1.23-.755 2.695-.755 4.006 0a3.863 3.863 0 011.814 3.574 3.83 3.83 0 01-1.814 3.575c-1.23.754-2.694.754-4.006 0a3.861 3.861 0 01-1.814-3.575z'
      />
    </svg>
  );
}

export default SaiktFigma;
