import { BaseHeader, Footer } from 'src/components';
import styled, { keyframes } from 'styled-components/macro';
import noiseFull from 'src/assets/images/noise-full.png';
import { media } from 'src/assets/style/media';

const BaseLayout = ({ children, className }) => {
  return (
    <BaseLayoutContainer className={className}>
      <BaseHeader />
      <BaseLayoutNoise />
      {children}
      <Footer />
    </BaseLayoutContainer>
  );
};

const animateNoise = keyframes`
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-5%, -10%);
    transform: translate(-5%, -10%);
  }
  20% {
    -webkit-transform: translate(-15%, 5%);
    transform: translate(-15%, 5%);
  }
  30% {
    -webkit-transform: translate(7%, -25%);
    transform: translate(7%, -25%);
  }
  40% {
    -webkit-transform: translate(-5%, 25%);
    transform: translate(-5%, 25%);
  }
  50% {
    -webkit-transform: translate(-15%, 10%);
    transform: translate(-15%, 10%);
  }
  60% {
    -webkit-transform: translate(15%, 0%);
    transform: translate(15%, 0%);
  }
  70% {
    -webkit-transform: translate(0%, 15%);
    transform: translate(0%, 15%);
  }
  80% {
    -webkit-transform: translate(3%, 35%);
    transform: translate(3%, 35%);
  }
  90% {
    -webkit-transform: translate(-10%, 10%);
    transform: translate(-10%, 10%);
  }
`;

const BaseLayoutContainer = styled.main`
  background-color: #171717;
  font-size: 3vw;
  padding: 50px 140px 0 140px;
  font-family: 'gyre heros';
  overflow-x: hidden;

  @media ${media.laptopM()} {
    padding: 50px 70px;
  }

  @media ${media.laptopS()} {
    padding: 20px;
  }
`;

const BaseLayoutNoise = styled.div`
  text-rendering: optimizeSpeed;
  background: url(${noiseFull});
  animation: ${animateNoise} 6s steps(10) infinite;
  background-repeat: repeat;
  height: 300%;
  left: -50%;
  opacity: 0.05;
  position: fixed;
  top: -100%;
  width: 300%;
  z-index: 1;
  pointer-events: none;
`;

export default BaseLayout;
