import React from 'react';

function MSquares7() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='260'
      height='260'
      fill='none'
      viewBox='0 0 260 260'
    >
      <path
        fill='#fff'
        d='M7.632 141.494c-11.897-14.684-9.637-36.232 5.048-48.129L118.506 7.632c14.684-11.897 36.232-9.637 48.129 5.048l85.733 105.826c11.897 14.684 9.637 36.232-5.048 48.129l-105.826 85.733c-14.684 11.897-36.232 9.637-48.129-5.048L7.632 141.494z'
      />
    </svg>
  );
}

export default MSquares7;
