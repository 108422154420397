import React from 'react';

function MSquaresTwo1() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='279'
      height='256'
      fill='none'
      viewBox='0 0 279 256'
    >
      <path
        fill='#CFE0FF'
        d='M42.144 132.319c-11.125-13.732-9.012-33.883 4.72-45.008l98.964-80.174c13.732-11.125 33.882-9.012 45.007 4.72l80.175 98.964c11.125 13.732 9.011 33.883-4.721 45.008l-98.963 80.174c-13.733 11.125-33.883 9.011-45.008-4.721l-80.174-98.963zM2.436 218.162c-3.797-4.687-3.076-11.566 1.612-15.364l33.783-27.369c4.687-3.797 11.566-3.076 15.364 1.612l27.369 33.783c3.798 4.687 3.076 11.566-1.612 15.364L45.17 253.557c-4.687 3.798-11.566 3.076-15.364-1.612l-27.37-33.783z'
      />
    </svg>
  );
}

export default MSquaresTwo1;
