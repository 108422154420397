import React from 'react';

function MCircleFill({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='449'
      height='449'
      fill='none'
      viewBox='0 0 449 449'
    >
      <circle cx='224.5' cy='224.5' r='224.5' fill='#D2F2E4' />
    </svg>
  );
}

export default MCircleFill;
