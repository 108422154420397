import React from 'react';

function Solution({ className = '' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='447'
      height='306'
      fill='none'
      viewBox='0 0 447 306'
    >
      <path
        fill='#F1F1F1'
        d='M223.5 306c123.436 0 223.5-6.293 223.5-14.055 0-7.763-100.064-14.056-223.5-14.056S0 284.182 0 291.945C0 299.707 100.064 306 223.5 306z'
      />
      <path fill='#3F3D56' d='M353.481 0H93.519v106.251h259.962V0z' />
      <path
        fill='#FF6584'
        d='M130.881 39.555H111.5a68.026 68.026 0 010-23.153h19.381a41.745 41.745 0 000 23.153z'
      />
      <path
        fill='#14BC6F'
        d='M171.248 33.07h19.382a68.077 68.077 0 000-23.153h-19.382a41.726 41.726 0 010 23.153z'
      />
      <path
        fill='#F1F1F1'
        d='M292.832 43.973a370.118 370.118 0 0030.693-.95l-.679-.893c-1.673 4.247-1.627 8.724-1.405 13.217.055 1.125.408 2.643.18 3.758-.287 1.403-1.543 1.067-2.792 1.108a453.89 453.89 0 01-19.731.21c-1.437-.017-3.07.401-3.441-1.266-.238-1.068.002-2.473.002-3.572l.003-4.018.005-8.036a.706.706 0 00-1.193-.484.706.706 0 00-.214.484l-.007 9.822-.003 4.465c0 .886-.184 1.958.146 2.8.76 1.936 4.045 1.21 5.62 1.224a453.85 453.85 0 0019.271-.234c1.383-.046 3.297.27 3.717-1.44.294-1.198-.049-2.76-.112-3.983-.071-1.396-.148-2.791-.167-4.189-.043-3.263.276-6.437 1.479-9.491a.707.707 0 00-.679-.893 370.07 370.07 0 01-30.693.95.706.706 0 000 1.41v.001z'
      />
      <path
        fill='#F1F1F1'
        d='M295.751 44.74a103.292 103.292 0 0112.793 5.535c3.71 1.92 8.567 4.02 11.12 7.452.535.72 1.757.018 1.215-.711-2.607-3.506-7.095-5.57-10.866-7.562a105.656 105.656 0 00-13.888-6.075c-.855-.304-1.223 1.058-.374 1.36z'
      />
      <path
        fill='#F1F1F1'
        d='M323.285 42.228c-4.652 1.993-8.593 5.833-12.75 8.678-4.5 3.08-9.134 5.946-13.901 8.599-.791.442-.082 1.66.71 1.217a186.307 186.307 0 0013.902-8.598c4.156-2.845 8.099-6.686 12.75-8.678.83-.356.114-1.572-.711-1.218zm-121.536 4.377c9.49.145 18.979.107 28.467-.114.905-.02.908-1.431 0-1.41-9.488.22-18.977.258-28.467.113a.705.705 0 000 1.41zm.798 6.395c9.756.155 19.511.114 29.265-.126.905-.023.908-1.433 0-1.41-9.753.239-19.508.281-29.265.125a.704.704 0 00-.482 1.196.71.71 0 00.482.215zm-1.068 6.904l27.67-1.288c.902-.041.907-1.452 0-1.41l-27.67 1.287c-.902.042-.907 1.453 0 1.41zm43.1-6.706h26.337a.704.704 0 00.482-1.196.705.705 0 00-.482-.215h-26.337a.705.705 0 000 1.41z'
      />
      <path
        fill='#F1F1F1'
        d='M269.762 45.134l4.115 3.763c.47.43 1.765 1.32 2.057 1.882.605 1.163-.045.898-1.081 1.3a23.15 23.15 0 00-6.927 4.311c-.681.601.317 1.596.995.997a21.495 21.495 0 016.307-3.947c.83-.329 2.405-.522 2.799-1.512.483-1.213-1.055-2.107-1.783-2.774l-5.486-5.017c-.668-.61-1.667.384-.996.997z'
      />
      <path
        fill='#14BC6F'
        d='M205.846 100.192h19.382a68.024 68.024 0 000-23.152h-19.382a41.725 41.725 0 010 23.152zm-80.709-17.395h19.381a68.026 68.026 0 000-23.153h-19.381a41.745 41.745 0 010 23.153zm122.472-50.775h19.382a68.077 68.077 0 000-23.153h-19.382a41.727 41.727 0 010 23.153z'
      />
      <path
        fill='#FBBEBE'
        d='M440.385 267.894l2.526 10.125-11.115 3.037-1.011-10.631 9.6-2.531zm-40.421 12.656v9.618l-11.116 2.025-2.02-5.062 4.042-8.606 9.094 2.025z'
      />
      <path
        fill='#2F2E41'
        d='M422.196 175.761l-1.516 13.668 1.01 26.324a1.486 1.486 0 011.516 1.519c0 1.518-1.516 1.012 0 2.025.813.553 1.657 1.06 2.527 1.518l16.168 49.104s-4.042 5.062-11.621 3.544l-1.516-5.063s-3.031-3.037-3.031-4.556c0-1.518 1.01-2.025 0-3.543-1.011-1.519-16.169-32.399-16.169-32.399l-7.073-12.655-2.021 17.211s4.042 21.768 3.031 31.893c-1.01 10.124-3.031 20.755-3.031 20.755l-12.127-2.025a8.457 8.457 0 00-1.516-4.556c-1.515-2.025-3.536-1.519-1.515-3.038 2.021-1.518 4.547.001 2.526-2.024s-2.021-4.05-2.021-4.05v-1.519l-3.032-38.979-5.557-51.129 44.968-2.025z'
      />
      <path
        fill='#FBBEBE'
        d='M387.838 83.628c7.534 0 13.642-6.119 13.642-13.668 0-7.548-6.108-13.668-13.642-13.668s-13.642 6.12-13.642 13.668c0 7.549 6.108 13.668 13.642 13.668z'
      />
      <path
        fill='#FBBEBE'
        d='M399.712 70.213l5.557 13.669-13.136 9.617-8.084-14.173 15.663-9.113z'
      />
      <path
        fill='#D0CDE1'
        d='M400.469 80.59l5.053-2.53 3.032 7.087s19.705 6.075 18.189 33.917c-1.516 27.842-5.053 38.473-5.053 38.473s8.59 18.224 3.537 20.249c-5.052 2.025-48 9.618-49.01 2.531-1.011-7.087 3.537-48.597 3.537-48.597s-1.516-29.868 11.115-35.942l-2.526-5.569 12.126-9.618z'
      />
      <path
        fill='#FBBEBE'
        d='M372.68 178.799s-11.621 12.149-5.053 15.186c6.569 3.038 10.611-13.668 10.611-13.668l-5.558-1.518z'
      />
      <path
        fill='#2F2E41'
        d='M396.427 287.637l5.558-2.025s6.063 13.668 1.011 13.668h-13.137s-7.579-2.531-11.621-2.531c-4.042 0-11.621-7.594-6.063-11.137 5.558-3.544 12.631-2.531 12.631-2.531l2.021-4.556 2.527 7.087s3.031 5.062 7.073 2.025zm40.927-11.137s3.164-2 4.866-1.253c1.702.747 2.713 8.846 2.713 8.846s5.052 6.075-6.064 7.087c-11.115 1.013-13.642-1.012-14.652-4.049-1.011-3.038 1.01-6.075 1.01-6.075l3.032-1.013s3.537-2.531 2.526-3.543c-1.01-1.013 0-3.037 0-3.037s3.537 7.087 6.569 3.037zm-44.85-206.759a6.664 6.664 0 00-.297-1.3c-.733-1.542-3.594-1.092-4.103-2.72-.1-.32-.079-.667-.177-.986-.249-.815-1.206-1.219-2.055-1.18-.843.117-1.669.33-2.463.636-1.999.568-4.126.351-6.191.13a6.221 6.221 0 01-2.644-.69c-.782-.482-1.346-1.453-1.061-2.328.273-.838 1.189-1.3 1.601-2.08.654-1.238-.145-2.837.385-4.133.852-2.08 4.073-1.662 5.71-3.2.398-.372.684-.85 1.064-1.24 1.13-1.156 2.915-1.38 4.522-1.22 1.608.16 3.188.632 4.803.603 1.532-.027 3.138-.494 4.549.103a6.495 6.495 0 012.137 1.759l3.62 3.978a4.47 4.47 0 011.119 1.689c.218.762.018 1.58.11 2.368.212 1.795 1.89 3.152 2.117 4.946a6.458 6.458 0 01-.08 1.665l-.507 3.747a2.804 2.804 0 01-.286 1.05c-.147.252-.37.451-.536.692-.469.68-.426 1.577-.659 2.37-.233.792-1.069 1.576-1.799 1.194-.477-.25-.793-2.31-1.372-2.844-.806-.745-1.228-.653-2.223-.64-1.994.025-4.646.196-5.284-2.369z'
      />
      <path
        fill='#D0CDE1'
        d='M394.912 99.321s15.157 6.075 10.61 19.743c-4.547 13.668-16.674 55.685-16.674 55.685l-7.073 3.543-2.021 5.569-9.6-3.544 1.516-7.593s.505-.506.505-2.531c0-2.025-2.021-2.531.505-2.531s2.526-2.531 2.526-2.531l9.095-56.698s0-9.618 10.611-9.112z'
      />
      <path
        fill='#000'
        d='M406.28 121.848l-15.663 53.66-6.568 6.581 3.789-6.834 18.442-53.407z'
        opacity='0.1'
      />
      <path
        fill='#3F3D56'
        d='M293.026 305.138c-.06-.098-1.475-2.467-1.966-7.384-.45-4.512-.161-12.117 3.776-22.725 7.457-20.096-1.719-36.311-1.813-36.472l.453-.263a40.283 40.283 0 013.79 10.758 47.022 47.022 0 01-1.94 26.16c-7.445 20.062-1.91 29.559-1.853 29.653l-.447.273zm-2.916-67.761a3.405 3.405 0 003.401-3.408 3.404 3.404 0 00-3.401-3.407 3.404 3.404 0 00-3.401 3.407 3.405 3.405 0 003.401 3.408z'
      />
      <path
        fill='#3F3D56'
        d='M300.836 249.958a3.404 3.404 0 003.401-3.408 3.404 3.404 0 00-3.401-3.407 3.405 3.405 0 00-3.401 3.407 3.405 3.405 0 003.401 3.408z'
      />
      <path
        fill='#14BC6F'
        d='M293.511 258.345a3.404 3.404 0 003.401-3.407 3.404 3.404 0 00-3.401-3.407 3.404 3.404 0 00-3.401 3.407 3.404 3.404 0 003.401 3.407zm8.895 7.078a3.405 3.405 0 003.401-3.408 3.404 3.404 0 00-3.401-3.407 3.404 3.404 0 00-3.401 3.407 3.405 3.405 0 003.401 3.408z'
      />
      <path
        fill='#3F3D56'
        d='M290.895 276.431a3.404 3.404 0 003.401-3.407 3.405 3.405 0 00-3.401-3.408 3.405 3.405 0 00-3.401 3.408 3.404 3.404 0 003.401 3.407zm4.186 28.832s-3.401-8.387 6.802-14.678l-6.802 14.678zm-4.183-.152s-1.548-8.919-13.527-8.843l13.527 8.843z'
      />
    </svg>
  );
}

export default Solution;
