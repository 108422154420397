import styled from 'styled-components/macro';
import screenOne from '../../../../assets/images/cases/ark/slider/screen-0.jpg';
import screenTwo from '../../../../assets/images/cases/ark/slider/screen-1.jpg';
import screenThree from '../../../../assets/images/cases/ark/slider/screen-2.jpg';
import screenFor from '../../../../assets/images/cases/ark/slider/screen-3.jpg';
import screenFive from '../../../../assets/images/cases/ark/slider/screen-4.jpg';
import screenSix from '../../../../assets/images/cases/ark/slider/screen-5.jpg';
import i18n from 'src/utils/i18next/i18n';
import { media } from 'src/assets/style/media';

const data = [
  {
    title: i18n.t('ark:ark.project.title.3.name.1'),
    img: screenOne,
  },
  {
    title: i18n.t('ark:ark.project.title.3.name.2'),
    img: screenTwo,
  },
  {
    title: i18n.t('ark:ark.project.title.3.name.3'),
    img: screenThree,
  },
  {
    title: i18n.t('ark:ark.project.title.3.name.4'),
    img: screenFor,
  },
  {
    title: i18n.t('ark:ark.project.title.3.name.4'),
    img: screenFive,
  },
  {
    title: i18n.t('ark:ark.project.title.3.name.4'),
    img: screenSix,
  },
];

const SliderContent = () => {
  return data.map(({ title, img }, i) => {
    return (
      <div className='ark-project__slider-item swiper-slide' key={i}>
        <SliderThumbnail>
          <img src={img} alt={img} />
        </SliderThumbnail>
        <div>{title}</div>
      </div>
    );
  });
};

const SliderThumbnail = styled.div`
  background-color: white;
  padding: 32px 36px;
  border-radius: 58px;

  @media ${media.laptopS('min')} {
    margin: 0 90px;
  }

  @media ${media.tabletL()} {
    padding: 15px;
    border-radius: 15px;
  }

  img {
    border-radius: 41px;

    @media ${media.tabletL()} {
      border-radius: 10px;
    }
  }

  + div {
    color: #85ddf2;
    font-size: min(15vw, 100px);
    line-height: min(15vw, 100px);
    font-weight: 700;
    text-align: left;
    max-width: 1464px;
    margin: 19px 0 15px auto;

    @media ${media.laptopL()} {
      font-size: min(5vw, 100px);
      line-height: min(5vw, 100px);
    }

    @media ${media.tabletL()} {
      text-align: center;
      margin-bottom: 90px;
    }
  }
`;

export default SliderContent;
