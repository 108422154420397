import { media } from 'src/assets/style/media';
import { ParallaxControl, ParallaxControlScroll } from 'src/components';
import {
  MSquares1,
  MSquares2,
  MSquares3,
  MSquares4,
} from 'src/components/svg/cases/saikt/models';
import styled from 'styled-components/macro';

const ObjectivesLayers = () => {
  return (
    <ObjectivesLayersWrapper>
      <Layer1>
        <ParallaxControlScroll y={[0, 10]}>
          <ParallaxControl speed='.2'>
            <MSquares1 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer1>
      <Layer2>
        <ParallaxControlScroll y={[0, 60]}>
          <ParallaxControl speed='.4'>
            <MSquares2 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer2>
      <Layer3>
        <ParallaxControlScroll y={[0, 220]}>
          <ParallaxControl speed='-.2'>
            <MSquares3 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer3>
      <Layer4>
        <ParallaxControlScroll y={[-50, 0]}>
          <ParallaxControl speed='.2'>
            <MSquares4 />
          </ParallaxControl>
        </ParallaxControlScroll>
      </Layer4>
    </ObjectivesLayersWrapper>
  );
};

const ObjectivesLayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Layer1 = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: min(50vw, 493px);
  height: min(50vw, 493px);
  transform: translate(-27%, -67%);
`;

const Layer2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: min(30vw, 248px);
  height: min(30vw, 248px);
  transform: translate(-25%, -16%);
`;

const Layer3 = styled.div`
  position: absolute;
  top: calc(20% - 20px);
  left: calc(100%);
  width: min(20vw, 137px);
  height: min(20vw, 137px);
  transform: translate(56%, 0);
`;

const Layer4 = styled.div`
  position: absolute;
  top: calc(100% - 46px);
  right: -39px;
  width: min(20vw, 243px);
  height: min(20vw, 243px);
  @media ${media.tabletS()} {
    display: none;
  }
`;

export default ObjectivesLayers;
