import React from 'react';

function MSettings() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='186'
      height='186'
      fill='none'
      viewBox='0 0 186 186'
    >
      <path
        fill='#111110'
        fillRule='evenodd'
        d='M1.697 75.22a50.09 50.09 0 0019.537 9 73.03 73.03 0 00-.528 8.78c0 39.926 32.367 72.293 72.293 72.293 39.927 0 72.294-32.367 72.294-72.293 0-39.926-32.367-72.293-72.294-72.293-4.244 0-8.402.366-12.446 1.067a50.18 50.18 0 00-9.995-19.048A93.21 93.21 0 0192.999 0C144.363 0 186 41.638 186 93s-41.638 93-93 93c-51.363 0-93-41.638-93-93 0-6.08.583-12.025 1.697-17.78zm5.57-45.554C5 37.922 7.39 47.122 14.207 53.212c6.819 6.091 16.229 7.433 24.178 4.254l50.297 44.933c4.118 3.68 10.44 3.324 14.119-.795l.465-.519c3.679-4.119 3.323-10.44-.796-14.12L52.173 42.031c2.267-8.255-.123-17.455-6.94-23.546-6.818-6.09-16.228-7.432-24.177-4.253L35.999 27.58a6 6 0 01.477 8.472l-5.794 6.485a6 6 0 01-8.472.477L7.267 29.665z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default MSettings;
